export const dateInSeconds = () => new Date().getTime() / 1000;

export const toDateTime = secs => {
  return new Date(secs * 1000);
};

export const dateToString = (dateFirebase, locale = 'pt-BR') => {
  const date = toDateTime(dateFirebase?.seconds);
  return date.toLocaleString(locale);
};

export const dateToInput = dateFirebase => {
  let date = toDateTime(dateFirebase?.seconds);
  date = date.toLocaleString('pt-BR').toString();
  date = date.split(' ');
  const dateDate = date[0].split('/');
  return (
    dateDate[2] + '-' + dateDate[1] + '-' + dateDate[0] + ' ' + date[1].split(':')[0] + ':' + date[1].split(':')[1]
  );
};
