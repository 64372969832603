import styled from 'styled-components';
import { heightWithoutToolbar } from '../../../common/services/screen';

export const PlantContainer = styled.div`
  width: 100%;
  height: ${heightWithoutToolbar};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
