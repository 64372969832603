import React, { useEffect, useState } from 'react';
import AppBarStyled from '../../common/components/AppBarStyled/AppBarStyled';
import { Container } from './Components/Container';
import { Content } from './Components/Content';
import { Add, Save } from '@mui/icons-material';
import { COLORS } from '../../common/constants/COLORS';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import AddInteractivePlan from '../../static/img/addinteractiveplant.png';
import Arrow from '../../static/img/arrow.png';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { ArrowInteractive } from './Components/ArrowInteractive';
import { PlantContainer } from './Components/PlantContainer';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { formatReal, getElementByXpath, isEmptyObject } from '../../common/utils/objectManipulation';
import { percentInPixelHeight, percentInPixelWidth } from '../../common/services/screen';
import { getDataForm, isValidForm } from '../../common/utils/formController';
import SelectStyled from '../../common/components/SelectStyled/SelectStyled';
import { useSnackbar } from 'notistack';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import { PLANT_STATUS } from '../../common/constants/PLANT_STATUS';
import { doc, getDoc } from 'firebase/firestore';
import { saveEnterprisesAdmin } from '../../common/services/enterprisesAdmin';
import { getImageStorage, uploadSingleImageStorage } from '../../common/services/Image';
import FileInput from '../../common/components/FileInput/FileInput';
import ImageMapper from '../../common/components/ImageMapper/ImageMapper';
import AlertDialog from '../../common/components/AlertDialog/AlertDialog';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { database } from '../../common/services/firebase';

const CreateInteractivePlant = ({ open, setOpen, setOpenModal, id }) => {
  const [form, setForm] = useState({
    number: { value: '1' },
    square: { value: 'A' },
    price: { value: '' },
    description: { value: '' },
    status: { value: PLANT_STATUS.AVAILABLE },
  });
  const [forms, setForms] = useState([]);
  const [image, setImage] = useState();
  const [zoom, setZoom] = useState(1);
  const [enterprise, setEnterprise] = useState();
  const [imageElement, setImageElement] = useState();
  const [loadingPlant, setLoadingPlant] = useState(false);
  const [cords, setCords] = useState({ name: 'generated', areas: [] });
  const [areaNumber, setAreaNumber] = useState(0);
  const [areaNameChange, setAreaNameChange] = useState(0);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function getMeta(url) {
    const img = new Image();
    img.src = url;
    img.onload = function() {
      setImageElement({ x: this.width, y: this.height });
    };
  }

  const setAllInformation = async () => {
    const getEnterprise = (await getDoc(doc(database, COLLECTION.ENTERPRISES, id))).data();
    await setEnterprise(getEnterprise);
    if (!isEmptyObject(getEnterprise?.imageOrigen)) {
      await setImageElement(getEnterprise?.imageOrigen);
      await setForms(
        getEnterprise?.forms.map(item => {
          return {
            description: { value: item?.description },
            number: { value: item?.number },
            price: { value: item?.price },
            square: { value: item?.square },
            status: { value: item?.status },
          };
        })
      );
      await setCords(getEnterprise?.cords);
      const lastForm = getEnterprise?.forms[getEnterprise?.forms.length - 1];
      await setForm({
        description: { value: lastForm?.description },
        number: { value: lastForm?.number },
        price: { value: lastForm?.price },
        square: { value: lastForm?.square },
        status: { value: lastForm?.status },
      });
      await setAreaNumber(getEnterprise?.cords?.areas?.length - 1);
    }
  };

  const getImage = async () => {
    setLoadingPlant(true);
    try {
      if (isEmptyObject(image)) {
        const imageUrl = await getImageStorage(id, 'plant');
        await setImage(imageUrl);
        getMeta(imageUrl);
      }
    } finally {
      setLoadingPlant(false);
    }
  };

  const handleImage = async event => {
    setLoadingPlant(true);
    try {
      await uploadSingleImageStorage(event, id, 'plant');
      getImage();
    } finally {
      setLoadingPlant(false);
    }
  };

  const setImageId = () => {
    setTimeout(() => {
      if (getElementByXpath('//*[@id="root"]/div[1]/div/div[1]/div/img') !== null) {
        getElementByXpath('//*[@id="root"]/div[1]/div/div[1]/div/img').id = 'image';
        getElementByXpath('//*[@id="root"]/div[1]/div/div[1]/div/img').style.cursor = 'crosshair';
      }
    }, 300);
  };

  const save = async () => {
    const getForms = () =>
      forms.map(data => {
        return { ...getDataForm(data), name: getDataForm(data)?.square + getDataForm(data)?.number };
      });

    const newEnterprise = {
      imageOrigen: imageElement,
      ...enterprise,
      forms: getForms(),
      cords: cords,
    };
    try {
      await saveEnterprisesAdmin(newEnterprise);
      enqueueSnackbar('Salvo com sucesso.', { variant: ALERT_TYPES.SUCCESS });
    } catch {
      enqueueSnackbar('Não foi possível salvar, tente novamente.', { variant: ALERT_TYPES.ERROR });
    }
  };

  const calculateImageByHeight = () => {
    const yPercent = (imageElement?.y * 100) / (percentInPixelHeight(100) - 64);
    const xScreen = (imageElement?.x * 100) / yPercent;
    setImageElement({ ...imageElement, xScreen: xScreen, yScreen: percentInPixelHeight(100) - 64 });
    return xScreen;
  };

  const calculateImageByWidth = () => {
    const xPercent = (imageElement?.x * 100) / (percentInPixelWidth(100) - 350);
    const yScreen = (imageElement?.y * 100) / xPercent;
    setImageElement({ ...imageElement, xScreen: percentInPixelWidth(100) - 350, yScreen: yScreen });
    return yScreen;
  };

  const calculateImage = () => {
    if (!isEmptyObject(imageElement) && imageElement?.xScreen === undefined) {
      if (imageElement?.x > imageElement?.y) {
        const yScreen = calculateImageByWidth();
        if (yScreen > percentInPixelHeight(100) - 64) {
          calculateImageByHeight();
        }
      } else if (imageElement?.x < imageElement?.y) {
        const xScreen = calculateImageByHeight();
        if (xScreen > percentInPixelWidth(100) - 350) {
          calculateImageByWidth();
        }
      }
    }
  };

  useEffect(() => {
    calculateImage();
    if (!isEmptyObject(enterprise?.imageOrigen)) {
      setImageElement(enterprise?.imageOrigen);
    }
  }, [imageElement]);

  useEffect(() => {
    open && getImage();
  }, [open]);

  useEffect(() => {
    setImageId();
    setAllInformation();
  }, [image]);

  useEffect(() => {
    fillForm();
  }, [areaNumber]);

  const fillForm = () => {
    if (!isEmptyObject(forms)) {
      if (isEmptyObject(forms[areaNumber])) {
        setForm({
          ...form,
          number: { value: '' },
          price: { value: '' },
          description: { value: '' },
          status: { value: PLANT_STATUS.AVAILABLE },
        });
      } else {
        setForm(forms[areaNumber]);
      }
    }
  };

  const getArea = async event => {
    if (await saveArea()) {
      const index = cords.areas.findIndex(area => area.name === event.name);
      setAreaNumber(index);
    } else {
      await setAreaNameChange(event.name);
      setOpenCloseDialog(true);
    }
  };

  const getCord = event => {
    const name = getDataForm(form).square + getDataForm(form).number;
    const scrollTop = getElementByXpath('//*[@id="root"]/div[1]/div/div[1]').scrollTop;
    const scrollLeft = getElementByXpath('//*[@id="root"]/div[1]/div/div[1]').scrollLeft;
    const cord = {
      x: event.pageX / zoom + scrollLeft / zoom,
      y: event.pageY / zoom - 64 / zoom + scrollTop / zoom,
    };

    const newCords = { ...cords };
    if (newCords.areas[areaNumber]?.coords === undefined) {
      newCords.areas[areaNumber] = {
        name,
        shape: 'poly',
        status: form?.status?.value,
        coords: [cord.x, cord.y],
      };
    } else {
      newCords.areas[areaNumber] = {
        name,
        shape: 'poly',
        status: form?.status?.value,
        coords: [...newCords.areas[areaNumber]?.coords, cord.x, cord.y],
      };
    }
    setCords(newCords);
  };

  const saveArea = async () => {
    if (cords.areas[areaNumber]?.coords !== undefined) {
      if (isValidForm(form, setForm)) {
        setForm({ ...form, onSubmit: false });
        const newForms = [...forms];
        newForms[areaNumber] = {
          ...newForms[areaNumber],
          ...form,
        };
        await setForms(newForms);
        const newCords = { ...cords };
        newCords.areas[areaNumber] = {
          ...newCords.areas[areaNumber],
          name: form?.square?.value + form?.number?.value,
          status: form?.status?.value,
        };
        await setCords(newCords);
        return true;
      }
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    } else {
      enqueueSnackbar('Por favor selecionar uma area.', { variant: ALERT_TYPES.WARNING });
    }
    return false;
  };

  const newArea = async () => {
    if (await saveArea()) {
      await setAreaNumber(cords.areas.length);
    }
  };

  const clearSelection = async () => {
    const areas = [...cords.areas];
    areas.splice(areaNumber, 1, { ...areas[areaNumber], coords: [] });
    await setCords({ ...cords, areas: [...areas] });
  };

  const deleteSelection = async () => {
    const areas = [...cords.areas];
    areas.splice(areaNumber, 1);
    const newForms = [...forms];
    newForms.splice(areaNumber, 1);
    await setForms(newForms);
    await setCords({ ...cords, areas: [...areas] });
    await setForm(newForms[areas.length - 1]);
    await setAreaNumber(areas.length - 1);
  };

  const onZoom = event => {
    if (event.altKey) {
      if (event.deltaY < 0) {
        setZoom(zoom + 0.25);
      } else if (zoom > 1) {
        setZoom(zoom - 0.25);
      }
    }
  };

  const backSelection = async () => {
    const newCords = { ...cords };
    newCords.areas[areaNumber].coords.splice(newCords.areas[areaNumber].coords.length - 2, 2);
    setCords({ ...newCords });
  };

  return (
    <Container open={open}>
      <AppBarStyled
        onBack={() => {
          save();
          setOpen(false);
          setOpenModal(true);
          setAreaNumber(0);
          setForm({
            number: { value: '1' },
            square: { value: 'A' },
            price: { value: '' },
            description: { value: '' },
            status: { value: '' },
          });
          setForms([]);
          setCords({ name: 'generated', areas: [] });
          setImage(undefined);
          setZoom(1);
          setEnterprise({});
        }}
        title={'Adicionar planta interativa'}
        buttons={
          !image ? (
            <FileInput
              handle={event => handleImage(event)}
              loading={loadingPlant}
              label={'Adicionar Planta'}
              multiple={false}
              margin={'0 0 0 16px'}
              bg={COLORS.WHITE}
              colorText={COLORS.GREEN_LIGHT}
              component="span"
            />
          ) : (
            <ButtonStyled
              onClick={() => save()}
              startIcon={<Save />}
              margin={'0 0 0 16px'}
              bg={COLORS.WHITE}
              component="span"
            >
              Salvar
            </ButtonStyled>
          )
        }
      />
      <AlertDialog
        open={openCloseDialog}
        title={'Ops!'}
        text={'Deseja realmente trocar sem salvar?'}
        handleClose={() => setOpenCloseDialog(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenCloseDialog(false)}>Voltar</ButtonStyled>
            <ButtonStyled
              onClick={() => {
                const index = cords.areas.findIndex(area => area.name === areaNameChange);
                setAreaNumber(index);
                setOpenCloseDialog(false);
              }}
            >
              Trocar
            </ButtonStyled>
          </>
        }
      />
      {!isEmptyObject(image) ? (
        <PlantContainer onWheel={event => onZoom(event)}>
          <div style={{ zIndex: '9999', overflow: 'auto', width: imageElement?.xScreen }}>
            <ImageMapper
              src={
                image ||
                'https://rockcontent.com/br/wp-content/uploads/sites/2/2019/02/Como-usar-o-Photoshop-1024x538.png'
              }
              height={imageElement?.yScreen * zoom}
              imgHeight={imageElement?.yScreen}
              width={imageElement?.xScreen * zoom}
              imgWidth={imageElement?.xScreen}
              onImageClick={event => getCord(event)}
              onClick={event => getArea(event)}
              map={cords}
            />
          </div>
          <Spacing padding={'16px'} jc={'space-between'} width={'calc(100% - 32px - ' + imageElement?.xScreen + 'px)'}>
            <Spacing jc={'flex-start'} ai={'flex-start'} width={'100%'}>
              <Typography sx={{ fontSize: '24px', marginBottom: '32px' }}>Adicionar Lote</Typography>
              <InputStyled
                mask={value => value?.toString().toUpperCase()}
                controller={{ form, setForm }}
                name={'square'}
                label={'Quadra'}
                type={'text'}
              />
              <InputStyled controller={{ form, setForm }} name={'number'} label={'Número'} type={'number'} />
              <InputStyled
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
                controller={{ form, setForm }}
                name={'price'}
                label={'Valor'}
                type={'price'}
              />
              <InputStyled
                controller={{ form, setForm }}
                name={'description'}
                label={'Descrição'}
                type={'description'}
                multiline
              />
              <SelectStyled
                controller={{ form, setForm }}
                label={'Status'}
                name={'status'}
                options={[
                  { value: PLANT_STATUS.AVAILABLE, label: 'Disponível' },
                  { value: PLANT_STATUS.RESERVED, label: 'Reservado' },
                  { value: PLANT_STATUS.IN_PROPOSAL, label: 'Em Proposta' },
                  { value: PLANT_STATUS.SOLD, label: 'Vendido' },
                ]}
              />
              <Spacing width={'100%'} margin={'0 0 16px 0'} fd={'row'} jc={'flex-end'}>
                <ButtonStyled
                  margin={'0 0 0 auto'}
                  bg={COLORS.RED}
                  colorText={COLORS.WHITE}
                  onClick={() => deleteSelection()}
                >
                  Excluir
                </ButtonStyled>
                <ButtonStyled
                  margin={'0 0 0 16px'}
                  bg={COLORS.BLUE}
                  colorText={COLORS.WHITE}
                  onClick={async () => {
                    await saveArea();
                    await saveArea();
                  }}
                >
                  Salvar
                </ButtonStyled>
                <ButtonStyled
                  margin={'0 0 0 16px'}
                  bg={COLORS.GREEN_LIGHT}
                  colorText={COLORS.WHITE}
                  onClick={() => newArea()}
                >
                  Novo
                </ButtonStyled>
              </Spacing>
            </Spacing>
            <Spacing width={'100%'} fd={'row'} jc={'flex-start'}>
              <Spacing width={'30%'} fd={'row'} jc={'flex-start'}>
                <IconButton
                  style={{
                    margin: '0 8px 0 0',
                    background: COLORS.WHITE_LIGHT,
                    border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
                  }}
                  onClick={() => setZoom(zoom + 0.25)}
                >
                  <Add />
                </IconButton>
                <IconButton
                  style={{
                    margin: '0 8px 0 0',
                    background: COLORS.WHITE_LIGHT,
                    border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
                  }}
                  onClick={() => {
                    if (zoom > 1) {
                      setZoom(zoom - 0.25);
                    }
                  }}
                >
                  <HorizontalRuleIcon />
                </IconButton>
                <IconButton
                  style={{
                    margin: '0 8px 0 0',
                    background: COLORS.WHITE_LIGHT,
                    border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
                  }}
                  onClick={() => setZoom(1)}
                >
                  <AspectRatioIcon />
                </IconButton>
              </Spacing>
              <Spacing width={'70%'} fd={'row'} jc={'flex-end'}>
                <ButtonStyled
                  bg={COLORS.YELLOW}
                  margin={'0 0 0 16px'}
                  colorText={COLORS.WHITE}
                  onClick={async () => {
                    await backSelection();
                  }}
                >
                  Voltar Ultima Seleção
                </ButtonStyled>
                <ButtonStyled
                  bg={COLORS.ORANGE_TRANSPARENT}
                  margin={'0 0 0 16px'}
                  colorText={COLORS.WHITE}
                  onClick={async () => {
                    await clearSelection();
                    await clearSelection();
                  }}
                >
                  Limpar Seleção
                </ButtonStyled>
              </Spacing>
            </Spacing>
          </Spacing>
        </PlantContainer>
      ) : (
        <Content>
          <Spacing margin={'0 0 32px 0'}>
            <Typography sx={{ fontSize: '32px' }}>ADICIONE A PLANTA</Typography>
          </Spacing>
          <img src={AddInteractivePlan} />
          <ArrowInteractive>
            <img src={Arrow} width={350} />
          </ArrowInteractive>
        </Content>
      )}
    </Container>
  );
};

export default CreateInteractivePlant;
