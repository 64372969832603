import React, { useEffect, useState } from 'react';
import { FormControlStyled } from '../FormControlStyled/FormControlStyled';
import { InputLabelStyled } from '../InputLabelStyled/InputLabelStyled';
import { FormHelperText, MenuItem, Select } from '@mui/material';
import { isEmptyObject } from '../../utils/objectManipulation';

const SelectStyled = ({ name, label, controller, notRequired = false, disabled = false, options, paddingBottom }) => {
  const [error, setError] = useState({ status: false });

  const handle = event => {
    if (!isEmptyObject(event?.target?.dataset?.value)) {
      setError({
        status: !notRequired && isEmptyObject(event?.target?.dataset?.value),
        message: 'Este campo é obrigatorio.',
      });
      controller.setForm(old => {
        return { ...old, [name]: { value: event.target.dataset?.value, required: !notRequired } };
      });
    }
  };

  useEffect(() => {
    controller.setForm(old => {
      return {
        ...old,
        [name]: { value: old !== undefined && old[name] ? old[name]?.value : '', required: !notRequired },
        onSubmit: false,
      };
    });
  }, []);

  useEffect(() => {
    if (controller.form?.onSubmit) {
      setError({
        status: !notRequired && isEmptyObject(controller?.form[name]?.value),
        message: 'Este campo é obrigatorio.',
      });
    }
  }, [controller.form]);

  return (
    <FormControlStyled paddingBottom={paddingBottom} error={error.status}>
      <InputLabelStyled disabled={disabled}>{label}</InputLabelStyled>
      <Select
        disabled={disabled}
        error={error.status}
        name={name}
        renderValue={() =>
          options.find(option => option?.value?.includes(controller.form ? controller?.form[name]?.value : ''))?.label
        }
        value={controller.form ? controller?.form[name]?.value : ''}
        onClick={event => handle(event)}
        label={label}
      >
        {options && options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)}
      </Select>
      {error.status && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControlStyled>
  );
};

export default SelectStyled;
