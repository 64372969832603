import React, { useEffect, useState } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import LogoImg from '../../../static/img/logo192.png';
import { Spacing } from '../Spacing/Spacing';
import { Link } from 'react-router-dom';
import { Container, MaxSpacing } from './styled';
import LogoutIcon from '@mui/icons-material/Logout';
import { currentUser } from '../../services/user';

const Menu = ({ openMenu, setOpenMenu, menu }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    setUser(currentUser());
  }, []);

  return (
    <Drawer anchor={'left'} open={openMenu} onClose={() => setOpenMenu(false)}>
      <Container>
        <Spacing fd={'row'} jc={'start'} ai={'center'} margin={'16px'}>
          <img
            style={{ borderRadius: '500px', marginRight: '16px' }}
            src={user?.photo ? user?.photo : LogoImg}
            width={70}
            alt={'Person'}
          />
          {user?.name}
        </Spacing>
        <Divider />
        {menu.map((menuList, index) => (
          <>
            <List key={index.toString()}>
              {menuList.map((menuItem, ind) => (
                <Link key={ind.toString()} to={menuItem.to}>
                  <ListItem key={menuItem.title} onClick={() => setOpenMenu(false)}>
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText primary={menuItem.title} />
                  </ListItem>
                </Link>
              ))}
            </List>
            {index + 1 !== menu.length && <Divider key={'divider' + index} />}
          </>
        ))}
        <MaxSpacing />
        <ListItem
          button
          key={'Sair'}
          onClick={() => {
            setOpenMenu(false);
            localStorage.removeItem('user');
            document.location.reload(true);
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Sair'} />
        </ListItem>
      </Container>
    </Drawer>
  );
};

export default Menu;
