import React, { useEffect, useState } from 'react';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { getListImageStorage } from '../../common/services/Image';
import ImagePlant from '../../common/components/ImagePlant/ImagePlant';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { Text, Title } from './styled';
import { heightWithoutToolbar, isDesktop } from '../../common/services/screen';
import { Card } from '@mui/material';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import ModalAreaClient from '../../common/components/ModalAreaClient/ModalAreaClient';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { isEmptyObject } from '../../common/utils/objectManipulation';

const Enterprise = () => {
  const { id } = useParams();
  const [enterprise, setEnterprise] = useState();
  const [plant, setPlant] = useState();
  const [images, setImages] = useState();
  const [openAreaClient, setOpenAreaClient] = useState(false);
  const [area, setArea] = useState();
  const [isProposal, setIsProposal] = useState(false);
  const navigate = useNavigate();

  const getPlant = async () => {
    setPlant((await getListImageStorage(id, 'plant'))[0]?.url);
  };

  const getImages = async () => {
    setImages(await getListImageStorage(id, 'image'));
  };

  const getArea = event => {
    setIsProposal(false);
    setArea(enterprise?.forms.find(item => item?.name === event?.name));
    setOpenAreaClient(true);
  };

  useEffect(() => {
    getPlant();
    getImages();
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(doc(database, COLLECTION.ENTERPRISES, id), async data => {
      await setEnterprise(data.data());
      await setEnterprise(data.data());
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <RestrictArea permission={USER_PERMISSION.USER} title={enterprise?.name} onBack={() => navigate('/home')}>
      <ModalAreaClient
        open={openAreaClient}
        setOpen={setOpenAreaClient}
        enterprise={enterprise}
        area={area}
        setArea={setArea}
        setIsProposal={setIsProposal}
        isProposal={isProposal}
      />
      <Spacing display={'block'} overflow height={heightWithoutToolbar}>
        {!isEmptyObject(plant) && (
          <ImagePlant
            onClick={event => getArea(event)}
            enterprise={enterprise}
            plant={plant}
            maxWidth={document.body.clientWidth}
            maxHeight={isDesktop ? 650 : 400}
          />
        )}
        <Spacing borderTop={'1px solid ' + COLORS.BLACK_TRANSPARENT} ai={'flex-start'} padding={'0 16px 16px 16px'}>
          {!isEmptyObject(plant) && (
            <Spacing width={'100%'} fd={'row'} jc={isDesktop ? 'flex-end' : 'space-between'} padding={'16px 0'}>
              <ButtonStyled
                colorText={COLORS.BLUE}
                onClick={() => {
                  setIsProposal(false);
                  setOpenAreaClient(true);
                }}
              >
                Reservar
              </ButtonStyled>
              <ButtonStyled
                onClick={() => {
                  setIsProposal(true);
                  setOpenAreaClient(true);
                }}
                colorText={COLORS.BLUE}
              >
                Enviar Proposta
              </ButtonStyled>
            </Spacing>
          )}
          <Title>Descrição</Title>
          <Text>{enterprise?.description}</Text>
          <Title>Localização</Title>
          <Text>
            {enterprise?.address?.city} - {enterprise?.address?.state}
          </Text>
          <Text>
            {enterprise?.address?.district} - {enterprise?.address?.road} - {enterprise?.address?.number}
          </Text>
          <Title>Imagens</Title>
          <Spacing fd={isDesktop ? 'row' : 'column'} width={'100%'}>
            {images &&
              images?.map(image => (
                <Spacing padding={'8px'}>
                  <Card>
                    <img src={image?.url} width={300} alt={'Imagem do Empreendimento'} />
                  </Card>
                </Spacing>
              ))}
          </Spacing>
        </Spacing>
      </Spacing>
    </RestrictArea>
  );
};

export default Enterprise;
