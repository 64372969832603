import styled from 'styled-components';
import { isMobile, MediaQuery } from '../../common/services/screen';
import { COLORS } from '../../common/constants/COLORS';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  height: 100vh;
`;

export const ContainerCenter = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100vh;
  margin: 0 16px;
  overflow-y: auto;

  ${MediaQuery(isMobile)} {
    width: calc(100% - 32px);
  }
`;

export const ContainerStart = styled.div`
  height: 100vh;

  ${MediaQuery(isMobile)} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: ${COLORS.GREEN_LIGHT};
  text-align: center;
`;

export const SubTitle = styled.h3`
  color: ${COLORS.GRAY_BLACK};
  text-align: center;
  font-weight: 500;
  margin: 24px;
`;

export const Background = styled.img`
  width: 100%;
  height: 100%;
  ${({ inverted }) => inverted && 'transform: scaleX(-1);'}
`;
