import styled from 'styled-components';

export const Spacing = styled.div`
  display: ${({ display }) => (display ? display : 'flex')};
  justify-content: ${({ jc }) => (jc ? jc : 'center')};
  align-items: ${({ ai }) => (ai ? ai : 'center')};
  align-content: ${({ ac }) => (ac ? ac : 'center')};
  flex-direction: ${({ fd }) => (fd ? fd : 'column')};
  flex-wrap: ${({ fw }) => (fw ? fw : 'no-wrap')};
  width: ${({ width }) => width && width};
  margin: ${({ margin }) => (margin ? margin : '0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  ${({ overflow }) => overflow && 'overflow: auto;'};
  ${({ height }) => height && 'height: ' + height + ';'};
  ${({ maxHeight }) => maxHeight && 'max-height: ' + maxHeight + ';'};
  ${({ cursor }) => cursor && 'cursor: ' + cursor + ';'};
  ${({ position }) => position && 'position: ' + position + ';'};
  ${({ top }) => top && 'top: ' + top + ';'};
  ${({ right }) => right && 'right: ' + right + ';'};
  ${({ borderTop }) => borderTop && 'border-top: ' + borderTop + ';'};
  ${({ borderRadius }) => borderRadius && 'border-radius: ' + borderRadius + ';'};
  ${({ bg }) => bg && 'background: ' + bg + ';'};
  ${({ sx }) => sx && sx};
  user-select: none;
`;
