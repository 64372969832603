import React, { useEffect, useState } from 'react';
import { FormHelperText, OutlinedInput } from '@mui/material';
import { FormControlStyled } from '../FormControlStyled/FormControlStyled';
import { InputLabelStyled } from '../InputLabelStyled/InputLabelStyled';
import { isEmptyObject } from '../../utils/objectManipulation';

const InputStyled = ({
  name,
  label,
  controller,
  maxLength = 99999,
  type,
  mask,
  notRequired = false,
  onValidation,
  messageValidation,
  maxWidth,
  paddingBottom,
  disabled,
  startAdornment,
  endAdornment,
  inputProps,
  multiline = false,
}) => {
  const [error, setError] = useState({ status: false });

  const maskFormat = value => {
    if (mask) {
      return mask(value)?.substring(0, maxLength);
    }
    if (type === 'number' || isEmptyObject(value)) {
      return value;
    }
    return value?.toString()?.substring(0, maxLength);
  };

  const handle = event => {
    setError({
      status: !notRequired && isEmptyObject(maskFormat(event.target.value)),
      message: 'Este campo é obrigatorio.',
    });
    if (!(!notRequired && isEmptyObject(maskFormat(event.target.value)))) {
      onValidation &&
        setError({
          status: !isEmptyObject(maskFormat(event.target.value)) && !onValidation(maskFormat(event?.target?.value)),
          message: messageValidation ? messageValidation : 'Valor inválido.',
        });
    }
    controller.setForm(old => {
      return { ...old, [name]: { value: maskFormat(event?.target?.value), required: !notRequired, onValidation } };
    });
  };

  useEffect(() => {
    controller.setForm(old => {
      return {
        ...old,
        [name]: { value: maskFormat(old !== undefined && old[name] ? old[name]?.value : ''), required: !notRequired },
        onSubmit: false,
      };
    });
  }, []);

  useEffect(() => {
    if (controller.form?.onSubmit) {
      setError({
        status: !notRequired && isEmptyObject(maskFormat(controller?.form[name]?.value)),
        message: 'Este campo é obrigatorio.',
      });
      if (!(!notRequired && isEmptyObject(maskFormat(controller?.form[name]?.value)))) {
        onValidation &&
          setError({
            status:
              !isEmptyObject(maskFormat(controller?.form[name]?.value)) &&
              !onValidation(maskFormat(controller?.form[name]?.value)),
            message: messageValidation ? messageValidation : 'Valor inválido.',
          });
      }
    }
  }, [controller?.form]);

  return (
    <FormControlStyled paddingBottom={paddingBottom} maxWidth={maxWidth} error={error?.status}>
      <InputLabelStyled disabled={disabled}>{label}</InputLabelStyled>
      <OutlinedInput
        error={error?.status}
        name={name}
        type={type}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        value={controller?.form ? controller?.form[name]?.value : ''}
        onChange={event => handle(event)}
        onClick={event => handle(event)}
        label={label}
        disabled={disabled}
        multiline={multiline}
        {...{ inputProps: !isEmptyObject(inputProps) && inputProps }}
      />
      {error?.status && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControlStyled>
  );
};

export default InputStyled;
