import React from 'react';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { Add } from '@mui/icons-material';

const FileInput = ({
  handle,
  loading,
  disabled,
  multiple = true,
  bg,
  margin,
  colorText,
  label = 'Adicionar',
  accept = 'image/*',
}) => {
  return (
    <label>
      <input
        onChange={event => handle(event)}
        disabled={loading || disabled}
        accept={accept}
        multiple={multiple}
        type="file"
        hidden
      />
      <ButtonStyled
        disabled={loading || disabled}
        startIcon={<Add />}
        variant="contained"
        loading={loading}
        loadingPosition="start"
        bg={bg}
        margin={margin}
        colorText={colorText}
        component="span"
      >
        {label}
      </ButtonStyled>
    </label>
  );
};

export default FileInput;
