import React, { useEffect, useState } from 'react';
import { currentUser } from '../../common/services/user';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { Container, Grid } from '../Home/styled';
import CardEnterprise from '../../common/components/CardEnterprise/CardEnterprise';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { checkReservationsExpired } from '../../common/services/reservation';
import { getEnterprisesById } from '../../common/services/enterprises';

const Enterprises = () => {
  document.title = 'Empreendimentos - Controlote - Loteamento sob controle.';

  const [enterprises, setEnterprises] = useState([]);

  const getEnterprises = async () => {
    const data = await getEnterprisesById(currentUser()?.id);
    setEnterprises(data);
  };

  const checkReservations = async () => {
    const docs = await getDocs(
      query(
        collection(database, COLLECTION.RESERVATIONS),
        where(
          'enterprise',
          'in',
          enterprises?.map(doc => doc?.id)
        )
      )
    );
    const dataDocs = docs?.docs?.map(doc => doc.data());
    await checkReservationsExpired(dataDocs);
  };

  useEffect(() => {
    getEnterprises();
  }, []);

  useEffect(() => {
    checkReservations();
  }, [enterprises]);

  return (
    <RestrictArea permission={USER_PERMISSION.USER} title={'Empreendimentos'}>
      <Container>
        <Grid>
          {enterprises &&
            enterprises.map(enterprise => {
              return <CardEnterprise {...{ enterprise }} />;
            })}
        </Grid>
      </Container>
    </RestrictArea>
  );
};

export default Enterprises;
