import { getCollectionPrefix } from '../utils/getCollectionPrefix';

export const COLLECTION = {
  USERS: getCollectionPrefix() + 'users',
  CONFIG: getCollectionPrefix() + 'config',
  ENTERPRISES: getCollectionPrefix() + 'enterprises',
  INVITES: getCollectionPrefix() + 'invites',
  PROPOSALS: getCollectionPrefix() + 'proposals',
  RESERVATIONS: getCollectionPrefix() + 'reservations',
  LOGS: getCollectionPrefix() + 'logs',
};
