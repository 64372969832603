import React, { useEffect, useState } from 'react';
import LogoContainer from '../../common/components/LogoContainer/LogoContainer';
import { CardActions, CardContent } from '@mui/material';
import { CardStyled } from '../../common/components/CardStyled/CardStyled';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import { isDesktop } from '../../common/services/screen';
import { LoginWrapper, Title } from './styled';
import { Container } from '../../common/components/LogoContainer/styled';
import { loginWithEmail, resetPassword } from '../../common/services/auth';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { translateError } from '../../common/utils/error';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getDataForm, isValidForm } from '../../common/utils/formController';
import { setLocalStorage } from '../../common/utils/storage';
import { createLog } from '../../common/services/log';
import { LOG_TYPES } from '../../common/constants/LOG_TYPES';

const LoginAdmin = () => {
  const [logoWidth, setLogoWidth] = useState('100%');
  const [position, setPosition] = useState(isDesktop ? '-60%' : '-100%');
  const [form, setForm] = useState();
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  document.title = 'Admin Login - Controlote';

  useEffect(() => {
    setTimeout(() => {
      isDesktop ? setLogoWidth('40%') : setLogoWidth('0');
      setPosition('0');
    }, 1000);
  }, []);

  const signInWithEmail = async () => {
    setDisable(true);
    if (isValidForm(form, setForm)) {
      try {
        const data = getDataForm(form);
        const user = await loginWithEmail(data.email, data.password);
        if (user?.permission === USER_PERMISSION.ADMIN) {
          createLog({
            type: LOG_TYPES.LOGIN,
            to: USER_PERMISSION.ADMIN,
            who: user,
          });
          setLocalStorage('user', user);
          navigate('../admin/home');
        } else {
          enqueueSnackbar('Usuário sem permissão', { variant: ALERT_TYPES.ERROR });
        }
      } catch (error) {
        enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
      }
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setDisable(false);
  };

  const forgetPassword = () => {
    if (form?.email.value.length > 0 && form?.email.onValidation(form?.email.value)) {
      resetPassword(form?.email.value)
        .then(response => {
          enqueueSnackbar('E-mail de recuperação enviado!', { variant: ALERT_TYPES.SUCCESS });
        })
        .catch(error => {
          enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
        });
      return;
    }
    enqueueSnackbar('Email inválido.', { variant: ALERT_TYPES.WARNING });
  };

  return (
    <Container>
      <LogoContainer width={logoWidth} />
      <LoginWrapper position={position}>
        <CardStyled width={isDesktop ? '50%' : '80%'}>
          <CardContent>
            <Title>Admin - Entrar</Title>
            <InputStyled
              onValidation={value =>
                String(value)
                  .toLowerCase()
                  .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
              }
              messageValidation={'E-mail inválido.'}
              controller={{ form, setForm }}
              name={'email'}
              label={'E-mail'}
              type={'email'}
            />
            <InputStyled
              onValidation={value => value.length >= 6}
              messageValidation={'Senha deve ter 6 ou mais caracteres.'}
              controller={{ form, setForm }}
              name={'password'}
              label={'Senha'}
              type={'password'}
            />
            <ButtonStyled
              disabled={disable}
              onClick={() => signInWithEmail()}
              size="large"
              width={'100%'}
              bg={COLORS.GRAY_BLACK}
              variant="contained"
            >
              Entrar
            </ButtonStyled>
          </CardContent>
          <CardActions>
            <ButtonStyled onClick={() => forgetPassword()} colorText={COLORS.GRAY} bg={COLORS.WHITE}>
              Esqueceu a senha?
            </ButtonStyled>
          </CardActions>
        </CardStyled>
      </LoginWrapper>
    </Container>
  );
};

export default LoginAdmin;
