import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import InputStyled from '../InputStyled/InputStyled';
import { InputAdornment, Tab, Typography } from '@mui/material';
import { formatReal, isEmptyObject, realToNumber } from '../../utils/objectManipulation';
import { Spacing } from '../Spacing/Spacing';
import TabsStyled from '../TabsStyled/TabsStyled';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { COLORS } from '../../constants/COLORS';
import { PLANT_STATUS } from '../../constants/PLANT_STATUS';
import { saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { useSnackbar } from 'notistack';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import AlertDialog from '../AlertDialog/AlertDialog';

const ModalPrice = ({ open, setOpen, enterprise }) => {
  const [form, setForm] = useState();
  const [types, setTypes] = useState(0);
  const [action, setAction] = useState(0);
  const [prices, setPrices] = useState([]);
  const [newPrices, setNewPrices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openCloseModal, setOpenCloseModal] = useState();

  useEffect(() => {
    adjustValue();
  }, [form, action]);

  const adjustValue = () => {
    let tmpPrices = [];
    let tmpNewPrices = [];
    // eslint-disable-next-line no-unused-expressions
    enterprise?.forms
      ?.filter(item => item.status === PLANT_STATUS.AVAILABLE)
      .map(item => {
        tmpPrices = [...tmpPrices, { price: item?.price, name: item?.name }];
        let newValue;
        if (action === 0) {
          newValue =
            types === 0
              ? (realToNumber(item?.price) + realToNumber(form?.price?.value)) * 100
              : ((Number.parseFloat(form?.percentage?.value) + 100) * (realToNumber(item?.price) * 100)) / 100;
        } else {
          const valueResult =
            ((Number.parseFloat(form?.percentage?.value) + 100) * (realToNumber(item?.price) * 100)) / 100;
          newValue =
            types === 0
              ? (realToNumber(item?.price) - realToNumber(form?.price?.value)) * 100
              : realToNumber(item?.price) * 100 - (valueResult - realToNumber(item?.price) * 100);
        }
        tmpNewPrices = [...tmpNewPrices, { price: formatReal(newValue), name: item?.name }];
        return item;
      });
    setPrices(tmpPrices);
    setNewPrices(tmpNewPrices);
  };

  const save = () => {
    setOpenCloseModal(true);
  };

  const saveInClosed = async () => {
    try {
      const newEnterprise = { ...enterprise };
      // eslint-disable-next-line no-unused-expressions
      newEnterprise?.forms
        ?.filter(item => item.status === PLANT_STATUS.AVAILABLE)
        ?.map(item => {
          const newPrice = newPrices.find(price => price.name === item.name);
          item.price = newPrice.price;
          return item;
        });
      await saveEnterprisesAdmin(newEnterprise);
      setOpen(false);
      setOpenCloseModal(false);
      setForm({});
      enqueueSnackbar('Preços ajustados com sucesso!', { variant: ALERT_TYPES.SUCCESS });
    } catch (error) {
      enqueueSnackbar('Error ao ajustar os preços', { variant: ALERT_TYPES.ERROR });
    }
  };

  const getChangeValue = () =>
    types === 0 ? 'R$' + form?.price?.value : Number.parseFloat(form?.percentage?.value) + '%';

  const getOperation = () => (action === 0 ? 'aumentar' : 'diminuir');

  return (
    <ModalStyled open={open} setOpen={setOpen} title={'Ajustar Preços'} width={'60%'}>
      <AlertDialog
        open={openCloseModal}
        title={'Confirmação!'}
        text={
          'Será ajustado somente os valores dos lotes em status disponíveis. ' +
          'Você confirma ' +
          getOperation() +
          ' os preços em: ' +
          getChangeValue() +
          ' ?'
        }
        handleClose={() => setOpenCloseModal(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenCloseModal(false)}>Cancelar</ButtonStyled>
            <ButtonStyled
              onClick={() => {
                saveInClosed();
              }}
            >
              Confirmar e Salvar
            </ButtonStyled>
          </>
        }
      />
      <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px', color: COLORS.YELLOW }}>
        Alerta! Será ajustado somente os valores dos lotes em status disponíveis.
      </Typography>
      <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
        Operação de:
      </Typography>
      <TabsStyled value={action}>
        <Tab label="Aumentar" onClick={() => setAction(0)} />
        <Tab label="Diminuir" onClick={() => setAction(1)} />
      </TabsStyled>
      <Typography align={'left'} sx={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}>
        Ajustar em:
      </Typography>
      <TabsStyled value={types}>
        <Tab label="Valor" onClick={() => setTypes(0)} />
        <Tab label="Porcentagem" onClick={() => setTypes(1)} />
      </TabsStyled>
      <Spacing margin={'16px 0 0 0'}>
        {types === 0 && (
          <InputStyled
            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
            mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
            controller={{ form, setForm }}
            name={'price'}
            label={'Valor'}
            notRequired={true}
          />
        )}
        {types !== 0 && (
          <InputStyled
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            controller={{ form, setForm }}
            name={'percentage'}
            label={'Porcentagem'}
            notRequired={true}
          />
        )}
      </Spacing>
      <Spacing fd={'row'} jc={'flex-start'}>
        <Typography align={'left'} sx={{ width: '20%', marginBottom: '8px' }}>
          Lote
        </Typography>
        <Typography align={'left'} sx={{ width: '40%', marginBottom: '8px' }}>
          Preço Atual
        </Typography>
        <Typography align={'left'} sx={{ width: '40%', marginBottom: '8px' }}>
          Novo Preço
        </Typography>
      </Spacing>
      <Spacing overflow height={'321px'} jc={'flex-start'} ai={'flex-start'}>
        {!isEmptyObject(prices) &&
          (action !== 20 && types === 0
            ? !isEmptyObject(form?.price?.value)
            : !isEmptyObject(form?.percentage?.value)) &&
          prices?.map((price, index) => (
            <Spacing fd={'row'} width={'100%'}>
              <Typography align={'left'} sx={{ width: '20%', marginBottom: '8px' }}>
                {price?.name}
              </Typography>
              <Typography align={'left'} sx={{ width: '40%', marginBottom: '8px' }}>
                R${price?.price}
              </Typography>
              <Typography align={'left'} sx={{ width: '40%', marginBottom: '8px' }}>
                R${newPrices[index]?.price}
              </Typography>
            </Spacing>
          ))}
      </Spacing>
      <Spacing ai={'flex-end'}>
        <ButtonStyled onClick={() => save()} bg={COLORS.GREEN_LIGHT} colorText={COLORS.WHITE}>
          Ajustar
        </ButtonStyled>
      </Spacing>
    </ModalStyled>
  );
};

export default ModalPrice;
