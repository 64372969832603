import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CardEnterprise = ({ enterprise }) => {
  return (
    <Link to={'../empreendimento/' + enterprise?.id}>
      <Card style={{ margin: '16px', cursor: 'pointer', maxWidth: '250px' }}>
        <CardMedia style={{ width: '250px' }} component="img" src={enterprise?.image} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {enterprise?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {enterprise?.description}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

export default CardEnterprise;
