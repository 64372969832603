import React, { useEffect, useState } from 'react';
import AlertDialog from '../AlertDialog/AlertDialog';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import ModalStyled from '../ModalStyled/ModalStyled';
import { isEmptyObject, realToNumber } from '../../utils/objectManipulation';
import { Accepted, ChatSender, Text, Title, WrapperChat, WrapperText } from './styled';
import { Spacing } from '../Spacing/Spacing';
import { COLORS } from '../../constants/COLORS';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { database } from '../../services/firebase';
import { changeEnterpriseStatus, saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { PLANT_STATUS } from '../../constants/PLANT_STATUS';
import { deleteProposal, saveProposal } from '../../services/proposal';
import { COLLECTION } from '../../constants/COLLECTION';
import { currentUser } from '../../services/user';
import { dateToString } from '../../utils/date';
import InputStyled from '../InputStyled/InputStyled';
import { clearForm, getDataForm, isValidForm } from '../../utils/formController';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import { getDatabase } from '../../services/firestore';
import { WhatsApp } from '@mui/icons-material';
import { clearNotification, sendPushNotification } from '../../services/notification';
import { USER_PERMISSION } from '../../constants/USER_PERMISSION';
import { useNavigate } from 'react-router-dom';
import ModalParcels from '../ModalParcels/ModalParcels';
import { calcParcels } from '../../services/calc';

const ModalProposal = ({ setOpen, open, id, getProposals, isAdmin = false }) => {
  const [proposal, setProposal] = useState();
  const [enterprise, setEnterprise] = useState();
  const [user, setUser] = useState();
  const [proposalUser, setProposalUser] = useState();
  const [form, setForm] = useState();
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalParcels, setModalParcels] = useState(false);
  const [parcels, setParcels] = useState([]);
  const navigate = useNavigate();

  const getEnterprise = async () => {
    if (!isEmptyObject(proposal)) {
      const data = await getDoc(doc(database, COLLECTION.ENTERPRISES, proposal?.enterprise));
      setEnterprise(data?.data());
    }
  };

  const getProposalUser = async () => {
    if (!isEmptyObject(proposal)) {
      const data = await getDoc(doc(getDatabase(), COLLECTION.USERS, proposal?.user));
      setProposalUser(data.data());
    }
  };

  const removerProposal = async () => {
    setIsLoading(true);
    const newEnterprise = changeEnterpriseStatus(
      PLANT_STATUS.AVAILABLE,
      enterprise,
      proposal?.square + proposal?.number
    );
    await saveEnterprisesAdmin(newEnterprise);
    await deleteProposal(proposal);
    getProposals();
    setOpenCloseDialog(false);
    setOpen(false);
    navigate(currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/propostas' : '../propostas');
    setIsLoading(false);
  };

  const acceptProposal = async () => {
    setIsLoading(true);
    const newEnterprise = changeEnterpriseStatus(PLANT_STATUS.SOLD, enterprise, proposal?.square + proposal?.number);
    await saveEnterprisesAdmin(newEnterprise);
    const newProposal = {
      ...proposal,
      sold: true,
    };
    await saveProposal(newProposal);
    if (isAdmin) {
      sendPushNotification(
        'Sua proposta foi aceita!',
        'Lote ' +
          proposal?.number +
          ' da quadra ' +
          proposal?.square +
          ' em ' +
          enterprise?.name +
          ' teve sua proposta aceita.',
        proposal?.user,
        window.location.origin + '/propostas/' + proposal?.id
      );
    }
    getProposals();
    setOpenAcceptDialog(false);
    setOpen(false);
    navigate(currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/propostas' : '../propostas');
    setIsLoading(false);
  };

  const sendMessage = async () => {
    if (isValidForm(form, setForm)) {
      setForm({ ...form, onSubmit: false });
      const data = getDataForm(form);
      const newProposal = {
        ...proposal,
        chat: [
          ...proposal?.chat,
          {
            message: data?.message,
            sender: user?.permission,
            date: new Date(),
            senderName: user?.name,
          },
        ],
      };
      await saveProposal(newProposal);
      if (isAdmin) {
        sendPushNotification(
          'Nova mensagem de ' +
            user?.name +
            ' sobre o lote ' +
            proposal?.number +
            ' da quadra ' +
            proposal?.square +
            ' localizado no ' +
            enterprise?.name +
            '.',
          data?.message,
          proposal?.user,
          window.location.origin + '/propostas/' + proposal?.id
        );
      } else {
        enterprise.user.map(userId =>
          sendPushNotification(
            'Nova mensagem de ' +
              user?.name +
              ' sobre o lote ' +
              proposal?.number +
              ' da quadra ' +
              proposal?.square +
              ' localizado no ' +
              enterprise?.name +
              '.',
            data?.message,
            userId,
            window.location.origin + '/admin/propostas/' + proposal?.id
          )
        );
      }
      setForm(clearForm(form));
    } else {
      enqueueSnackbar('O campo mensagem deve ser preenchido.', { variant: ALERT_TYPES.WARNING });
    }
  };

  const getParcels = () => {
    if (proposal?.firstPrice) {
      console.log(
        'Pietro',
        realToNumber(proposal?.price) - realToNumber(proposal?.firstPrice),
        proposal?.parcels,
        proposal?.tax,
        proposal?.systemType
      );
      setParcels(
        calcParcels(
          realToNumber(proposal?.price) - realToNumber(proposal?.firstPrice),
          proposal?.parcels,
          proposal?.tax,
          proposal?.systemType
        )
      );
    } else {
      setParcels(calcParcels(proposal?.price, proposal?.parcels, proposal?.tax, proposal?.systemType));
    }
  };

  useEffect(() => {
    getEnterprise();
    getProposalUser();
    getParcels();
  }, [proposal]);

  useEffect(() => {
    setUser(currentUser());
    clearNotification();
  }, [proposal]);

  useEffect(() => {
    if (!isEmptyObject(id)) {
      const unsub = onSnapshot(doc(database, COLLECTION.PROPOSALS, id), async data => {
        await setProposal(data?.data());
        const elementById = document.getElementById('chat');
        elementById.scrollTo({ top: elementById.scrollHeight, behavior: 'smooth' });
      });
      return () => {
        unsub();
      };
    }
  }, [id]);

  const checkChat = message => {
    if (message === '<modalPrice>') {
      if (proposal?.firstPrice) {
        return (
          <>
            Total: R${proposal?.price} <br /> Entrada: R${proposal?.firstPrice} <br /> em {proposal?.parcels}X <br />{' '}
            <ButtonStyled onClick={() => setModalParcels(true)}>Ver Parcelas</ButtonStyled>
          </>
        );
      }
      return (
        <>
          R${proposal?.price} <ButtonStyled>Ver Parcelas</ButtonStyled>
        </>
      );
    }
    return message;
  };

  async function sendToWhats() {
    return window.open(
      `https://wa.me/${enterprise?.phone}?text=Acesse o link para abrir a proposta do lote ${proposal?.number} na quadra ${proposal?.square} do empreendimento ${enterprise?.name} :%0A https://${window.location.hostname}/admin/proposal/${proposal?.id}`,
      '_blank'
    );
  }

  return (
    <>
      <AlertDialog
        open={openCloseDialog}
        title={'Ops!'}
        text={'Deseja realmente excluir a proposta?'}
        handleClose={() => setOpenCloseDialog(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenCloseDialog(false)}>Cancelar</ButtonStyled>
            <ButtonStyled onClick={() => removerProposal()}>Excluir</ButtonStyled>
          </>
        }
      />
      <AlertDialog
        open={openAcceptDialog}
        title={'Uhull!'}
        text={'Deseja realmente aceitar a proposta? (O lote ficara com status de vendido)'}
        handleClose={() => setOpenAcceptDialog(false)}
        actions={
          <>
            <ButtonStyled disabled={isLoading} onClick={() => setOpenAcceptDialog(false)}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled disabled={isLoading} onClick={() => acceptProposal()}>
              Aceitar
            </ButtonStyled>
          </>
        }
      />
      <ModalParcels open={modalParcels} setOpen={setModalParcels} parcels={parcels} />
      <ModalStyled
        setOpen={setOpen}
        onClose={() => {
          getProposals();
          navigate(currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/propostas' : '../propostas');
        }}
        open={open}
        title={
          isEmptyObject(proposal)
            ? 'Proposta não encontrada!'
            : 'Proposta: ' + proposal?.enterpriseName + ' | ' + proposal?.square + ' - ' + proposal?.number
        }
      >
        {!isEmptyObject(proposal) && (
          <>
            {proposal?.sold === true && <Accepted>Essa Proposta foi aceita!</Accepted>}
            <Title>Cliente</Title>
            <WrapperText>
              <Text>Nome: </Text>
              <Text>{proposal?.clientName}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Telefone: </Text>
              <Text>{proposal?.clientPhone}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Identificação: </Text>
              <Text>{proposal?.clientIdentification}</Text>
            </WrapperText>
            {isAdmin && (
              <>
                <Title>Corretor</Title>
                <WrapperText>
                  <Text>Nome: </Text>
                  <Text>{proposal?.userName}</Text>
                </WrapperText>
                <WrapperText>
                  <Text>CRECI: </Text>
                  <Text>{proposalUser?.creci}</Text>
                </WrapperText>
                <WrapperText>
                  <Text>Telefone: </Text>
                  <Text>
                    {proposalUser?.phone
                      .toString()
                      .substring(3, proposalUser?.phone.length)
                      .replace(/\D/g, '')
                      .replace(/(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{5})(\d{4})/, '$1-$2')}
                  </Text>
                </WrapperText>
              </>
            )}
            <Title>Notas de Negociação</Title>
            <Spacing height={'350px'} overflow jc={'flex-start'} id={'chat'}>
              {proposal?.chat.map(message => (
                <WrapperChat isSender={message.sender === user?.permission}>
                  <ChatSender>{message.senderName + ' - ' + dateToString(message.date)}</ChatSender>
                  {checkChat(message.message)}
                </WrapperChat>
              ))}
            </Spacing>
            <Spacing
              onClick={() => {
                setTimeout(() => {
                  const elementById = document.getElementById('modalBody');
                  elementById.scrollTo({ top: elementById.scrollHeight, behavior: 'smooth' });
                }, [150]);
              }}
              margin={'8px 0 0 0'}
              fd={'row'}
              ai={'baseline'}
            >
              <InputStyled controller={{ form, setForm }} name={'message'} label={'Mensagem'} type={'text'} />
              <ButtonStyled onClick={() => sendMessage()}>Enviar</ButtonStyled>
            </Spacing>
            {proposal?.sold !== true ? (
              <Spacing jc={'flex-end'} fd={'row'} margin={'16px 0 0 0'}>
                {isAdmin && (
                  <>
                    <ButtonStyled
                      onClick={() => sendToWhats()}
                      margin={'0 16px 0 0'}
                      variant="contained"
                      component="span"
                      bg={COLORS.GREEN_LIGHT}
                      startIcon={<WhatsApp />}
                    >
                      Enviar para financeiro
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={() => setOpenAcceptDialog(true)}
                      margin={'0 16px 0 0'}
                      variant="contained"
                      component="span"
                      bg={COLORS.BLUE}
                    >
                      Aceitar Proposta
                    </ButtonStyled>
                  </>
                )}
                <ButtonStyled
                  onClick={() => setOpenCloseDialog(true)}
                  variant="contained"
                  component="span"
                  bg={COLORS.RED}
                >
                  Excluir Proposta
                </ButtonStyled>
              </Spacing>
            ) : (
              isAdmin && (
                <Spacing jc={'flex-end'} fd={'row'} margin={'16px 0 0 0'}>
                  <ButtonStyled
                    onClick={() => sendToWhats()}
                    margin={'0 16px 0 0'}
                    variant="contained"
                    component="span"
                    bg={COLORS.GREEN_LIGHT}
                    startIcon={<WhatsApp />}
                  >
                    Enviar para financeiro
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setOpenCloseDialog(true)}
                    variant="contained"
                    component="span"
                    bg={COLORS.RED}
                  >
                    Excluir Proposta
                  </ButtonStyled>
                </Spacing>
              )
            )}
          </>
        )}
      </ModalStyled>
    </>
  );
};

export default ModalProposal;
