import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pagesUser/Login';
import HomeAdmin from './pagesAdmin/Home/Home';
import Invite from './pages/Invite/Invite';
import InvitesAdmin from './pagesAdmin/Invites/Invites';
import LoginAdmin from './pagesAdmin/LoginAdmin/LoginAdmin';
import EnterprisesAdmin from './pagesAdmin/Enterprises/Enterprises';
import Home from './pagesUser/Home/Home';
import ReservationsAdmin from './pagesAdmin/Reservations/Reservations';
import ProposalsAdmin from './pagesAdmin/Proposals/Proposals';
import Enterprises from './pagesUser/Enterprises/Enterprises';
import Enterprise from './pagesUser/Enterprise/Enterprise';
import Proposals from './pagesUser/Proposals/Proposals';
import Reservations from './pagesUser/Reservations/Reservations';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import EnterpriseView from './pagesAdmin/EnterpriseView/EnterpriseView';
import GlobalComponents from './common/components/GlobalComponents/GlobalComponents';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <React.StrictMode>
    <React.StrictMode>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <GlobalComponents>
            <Routes>
              <Route path="/admin/home" element={<HomeAdmin />} />
              <Route path="/admin/convite/:id" element={<Invite />} />
              <Route path="/admin/convites" element={<InvitesAdmin />} />
              <Route path="/admin/empreendimentos" element={<EnterprisesAdmin />} />
              <Route path="/admin/reservas" element={<ReservationsAdmin />} />
              <Route path="/admin/propostas" element={<ProposalsAdmin />} />
              <Route path="/admin/reservas/:id" element={<ReservationsAdmin />} />
              <Route path="/admin/propostas/:id" element={<ProposalsAdmin />} />
              <Route path="/admin/empreendimento-view/:id" element={<EnterpriseView />} />
              <Route path="/admin" element={<LoginAdmin />} />
              <Route path="/convite/:id" element={<Invite />} />
              <Route path="/home" element={<Home />} />
              <Route path="/empreendimentos" element={<Enterprises />} />
              <Route path="/empreendimento/:id" element={<Enterprise />} />
              <Route path="/propostas" element={<Proposals />} />
              <Route path="/reservas" element={<Reservations />} />
              <Route path="/propostas/:id" element={<Proposals />} />
              <Route path="/reservas/:id" element={<Reservations />} />
              <Route path="/politica-privacidade" element={<PrivacyPolicy />} />
              <Route path="/termos-de-uso" element={<TermsOfUse />} />
              <Route path="/" element={<Login />} />
            </Routes>
          </GlobalComponents>
        </BrowserRouter>
      </SnackbarProvider>
    </React.StrictMode>
  </React.StrictMode>,
  document.getElementById('root')
);
