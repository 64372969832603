import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import { Spacing } from '../Spacing/Spacing';
import InputStyled from '../InputStyled/InputStyled';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { COLORS } from '../../constants/COLORS';
import { saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import { translateError } from '../../utils/error';
import { getDataForm, isValidForm } from '../../utils/formController';
import { Checkbox, FormControlLabel, InputAdornment, Radio, RadioGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { formatReal, isEmptyObject } from '../../utils/objectManipulation';

const ModalTax = ({ open, setOpen, enterprise }) => {
  const [form, setForm] = useState();
  const [minimumEntryType, setMinimumEntryType] = useState(0);
  const [systemType, setSystemType] = useState({ sac: true, price: false });
  const [showInformation, setShowInformation] = useState({ amortization: false, tax: false });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setForm({
      minimumEntry: { value: enterprise?.tax?.minimumEntry, required: false },
      maxParcels: { value: enterprise?.tax?.maxParcels },
      tax: { value: enterprise?.tax?.tax },
    });
    !isEmptyObject(enterprise?.tax?.minimumEntryType) && setMinimumEntryType(enterprise?.tax?.minimumEntryType);
    !isEmptyObject(enterprise?.tax?.systemType) && setSystemType(enterprise?.tax?.systemType);
    !isEmptyObject(enterprise?.tax?.showInformation) && setShowInformation(enterprise?.tax?.showInformation);
  }, [enterprise]);

  const saveTax = async () => {
    if (systemType.sac || systemType.price) {
      if (isValidForm(form, setForm)) {
        enterprise = { ...enterprise, tax: { ...getDataForm(form), systemType, minimumEntryType, showInformation } };
        try {
          await saveEnterprisesAdmin(enterprise);
          setOpen(false);
          enqueueSnackbar('Salvo com sucesso!', { variant: ALERT_TYPES.SUCCESS });
        } catch (error) {
          enqueueSnackbar(translateError(error?.code), { variant: ALERT_TYPES.ERROR });
        }
      } else {
        enqueueSnackbar('Valid For', { variant: ALERT_TYPES.ERROR });
      }
    } else {
      enqueueSnackbar('Tem que marcar ao menos um sistema de tabela.', { variant: ALERT_TYPES.ERROR });
    }
  };

  return (
    <ModalStyled open={open} setOpen={setOpen} title={'Sistema de Financiamento'}>
      <Spacing fd={'column'} jc={'space-between'} ai={'flex-start'} margin={'0 0 16px 0'}>
        <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
          Sistema de Tabela
        </Typography>
        <Spacing fd={'row'} padding={'0 0 16px 0'}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setSystemType(value => {
                    return { ...value, sac: !value.sac };
                  })
                }
                checked={systemType.sac}
              />
            }
            label="SAC"
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setSystemType(value => {
                    return { ...value, price: !value.price };
                  })
                }
                checked={systemType.price}
              />
            }
            label="PRICE"
          />
        </Spacing>
        <InputStyled
          controller={{ form, setForm }}
          name={'maxParcels'}
          label={'Número máximo de Parcelas'}
          type={'number'}
          maxWidth={'65%'}
          paddingBottom={'16px'}
        />
        <InputStyled
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          controller={{ form, setForm }}
          name={'tax'}
          label={'Taxa de Juros'}
          type={'number'}
          maxWidth={'65%'}
          paddingBottom={'16px'}
        />
        <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
          Configurar entrada mínima em:
        </Typography>
        <Spacing fd={'row'} padding={'0 0 16px 0'}>
          <RadioGroup
            row
            onChange={event => {
              setMinimumEntryType(Number(event.target.value));
              setForm(value => {
                return { ...value, minimumEntry: { value: '', required: false } };
              });
            }}
          >
            <FormControlLabel value={0} checked={minimumEntryType === 0} control={<Radio />} label="Valor" />
            <FormControlLabel value={1} checked={minimumEntryType === 1} control={<Radio />} label="Percentual" />
            <FormControlLabel
              value={2}
              checked={minimumEntryType === 2}
              control={<Radio />}
              label="Permitir sem entrada"
            />
          </RadioGroup>
        </Spacing>
        {minimumEntryType === 0 && (
          <InputStyled
            controller={{ form, setForm }}
            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
            mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
            name={'minimumEntry'}
            label={'Entrada mínima'}
            maxWidth={'65%'}
            notRequired
            paddingBottom={'0'}
          />
        )}
        {minimumEntryType === 1 && (
          <InputStyled
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            controller={{ form, setForm }}
            name={'minimumEntry'}
            label={'Entrada mínima'}
            type={'number'}
            maxWidth={'65%'}
            notRequired
            paddingBottom={'0'}
          />
        )}
        <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
          Informações mostradas ao Corretor:
        </Typography>
        <Spacing fd={'row'} padding={'0 0 16px 0'}>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setShowInformation(value => {
                    return { ...value, amortization: !value.amortization };
                  })
                }
                checked={showInformation.amortization}
              />
            }
            label="Amortização"
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setShowInformation(value => {
                    return { ...value, tax: !value.tax };
                  })
                }
                checked={showInformation.tax}
              />
            }
            label="Juros"
          />
        </Spacing>
      </Spacing>
      <Spacing ai={'flex-end'}>
        <ButtonStyled onClick={() => saveTax()} margin={'0 0 0 8px'} bg={COLORS.GREEN} colorText={COLORS.WHITE}>
          Salvar taxas
        </ButtonStyled>
      </Spacing>
    </ModalStyled>
  );
};

export default ModalTax;
