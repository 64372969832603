import { getDatabase } from './firestore';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getLocalStorage } from '../utils/storage';
import { COLLECTION } from '../constants/COLLECTION';
import { database } from './firebase';

export const getUserByEmail = email => {
  return getDocs(query(collection(getDatabase(), COLLECTION.USERS), where('email', '==', email)));
};

export const getAdminById = async id => {
  return getUserById(id);
};

export const getUserById = async id => {
  const document = await getDoc(doc(database, COLLECTION.USERS, id));
  return document.data();
};

export const currentUser = () => getLocalStorage('user');
