import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import { FormControlLabel, InputAdornment, Radio, Slider, Typography } from '@mui/material';
import InputStyled from '../InputStyled/InputStyled';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { COLORS } from '../../constants/COLORS';
import { Spacing } from '../Spacing/Spacing';
import { formatReal, isEmptyObject, realToNumber } from '../../utils/objectManipulation';
import { PLANT_STATUS } from '../../constants/PLANT_STATUS';
import { Description, Price, Status } from './styled';
import { clearForm, getDataForm, isValidForm } from '../../utils/formController';
import { getColor } from '../../services/imageMapper';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import { changeEnterpriseStatus, saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { createReservation } from '../../services/reservation';
import { useSnackbar } from 'notistack';
import { RESERVATION_STATUS } from '../../constants/RESERVATION_STATUS';
import AlertDialog from '../AlertDialog/AlertDialog';
import { USER_PERMISSION } from '../../constants/USER_PERMISSION';
import { currentUser, getAdminById } from '../../services/user';
import { createProposal } from '../../services/proposal';
import { sendEmail, sendPushNotification } from '../../services/notification';
import { getDataDocs } from '../../services/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { COLLECTION } from '../../constants/COLLECTION';
import { database } from '../../services/firebase';
import { around, calcParcels } from '../../services/calc';
import ModalParcels from '../ModalParcels/ModalParcels';

const ModalAreaClient = ({ open, setOpen, enterprise, setArea, area, setIsProposal, isProposal }) => {
  const [form, setForm] = useState();
  const [finalParcels, setFinalParcels] = useState();
  const [openParcels, setOpenParcels] = useState(false);
  const [showDialogReservation, setShowDialogReservation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [minimumEntry, setMinimumEntry] = useState();
  const [systemType, setSystemType] = useState();

  const findArea = () => {
    if (!isEmptyObject(form?.square.value)) {
      setArea(enterprise?.forms.find(item => item?.name === form?.square.value + form?.number.value));
    } else if (!isEmptyObject(area)) {
      setForm({ square: { value: area?.square }, number: { value: area?.number } });
    }
  };

  useEffect(() => {
    findArea();
  }, [open, form, enterprise, area]);

  useEffect(() => {
    if (!enterprise?.tax?.systemType?.sac && enterprise?.tax?.systemType?.price) {
      setSystemType({ sac: false, price: true });
    } else {
      setSystemType({ sac: true, price: false });
    }
    if (enterprise?.tax?.minimumEntryType === 0) {
      setMinimumEntry(realToNumber(enterprise?.tax?.minimumEntry));
    } else if (enterprise?.tax?.minimumEntryType === 1) {
      const price = realToNumber(area?.price);
      const value = price * (enterprise?.tax?.minimumEntry / 100);
      setMinimumEntry(Number(around(value)));
    } else {
      setMinimumEntry(false);
    }
  }, [enterprise, area]);

  useEffect(() => {
    const price = realToNumber(area?.price);
    const parcels = form?.parcels?.value;
    const tax = enterprise?.tax?.tax;
    if (isEmptyObject(form?.firstPrice?.value)) {
      setFinalParcels(calcParcels(price, parcels, tax, systemType?.sac));
    } else {
      setFinalParcels(calcParcels(price - realToNumber(form?.firstPrice?.value), parcels, tax, systemType?.sac));
    }
  }, [form, systemType]);

  const checkMaxReservation = async () => {
    const dataDocs = getDataDocs(
      await getDocs(query(collection(database, COLLECTION.RESERVATIONS), where('user', '==', currentUser()?.id)))
    );
    return Number(enterprise?.maxReservation) > dataDocs?.length && Number(enterprise?.maxReservation) !== 0;
  };

  const saveReservation = async () => {
    setIsLoading(true);
    if (isValidForm(form, setForm)) {
      if (await checkMaxReservation()) {
        setForm({ ...form, onSubmit: false });
        const newReservation = {
          ...getDataForm(form),
          enterprise: enterprise?.id,
          enterpriseName: enterprise?.name,
          status: RESERVATION_STATUS.RESERVED,
        };
        const newEnterprise = changeEnterpriseStatus(
          PLANT_STATUS.RESERVED,
          enterprise,
          form?.square.value + form?.number.value
        );
        const resId = (await createReservation(newReservation))[0]?.id;
        await saveEnterprisesAdmin(newEnterprise);
        enterprise.user.map(async user => {
          const admin = await getAdminById(user);
          sendEmail(
            admin?.email,
            'Nova Reserva!',
            'Olá, ' + admin?.name + '! Segue o link para acessar reserva.',
            window.location.origin + '/admin/reservas/' + resId
          );
          sendPushNotification(
            'Nova Reserva: ' +
              enterprise?.name +
              ' | lote ' +
              newReservation?.number +
              ' quadra ' +
              newReservation?.square,
            '',
            admin?.id,
            window.location.origin + '/admin/reservas/' + resId
          );
        });
        setShowDialogReservation(true);
        enqueueSnackbar('Reserva criada com sucesso.', { variant: ALERT_TYPES.SUCCESS });
      } else {
        enqueueSnackbar('Limite de reservas atingido.', { variant: ALERT_TYPES.WARNING });
      }
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setIsLoading(false);
  };

  const saveProposal = async () => {
    setIsLoading(true);
    if (isValidForm(form, setForm)) {
      setForm({ ...form, onSubmit: false });
      const data = getDataForm(form);
      let newProposal = {
        clientName: data.clientName,
        clientIdentification: data.clientIdentification,
        clientPhone: data.clientPhone,
        number: data.number,
        square: data.square,
        chat: [
          {
            message: 'R$' + data.price + ' - ' + data.description,
            sender: USER_PERMISSION.USER,
            date: new Date(),
            senderName: currentUser()?.name,
          },
        ],
        enterprise: enterprise?.id,
        enterpriseName: enterprise?.name,
      };
      if (!isEmptyObject(enterprise?.tax)) {
        newProposal = {
          clientName: data.clientName,
          clientIdentification: data.clientIdentification,
          clientPhone: data.clientPhone,
          number: data.number,
          square: data.square,
          price: area.price,
          firstPrice: data.firstPrice,
          parcels: data.parcels,
          tax: enterprise?.tax?.tax,
          systemType: systemType?.sac,
          chat: [
            {
              message: !isEmptyObject(enterprise?.tax) ? '<modalPrice>' : 'R$' + data.price + ' - ' + data.description,
              sender: USER_PERMISSION.USER,
              date: new Date(),
              senderName: currentUser()?.name,
            },
          ],
          enterprise: enterprise?.id,
          enterpriseName: enterprise?.name,
        };
      }
      const newEnterprise = changeEnterpriseStatus(
        PLANT_STATUS.IN_PROPOSAL,
        enterprise,
        form?.square.value + form?.number.value
      );
      const proId = (await createProposal(newProposal))[0]?.id;
      await saveEnterprisesAdmin(newEnterprise);
      enterprise.user.map(async user => {
        const admin = await getAdminById(user);
        sendEmail(
          admin?.email,
          'Nova Proposta!',
          'Olá, ' + admin?.name + '! Segue o link para acessar proposta.',
          window.location.origin + '/admin/propostas/' + proId
        );
        if (!isEmptyObject(enterprise?.tax)) {
          sendPushNotification(
            'Nova Proposta: ' + enterprise?.name + ' | lote ' + newProposal?.number + ' quadra ' + newProposal?.square,
            'Ver proposta',
            admin?.id,
            window.location.origin + '/admin/propostas/' + proId
          );
        } else {
          sendPushNotification(
            'Nova Proposta: ' + enterprise?.name + ' | lote ' + newProposal?.number + ' quadra ' + newProposal?.square,
            'R$' + data.price + ' - ' + data.description,
            admin?.id,
            window.location.origin + '/admin/propostas/' + proId
          );
        }
      });
      setOpen(false);
      enqueueSnackbar('Proposta criada com sucesso.', { variant: ALERT_TYPES.SUCCESS });
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setIsLoading(false);
  };

  function getTitle() {
    return isProposal ? 'Nova Proposta' : 'Nova Reserva';
  }

  function getTitleByStatus() {
    return area?.status === PLANT_STATUS.AVAILABLE ? getTitle() : 'Ops, não está disponivel.';
  }

  return (
    <ModalStyled
      open={open}
      onClose={() => {
        setForm(clearForm(form));
        setArea({});
      }}
      setOpen={setOpen}
      title={!isEmptyObject(area) ? getTitleByStatus() : getTitle()}
    >
      <AlertDialog
        open={showDialogReservation}
        title={'Reserva confirmada!'}
        text={'Sua reserva de 24h foi realizada com sucesso.'}
        handleClose={() => setShowDialogReservation(false)}
        actions={
          <>
            <ButtonStyled
              onClick={() => {
                setShowDialogReservation(false);
                setOpen(false);
                setForm(clearForm(form));
                setArea({});
              }}
            >
              Ok
            </ButtonStyled>
          </>
        }
      />
      <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
        Lote
      </Typography>
      {!isEmptyObject(area) && (
        <>
          <Description>{area?.description}</Description>
          <Price>R$ {area?.price}</Price>
          <Status color={getColor(area?.status)}>{area?.status}</Status>
        </>
      )}
      <InputStyled
        mask={value => value?.toString().toUpperCase()}
        controller={{ form, setForm }}
        name={'square'}
        label={'Quadra'}
        type={'text'}
      />
      <InputStyled controller={{ form, setForm }} name={'number'} label={'Número'} type={'number'} />
      {(!(isEmptyObject(area) || area?.status !== PLANT_STATUS.AVAILABLE) || isProposal) && (
        <>
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Cliente
          </Typography>
          <InputStyled controller={{ form, setForm }} name={'clientName'} label={'Nome'} type={'text'} />
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/\((\d{2})\)\s(\d{5})-(\d{4})/g) !== null && value.length === 15
            }
            messageValidation={'Celular inválido. Ex: (48) 99988-9988'}
            mask={value => {
              return (
                value &&
                value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '($1) $2')
                  .replace(/(\d{5})(\d{4})/, '$1-$2')
              );
            }}
            maxLength={15}
            controller={{ form, setForm }}
            name={'clientPhone'}
            label={'Teleforne'}
            type={'text'}
          />
          <InputStyled controller={{ form, setForm }} name={'clientIdentification'} label={'CPF'} type={'text'} />
        </>
      )}
      {isProposal && (
        <>
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Proposta
          </Typography>
          {!isEmptyObject(enterprise?.tax) ? (
            <Spacing padding={'8px'}>
              <ModalParcels
                open={openParcels}
                setOpen={setOpenParcels}
                enterprise={enterprise}
                parcels={finalParcels}
              />
              <InputStyled
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
                controller={{ form, setForm }}
                onValidation={value =>
                  (minimumEntry === false || realToNumber(value) >= minimumEntry) &&
                  realToNumber(value) < realToNumber(area?.price)
                }
                messageValidation={
                  minimumEntry === false
                    ? 'Valor máximo de R$' +
                      formatReal(parseInt((realToNumber(area?.price) - 0.01).toString().replace(/\D+/g, '')))
                    : 'Valor minimo de R$' +
                      formatReal(
                        parseInt(realToNumber(around(minimumEntry)))
                          .toString()
                          .replace(/\D+/g, '')
                      ) +
                      ' e máximo de R$' +
                      formatReal(parseInt((realToNumber(area?.price) - 0.01).toString().replace(/\D+/g, '')))
                }
                notRequired={enterprise?.tax?.minimumEntryType === 2}
                name={'firstPrice'}
                label={'Entrada'}
                type={'text'}
              />
              {enterprise?.tax?.systemType?.sac && enterprise?.tax?.systemType?.price && (
                <>
                  <Typography align={'left'} sx={{ color: COLORS.GRAY, width: '100%', marginBottom: '8px' }}>
                    Sistema
                  </Typography>
                  <Spacing fd={'row'} jc={'flex-start'} width={'100%'} padding={'0 0 16px 0'}>
                    <FormControlLabel
                      control={
                        <Radio
                          onClick={() => setSystemType({ sac: true, price: false })}
                          checked={systemType?.sac}
                          defaultChecked
                        />
                      }
                      label="SAC"
                    />
                    <FormControlLabel
                      control={
                        <Radio onClick={() => setSystemType({ sac: false, price: true })} checked={systemType.price} />
                      }
                      label="PRICE"
                    />
                  </Spacing>
                </>
              )}
              <Typography align={'left'} sx={{ color: COLORS.GRAY, width: '100%', marginBottom: '8px' }}>
                Número de Parcelas
              </Typography>
              <Slider
                style={{ width: '95%' }}
                defaultValue={6}
                valueLabelDisplay="on"
                step={6}
                min={6}
                max={enterprise?.tax?.maxParcels}
                onChange={event => {
                  setForm(value => {
                    return { ...value, parcels: { value: event.target.value } };
                  });
                  isValidForm(form, setForm);
                }}
              />
              {!isEmptyObject(finalParcels) &&
                (minimumEntry === false || realToNumber(form?.firstPrice?.value) >= minimumEntry) && (
                  <Spacing width={'100%'} padding={'16px 0 0 0'}>
                    <Typography variant={'h5'}>Resumo da Proposta</Typography>
                    <Spacing fd={'row'} width={'100%'} padding={'16px 0 0 0'} jc={'space-around'}>
                      <Typography width={'50%'}>Quadra</Typography>
                      <Typography variant={'h6'} width={'50%'}>
                        {area?.square}
                      </Typography>
                    </Spacing>
                    <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                      <Typography width={'50%'}>Lote</Typography>
                      <Typography variant={'h6'} width={'50%'}>
                        {area?.number}
                      </Typography>
                    </Spacing>
                    <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                      <Typography width={'50%'}>Valor</Typography>
                      <Typography variant={'h6'} width={'50%'}>
                        R$ {area?.price}
                      </Typography>
                    </Spacing>
                    {form?.firstPrice?.value && (
                      <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                        <Typography width={'50%'}>Entrada</Typography>
                        <Typography variant={'h6'} width={'50%'}>
                          R$ {form?.firstPrice?.value}
                        </Typography>
                      </Spacing>
                    )}
                    <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                      <Typography width={'50%'}>Parcelas</Typography>
                      <Typography variant={'h6'} width={'50%'}>
                        {form?.parcels?.value}x
                      </Typography>
                    </Spacing>
                    <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                      <Typography width={'50%'}>Valor da Parcela</Typography>
                      <Typography color={COLORS.GREEN} variant={'h4'} width={'50%'}>
                        R${' '}
                        {formatReal(
                          parseInt(realToNumber(finalParcels[0]?.parcelValue))
                            .toString()
                            .replace(/\D+/g, '')
                        )}
                      </Typography>
                    </Spacing>
                    <Spacing fd={'row'} width={'100%'} jc={'start'}>
                      <Typography width={'50%'}></Typography>
                      <ButtonStyled onClick={() => setOpenParcels(true)}>Ver Parcelas</ButtonStyled>
                    </Spacing>
                  </Spacing>
                )}
            </Spacing>
          ) : (
            <>
              <InputStyled
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
                controller={{ form, setForm }}
                onValidation={value => realToNumber(value) >= realToNumber(area?.price)}
                messageValidation={'Valor mínimo de R$' + area?.price}
                name={'price'}
                label={'Valor'}
                type={'text'}
              />
              <InputStyled
                multiline
                controller={{ form, setForm }}
                name={'description'}
                label={'Nota de negociação'}
                type={'text'}
              />
            </>
          )}
        </>
      )}
      <Spacing width={'100%'} margin={'0 0 16px 0'} fd={'row'} jc={'flex-end'}>
        {!isProposal ? (
          <>
            <ButtonStyled
              disabled={isEmptyObject(area) || area?.status !== PLANT_STATUS.AVAILABLE || isLoading}
              margin={'0 0 0 auto'}
              bg={COLORS.YELLOW}
              colorText={COLORS.WHITE}
              onClick={() => setIsProposal(true)}
            >
              Fazer Proposta
            </ButtonStyled>
            <ButtonStyled
              disabled={isEmptyObject(area) || area?.status !== PLANT_STATUS.AVAILABLE || isLoading}
              margin={'0 0 0 16px'}
              bg={COLORS.GREEN_LIGHT}
              colorText={COLORS.WHITE}
              onClick={() => saveReservation()}
            >
              Reservar
            </ButtonStyled>
          </>
        ) : (
          <ButtonStyled
            disabled={isEmptyObject(area) || area?.status !== PLANT_STATUS.AVAILABLE || isLoading}
            margin={'0 0 0 16px'}
            bg={COLORS.GREEN_LIGHT}
            colorText={COLORS.WHITE}
            onClick={() => saveProposal()}
          >
            Enviar Proposta
          </ButtonStyled>
        )}
      </Spacing>
    </ModalStyled>
  );
};

export default ModalAreaClient;
