import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';

export const Container = styled.div`
  background-color: ${COLORS.WHITE};
  width: ${({ width }) => (width ? width : '100%')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 1s;
  position: absolute;
  top: 0;
  left: 0;
`;
