import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { database } from '../../services/firebase';
import { Text, Title, WrapperText } from './styled';
import { formatReal, isEmptyObject, realToNumber } from '../../utils/objectManipulation';
import { Spacing } from '../Spacing/Spacing';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { COLORS } from '../../constants/COLORS';
import AlertDialog from '../AlertDialog/AlertDialog';
import { changeEnterpriseStatus, saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { PLANT_STATUS } from '../../constants/PLANT_STATUS';
import { deleteReservation, saveReservation } from '../../services/reservation';
import { FormControlLabel, InputAdornment, Radio, Slider, Typography } from '@mui/material';
import InputStyled from '../InputStyled/InputStyled';
import { getDataForm, isValidForm } from '../../utils/formController';
import { createProposal } from '../../services/proposal';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import { useSnackbar } from 'notistack';
import { COLLECTION } from '../../constants/COLLECTION';
import { dateToInput, dateToString } from '../../utils/date';
import { RESERVATION_STATUS } from '../../constants/RESERVATION_STATUS';
import { currentUser, getAdminById, getUserById } from '../../services/user';
import { USER_PERMISSION } from '../../constants/USER_PERMISSION';
import { getDatabase, getDataDocs } from '../../services/firestore';
import { clearNotification, sendEmail, sendPushNotification } from '../../services/notification';
import { useNavigate } from 'react-router-dom';
import ModalParcels from '../ModalParcels/ModalParcels';
import { around, calcParcels } from '../../services/calc';

const ModalReservation = ({ setOpen, open, id, getReservations, isAdmin = false }) => {
  const [reservation, setReservation] = useState();
  const [enterprise, setEnterprise] = useState();
  const [isProposal, setIsProposal] = useState(false);
  const [openPlusTime, setOpenPlusTime] = useState(false);
  const [openDeleteReservation, setOpenDeleteReservation] = useState(false);
  const [openCloseDialogProposal, setOpenCloseDialogProposal] = useState(false);
  const [form, setForm] = useState();
  const [reservationUser, setReservationUser] = useState();
  const [formPlusTime, setFormPlusTime] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [finalParcels, setFinalParcels] = useState();
  const [openParcels, setOpenParcels] = useState(false);
  const [minimumEntry, setMinimumEntry] = useState();
  const [systemType, setSystemType] = useState();
  const [area, setArea] = useState();
  const navigate = useNavigate();

  const getReservation = async () => {
    if (!isEmptyObject(id)) {
      const data = await getDoc(doc(database, COLLECTION.RESERVATIONS, id));
      setReservation(data?.data());
      setFormPlusTime({ time: { value: dateToInput(data?.data()?.validDate) } });
    }
  };

  useEffect(() => {
    const find = enterprise?.forms?.find(are => are?.name === reservation?.square + reservation?.number);
    setArea(find);
  }, [enterprise]);

  const getReservationUser = async () => {
    if (!isEmptyObject(reservation)) {
      const data = getDataDocs(
        await getDocs(query(collection(getDatabase(), COLLECTION.USERS), where('id', '==', reservation?.user)))
      );
      setReservationUser(data[0]);
    }
  };

  const getEnterprise = async () => {
    const data = await getDoc(doc(database, COLLECTION.ENTERPRISES, reservation?.enterprise));
    setEnterprise(data?.data());
  };

  const removerReservation = async () => {
    setIsLoading(true);
    const newEnterprise = changeEnterpriseStatus(
      PLANT_STATUS.AVAILABLE,
      enterprise,
      reservation?.square + reservation?.number
    );
    await saveEnterprisesAdmin(newEnterprise);
    await deleteReservation(reservation);
    if (isAdmin) {
      sendPushNotification(
        'A reserva foi removida: ' +
          enterprise?.name +
          ' | lote ' +
          reservation?.number +
          ' quadra ' +
          reservation?.square,
        '',
        reservation?.user,
        window.location.origin + '/reservas'
      );
    } else {
      enterprise.user.map(userId =>
        sendPushNotification(
          'A reserva foi removida: ' +
            enterprise?.name +
            ' | lote ' +
            reservation?.number +
            ' quadra ' +
            reservation?.square,
          '',
          userId,
          window.location.origin + '/admin/reservas'
        )
      );
    }
    setOpenDeleteReservation(false);
    setOpen(false);
    getReservations();
    navigate(currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/reservas' : '../reservas');
    setIsLoading(false);
  };

  const sendProposal = async () => {
    setIsLoading(true);
    if (isValidForm(form, setForm)) {
      const newEnterprise = changeEnterpriseStatus(
        PLANT_STATUS.IN_PROPOSAL,
        enterprise,
        reservation?.square + reservation?.number
      );
      await saveEnterprisesAdmin(newEnterprise);
      await deleteReservation(reservation);
      const data = getDataForm(form);
      let newProposal = {
        clientName: reservation?.clientName,
        clientIdentification: reservation?.clientIdentification,
        clientPhone: reservation?.clientPhone,
        number: reservation?.number,
        square: reservation?.square,
        user: reservation?.user,
        userName: reservation?.userName,
        chat: [
          {
            message: 'R$' + data.price + ' - ' + data.description,
            sender: USER_PERMISSION.USER,
            date: new Date(),
            senderName: currentUser()?.name,
          },
        ],
        enterprise: enterprise?.id,
        enterpriseName: enterprise?.name,
      };
      if (!isEmptyObject(enterprise?.tax)) {
        newProposal = {
          clientName: reservation?.clientName,
          clientIdentification: reservation?.clientIdentification,
          clientPhone: reservation?.clientPhone,
          number: reservation?.number,
          square: reservation?.square,
          user: reservation?.user,
          userName: reservation?.userName,
          price: area?.price,
          firstPrice: data.firstPrice,
          parcels: data.parcels,
          tax: enterprise?.tax?.tax,
          systemType: systemType?.sac,
          chat: [
            {
              message: !isEmptyObject(enterprise?.tax) ? '<modalPrice>' : 'R$' + data.price + ' - ' + data.description,
              sender: USER_PERMISSION.USER,
              date: new Date(),
              senderName: currentUser()?.name,
            },
          ],
          enterprise: enterprise?.id,
          enterpriseName: enterprise?.name,
        };
      }
      const proId = (await createProposal(newProposal))[0]?.id;
      if (isAdmin) {
        const getUser = await getUserById(reservation?.user);
        sendEmail(
          getUser?.email,
          'Nova Proposta!',
          'Olá, ' + getUser?.name + '! Segue o link para acessar proposta.',
          window.location.origin + '/propostas/' + proId
        );
        if (!isEmptyObject(enterprise?.tax)) {
          sendPushNotification(
            'Nova Proposta: ' + enterprise?.name + ' | lote ' + reservation?.number + ' quadra ' + reservation?.square,
            'Ver Proposta',
            getUser?.id,
            window.location.origin + '/propostas/' + proId
          );
        } else {
          sendPushNotification(
            'Nova Proposta: ' + enterprise?.name + ' | lote ' + reservation?.number + ' quadra ' + reservation?.square,
            'R$' + data.price + ' - ' + data.description,
            getUser?.id,
            window.location.origin + '/propostas/' + proId
          );
        }
      } else {
        enterprise.user.map(async user => {
          const admin = await getAdminById(user);
          sendEmail(
            admin?.email,
            'Nova Proposta!',
            'Olá, ' + admin?.name + '! Segue o link para acessar proposta.',
            window.location.origin + '/admin/propostas/' + proId
          );
          if (!isEmptyObject(enterprise?.tax)) {
            sendPushNotification(
              'Nova Proposta: ' +
                enterprise?.name +
                ' | lote ' +
                reservation?.number +
                ' quadra ' +
                reservation?.square,
              'Ver Proposta',
              admin?.id,
              window.location.origin + '/admin/propostas/' + proId
            );
          } else {
            sendPushNotification(
              'Nova Proposta: ' +
                enterprise?.name +
                ' | lote ' +
                reservation?.number +
                ' quadra ' +
                reservation?.square,
              'R$' + data.price + ' - ' + data.description,
              admin?.id,
              window.location.origin + '/admin/propostas/' + proId
            );
          }
        });
      }
      getReservations();
      setOpenCloseDialogProposal(false);
      navigate(
        currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/propostas/' + proId : '../propostas/' + proId
      );
      enqueueSnackbar('Proposta criada com sucesso.', { variant: ALERT_TYPES.SUCCESS });
    } else {
      setOpenCloseDialogProposal(false);
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!enterprise?.tax?.systemType?.sac && enterprise?.tax?.systemType?.price) {
      setSystemType({ sac: false, price: true });
    } else {
      setSystemType({ sac: true, price: false });
    }
    if (enterprise?.tax?.minimumEntryType === 0) {
      setMinimumEntry(realToNumber(enterprise?.tax?.minimumEntry));
    } else if (enterprise?.tax?.minimumEntryType === 1) {
      const price = realToNumber(area?.price);
      const value = price * (enterprise?.tax?.minimumEntry / 100);
      setMinimumEntry(Number(around(value)));
    } else {
      setMinimumEntry(false);
    }
  }, [enterprise, area]);

  useEffect(() => {
    const price = realToNumber(area?.price);
    const parcels = form?.parcels?.value;
    const tax = enterprise?.tax?.tax;
    if (isEmptyObject(form?.firstPrice?.value)) {
      setFinalParcels(calcParcels(price, parcels, tax, systemType?.sac));
    } else {
      setFinalParcels(calcParcels(price - realToNumber(form?.firstPrice?.value), parcels, tax, systemType?.sac));
    }
  }, [form, systemType]);

  useEffect(() => {
    getEnterprise();
    getReservationUser();
    clearNotification();
  }, [reservation]);

  useEffect(() => {
    getReservation();
  }, [id]);

  return (
    <>
      <AlertDialog
        open={openDeleteReservation}
        title={'Ops!'}
        text={'Deseja realmente excluir a reserva?'}
        handleClose={() => setOpenDeleteReservation(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenDeleteReservation(false)}>Cancelar</ButtonStyled>
            <ButtonStyled disabled={isLoading} onClick={() => removerReservation()}>
              Excluir
            </ButtonStyled>
          </>
        }
      />
      <AlertDialog
        open={openCloseDialogProposal}
        title={'Ops!'}
        text={'Deseja realmente enviar a proposta?'}
        handleClose={() => setOpenCloseDialogProposal(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenCloseDialogProposal(false)}>Cancelar</ButtonStyled>
            <ButtonStyled disabled={isLoading} onClick={() => sendProposal()}>
              Enviar
            </ButtonStyled>
          </>
        }
      />
      <ModalStyled
        setOpen={setOpen}
        open={open}
        onClose={() => {
          setForm({});
          setIsProposal(false);
          getReservations();
          navigate(currentUser()?.permission === USER_PERMISSION.ADMIN ? '../admin/reservas' : '../reservas');
        }}
        title={
          isEmptyObject(reservation)
            ? 'Reserva não encontrada!'
            : 'Reserva: ' + reservation?.enterpriseName + ' | ' + reservation?.square + ' - ' + reservation?.number
        }
      >
        <ModalStyled setOpen={setOpenPlusTime} open={openPlusTime} title={'Aumentar Expiração'}>
          <InputStyled
            controller={{ form: formPlusTime, setForm: setFormPlusTime }}
            name={'time'}
            label={'Nova data de Expiração'}
            type={'datetime-local'}
            inputProps={{ min: dateToInput(reservation?.validDate), max: '3500-09-27 00:00' }}
          />
          <Spacing jc={'flex-end'} fd={'row'} margin={'16px 0 0 0'}>
            <ButtonStyled
              margin={'0 8px 0 0'}
              onClick={async () => {
                if (isValidForm(formPlusTime, setFormPlusTime)) {
                  const newReservation = {
                    ...reservation,
                    validDate: new Date(formPlusTime?.time.value),
                  };
                  await saveReservation(newReservation);
                  setOpenPlusTime(false);
                  await getReservation();
                  enqueueSnackbar('Alterado com sucesso', { variant: ALERT_TYPES.SUCCESS });
                } else {
                  enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
                }
              }}
              variant="contained"
              component="span"
              bg={COLORS.GREEN_LIGHT}
            >
              Aumentar Validade
            </ButtonStyled>
          </Spacing>
        </ModalStyled>
        {!isEmptyObject(reservation) && (
          <>
            <Title>Informações</Title>
            <WrapperText>
              <Text>Status: </Text>
              <Text>{reservation?.status === RESERVATION_STATUS.RESERVED ? 'Reservado' : 'Expirado'}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Data de expiração: </Text>
              <Text>{dateToString(reservation?.validDate)}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Data de criação: </Text>
              <Text>{dateToString(reservation?.createdAt)}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Empreendimento: </Text>
              <Text>{reservation?.enterpriseName}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Quadra: </Text>
              <Text>{reservation?.square}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Número: </Text>
              <Text>{reservation?.number}</Text>
            </WrapperText>
            <Title>Cliente</Title>
            <WrapperText>
              <Text>Nome: </Text>
              <Text>{reservation?.clientName}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Telefone: </Text>
              <Text>{reservation?.clientPhone}</Text>
            </WrapperText>
            <WrapperText>
              <Text>Identificação: </Text>
              <Text>{reservation?.clientIdentification}</Text>
            </WrapperText>
            {isAdmin && (
              <>
                <Title>Corretor</Title>
                <WrapperText>
                  <Text>Nome: </Text>
                  <Text>{reservation?.userName}</Text>
                </WrapperText>
                <WrapperText>
                  <Text>CRECI: </Text>
                  <Text>{reservationUser?.creci}</Text>
                </WrapperText>
                <WrapperText>
                  <Text>Telefone: </Text>
                  <Text>
                    {reservationUser?.phone
                      .toString()
                      .substring(3, reservationUser?.phone.length)
                      .replace(/\D/g, '')
                      .replace(/(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{5})(\d{4})/, '$1-$2')}
                  </Text>
                </WrapperText>
              </>
            )}
            {isProposal && (
              <>
                <Typography align={'left'} sx={{ width: '100%', margin: '16px 0 8px 0' }}>
                  Proposta
                </Typography>
                {!isEmptyObject(enterprise?.tax) ? (
                  <Spacing padding={'8px'}>
                    <ModalParcels
                      open={openParcels}
                      setOpen={setOpenParcels}
                      parcels={finalParcels}
                      enterprise={enterprise}
                    />
                    <InputStyled
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
                      controller={{ form, setForm }}
                      onValidation={value =>
                        (minimumEntry === false || realToNumber(value) >= minimumEntry) &&
                        realToNumber(value) < realToNumber(area?.price)
                      }
                      messageValidation={
                        minimumEntry === false
                          ? 'Valor máximo de R$' +
                            formatReal(parseInt((realToNumber(area?.price) - 0.01).toString().replace(/\D+/g, '')))
                          : 'Valor minimo de R$' +
                            formatReal(
                              parseInt(realToNumber(around(minimumEntry)))
                                .toString()
                                .replace(/\D+/g, '')
                            ) +
                            ' e máximo de R$' +
                            formatReal(parseInt((realToNumber(area?.price) - 0.01).toString().replace(/\D+/g, '')))
                      }
                      notRequired={enterprise?.tax?.minimumEntryType === 2}
                      name={'firstPrice'}
                      label={'Entrada'}
                      type={'text'}
                    />
                    {enterprise?.tax?.systemType?.sac && enterprise?.tax?.systemType?.price && (
                      <>
                        <Typography align={'left'} sx={{ color: COLORS.GRAY, width: '100%', marginBottom: '8px' }}>
                          Sistema
                        </Typography>
                        <Spacing fd={'row'} jc={'flex-start'} width={'100%'} padding={'0 0 16px 0'}>
                          <FormControlLabel
                            control={
                              <Radio
                                onClick={() => setSystemType({ sac: true, price: false })}
                                checked={systemType?.sac}
                                defaultChecked
                              />
                            }
                            label="SAC"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                onClick={() => setSystemType({ sac: false, price: true })}
                                checked={systemType.price}
                              />
                            }
                            label="PRICE"
                          />
                        </Spacing>
                      </>
                    )}
                    <Typography align={'left'} sx={{ color: COLORS.GRAY, width: '100%', marginBottom: '8px' }}>
                      Número de Parcelas
                    </Typography>
                    <Slider
                      style={{ width: '95%' }}
                      defaultValue={6}
                      valueLabelDisplay="on"
                      step={6}
                      min={6}
                      max={enterprise?.tax?.maxParcels}
                      onChange={event => {
                        setForm(value => {
                          return { ...value, parcels: { value: event.target.value } };
                        });
                        isValidForm(form, setForm);
                      }}
                    />
                    {!isEmptyObject(finalParcels) &&
                      (minimumEntry === false || realToNumber(form?.firstPrice?.value) >= minimumEntry) && (
                        <Spacing width={'100%'} padding={'16px 0 0 0'}>
                          <Typography variant={'h5'}>Resumo da Proposta</Typography>
                          <Spacing fd={'row'} width={'100%'} padding={'16px 0 0 0'} jc={'space-around'}>
                            <Typography width={'50%'}>Quadra</Typography>
                            <Typography variant={'h6'} width={'50%'}>
                              {area?.square}
                            </Typography>
                          </Spacing>
                          <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                            <Typography width={'50%'}>Lote</Typography>
                            <Typography variant={'h6'} width={'50%'}>
                              {area?.number}
                            </Typography>
                          </Spacing>
                          <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                            <Typography width={'50%'}>Valor</Typography>
                            <Typography variant={'h6'} width={'50%'}>
                              R$ {area?.price}
                            </Typography>
                          </Spacing>
                          {form?.firstPrice?.value && (
                            <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                              <Typography width={'50%'}>Entrada</Typography>
                              <Typography variant={'h6'} width={'50%'}>
                                R$ {form?.firstPrice?.value}
                              </Typography>
                            </Spacing>
                          )}
                          <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                            <Typography width={'50%'}>Parcelas</Typography>
                            <Typography variant={'h6'} width={'50%'}>
                              {form?.parcels?.value}x
                            </Typography>
                          </Spacing>
                          <Spacing fd={'row'} width={'100%'} jc={'space-around'}>
                            <Typography width={'50%'}>Valor da Parcela</Typography>
                            <Typography color={COLORS.GREEN} variant={'h4'} width={'50%'}>
                              R${' '}
                              {formatReal(
                                parseInt(realToNumber(finalParcels[0]?.parcelValue))
                                  .toString()
                                  .replace(/\D+/g, '')
                              )}
                            </Typography>
                          </Spacing>
                          <Spacing fd={'row'} width={'100%'} jc={'start'}>
                            <Typography width={'50%'}></Typography>
                            <ButtonStyled onClick={() => setOpenParcels(true)}>Ver Parcelas</ButtonStyled>
                          </Spacing>
                        </Spacing>
                      )}
                  </Spacing>
                ) : (
                  <>
                    <InputStyled
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                      mask={value => value && formatReal(parseInt(value.toString().replace(/\D+/g, '')))}
                      controller={{ form, setForm }}
                      name={'price'}
                      label={'Valor'}
                      type={'text'}
                    />
                    <InputStyled
                      multiline
                      controller={{ form, setForm }}
                      name={'description'}
                      label={'Nota de negociação'}
                      type={'text'}
                    />
                  </>
                )}
              </>
            )}
            <Spacing jc={'flex-end'} fd={'row'} margin={'16px 0 0 0'}>
              {reservation?.status !== RESERVATION_STATUS.EXPIRED ? (
                isProposal ? (
                  <ButtonStyled
                    margin={'0 8px 0 0'}
                    onClick={() => setOpenCloseDialogProposal(true)}
                    variant="contained"
                    component="span"
                    bg={COLORS.GREEN_LIGHT}
                  >
                    Enviar Proposta
                  </ButtonStyled>
                ) : (
                  <>
                    <ButtonStyled
                      margin={'0 8px 0 0'}
                      onClick={() => setIsProposal(true)}
                      variant="contained"
                      component="span"
                      bg={COLORS.BLUE}
                    >
                      Enviar Proposta
                    </ButtonStyled>
                    {isAdmin && (
                      <ButtonStyled
                        margin={'0 8px 0 0'}
                        onClick={() => setOpenPlusTime(true)}
                        variant="contained"
                        component="span"
                        bg={COLORS.GREEN_LIGHT}
                      >
                        Aumentar Validade
                      </ButtonStyled>
                    )}
                    <ButtonStyled
                      onClick={() => setOpenDeleteReservation(true)}
                      variant="contained"
                      component="span"
                      bg={COLORS.RED}
                    >
                      Excluir Reserva
                    </ButtonStyled>
                  </>
                )
              ) : (
                isAdmin && (
                  <ButtonStyled
                    onClick={() => setOpenDeleteReservation(true)}
                    variant="contained"
                    component="span"
                    bg={COLORS.RED}
                  >
                    Excluir Reserva
                  </ButtonStyled>
                )
              )}
            </Spacing>
          </>
        )}
      </ModalStyled>
    </>
  );
};

export default ModalReservation;
