import { isEmptyObject } from './objectManipulation';

const CryptoJS = require('crypto-js');

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, CryptoJS.AES.encrypt(JSON.stringify(value), 'controlotepisecretkey').toString());
};

export const getLocalStorage = key => {
  if (!isEmptyObject(localStorage.getItem(key))) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem(key), 'controlotepisecretkey');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
  }
  return undefined;
};
