import { currentUser } from './user';
import { createDocument, deleteDocument, updateDocument } from './firestore';
import { COLLECTION } from '../constants/COLLECTION';
import { dateInSeconds } from '../utils/date';
import { RESERVATION_STATUS } from '../constants/RESERVATION_STATUS';
import { changeEnterpriseStatus, saveEnterprisesAdmin } from './enterprisesAdmin';
import { PLANT_STATUS } from '../constants/PLANT_STATUS';
import { doc, getDoc } from 'firebase/firestore';
import { database } from './firebase';

export const createReservation = async data => {
  const reservation = {
    ...data,
    user: currentUser()?.id,
    userName: currentUser()?.name,
    createdAt: new Date(),
    validDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  return createDocument(COLLECTION.RESERVATIONS, reservation);
};

export const saveReservation = async data => {
  const reservation = {
    ...data,
    updatedAt: new Date(),
  };
  return updateDocument(COLLECTION.RESERVATIONS, reservation);
};

export const deleteReservation = async data => {
  return deleteDocument(COLLECTION.RESERVATIONS, data);
};

export async function checkReservationsExpired(dataDocs, getReservations = () => {}) {
  let getAgain = false;
  for (const data of dataDocs) {
    if (data?.validDate.seconds <= dateInSeconds() && data?.status !== RESERVATION_STATUS.EXPIRED) {
      data.status = RESERVATION_STATUS.EXPIRED;
      saveReservation(data);
      const enterprise = (await getDoc(doc(database, COLLECTION.ENTERPRISES, data.enterprise))).data();
      saveEnterprisesAdmin(
        changeEnterpriseStatus(PLANT_STATUS.AVAILABLE, enterprise, String(data.square) + data.number)
      );
      getAgain = true;
    }
  }
  if (getAgain) {
    getReservations();
  }
}
