import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';
import { isMobile, MediaQuery } from '../../services/screen';
import LoadingButton from '@mui/lab/LoadingButton';

export const ButtonStyled = styled(LoadingButton)`
  background-color: ${({ bg, disabled }) =>
    bg && (!disabled ? `${bg} !important` : `${COLORS.GRAY_BLACK_TRANSPARENT} !important`)};
  color: ${({ colorText, disabled }) =>
    colorText && (!disabled ? `${colorText} !important` : `${COLORS.GRAY} !important`)};
  width: ${({ width }) => width && `${width}`};
  ${({ height }) => (height ? `height: ${height};` : '')};
  ${({ border }) => (border ? `border: ${border} !important;` : '')};
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}`};
  margin: ${({ margin }) => margin && `${margin} !important`};
  font-family: Montserrat, sans-serif !important;

  ${MediaQuery(isMobile)} {
    ${({ noMobile }) => noMobile && 'display: none !important;'}
  }
`;
