export const translateError = errorCode => {
  if (errorMap[errorCode]) {
    return errorMap[errorCode];
  }
  console.error(errorCode);
  return errorCode;
};

const errorMap = {
  'auth/invalid-email': 'E-mail inválido',
  'auth/weak-password': 'Senha inválida: mínimo 6 caracteres',
  'auth/email-already-in-use': 'Esse e-mail já está em uso',
  'auth/popup-closed-by-user': 'Operação cancelada pelo usuário',
  'auth/wrong-password': 'Senha incorreta.',
  'auth/user-not-found': 'Usuário não encontrado',
};
