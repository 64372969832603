import { currentUser } from './user';
import { createDocument, deleteDocument, updateDocument } from './firestore';
import { COLLECTION } from '../constants/COLLECTION';

export const createProposal = async data => {
  const proposal = {
    user: currentUser()?.id,
    userName: currentUser()?.name,
    ...data,
    sold: false,
    createdAt: new Date(),
  };
  return createDocument(COLLECTION.PROPOSALS, proposal);
};

export const saveProposal = async data => {
  const proposal = {
    ...data,
    updatedAt: new Date(),
  };
  return updateDocument(COLLECTION.PROPOSALS, proposal);
};

export const deleteProposal = async data => {
  return deleteDocument(COLLECTION.PROPOSALS, data);
};
