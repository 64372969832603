import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, ptBR } from '@mui/x-data-grid';
import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { isDesktop } from '../../services/screen';

const Grid = styled(DataGrid)`
  border: none !important;

  .MuiDataGrid-toolbarContainer {
    justify-content: space-between;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
  }

  .MuiDataGrid-panel {
    right: 0;
  }

  .MuiDataGrid-cell {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader {
    outline: none !important;
  }
`;

const Div = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.GRAY_BLACK,
      contrastText: COLORS.WHITE,
    },
  },
});

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
    <GridToolbarExport />
  </GridToolbarContainer>
);

const TableData = ({ rows, columns, showMenu, loading = true, rowClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <Div>
        {!loading && (
          <Grid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            disableColumnMenu
            onRowClick={(params, event) => {
              if (event.target.toString() === '[object HTMLDivElement]' && !isDesktop) {
                rowClick && rowClick(params);
              }
            }}
            onRowDoubleClick={(params, event) => {
              if (event.target.toString() === '[object HTMLDivElement]' && isDesktop) {
                rowClick && rowClick(params);
              }
            }}
            components={showMenu && { Toolbar: CustomToolbar }}
          />
        )}
        {loading && <CircularProgress />}
      </Div>
    </ThemeProvider>
  );
};

export default TableData;
