import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import { Spacing } from '../Spacing/Spacing';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { COLLECTION } from '../../constants/COLLECTION';
import { database } from '../../services/firebase';
import { isEmptyObject } from '../../utils/objectManipulation';
import { getDataDocs } from '../../services/firestore';
import InputStyled from '../InputStyled/InputStyled';
import { ButtonStyled } from '../ButtonStyled/ButtonStyled';
import { COLORS } from '../../constants/COLORS';
import { currentUser } from '../../services/user';
import { isValidForm } from '../../utils/formController';
import { useSnackbar } from 'notistack';
import { ALERT_TYPES } from '../../constants/ALERT_TYPES';
import { saveEnterprisesAdmin } from '../../services/enterprisesAdmin';
import { translateError } from '../../utils/error';
import { USER_PERMISSION } from '../../constants/USER_PERMISSION';

const ModalOwners = ({ open, setOpen, enterprise }) => {
  const [users, setUsers] = useState();
  const [form, setForm] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const getUsers = async () => {
    if (!isEmptyObject(enterprise)) {
      let dataDocs = getDataDocs(
        await getDocs(query(collection(database, COLLECTION.USERS), where('id', 'in', enterprise.user)))
      );
      dataDocs = dataDocs.filter(data => data.email !== currentUser().email);
      setUsers(dataDocs);
    }
  };

  async function addAdminExec(dataDocs) {
    if (dataDocs.length > 0) {
      if (!enterprise.user.find(user => user === dataDocs[0]?.id)) {
        if (dataDocs[0]?.permission === USER_PERMISSION.ADMIN) {
          enterprise.user = [...enterprise.user, dataDocs[0].id];
          try {
            await saveEnterprisesAdmin(enterprise);
            await getUsers();
            enqueueSnackbar('Adicionado com sucesso!', { variant: ALERT_TYPES.SUCCESS });
          } catch (error) {
            enqueueSnackbar(translateError(error?.code), { variant: ALERT_TYPES.ERROR });
          }
        } else {
          enqueueSnackbar('Apenas usuários da área de administração!', { variant: ALERT_TYPES.ERROR });
        }
      } else {
        enqueueSnackbar('Este administrador já está registrado!', { variant: ALERT_TYPES.ERROR });
      }
    } else {
      enqueueSnackbar('Nenhum administrador registrado com este e-mail!', { variant: ALERT_TYPES.ERROR });
    }
  }

  const addAdmin = async () => {
    if (isValidForm(form, setForm)) {
      const dataDocs = getDataDocs(
        await getDocs(query(collection(database, COLLECTION.USERS), where('email', '==', form?.email?.value)))
      );
      await addAdminExec(dataDocs);
    }
  };

  const removeAdmin = async id => {
    enterprise.user = enterprise.user.filter(user => user !== id);
    try {
      await saveEnterprisesAdmin(enterprise);
      await getUsers();
      enqueueSnackbar('Removido com sucesso!', { variant: ALERT_TYPES.SUCCESS });
    } catch (error) {
      enqueueSnackbar(translateError(error?.code), { variant: ALERT_TYPES.ERROR });
    }
  };

  useEffect(() => {
    getUsers();
  }, [enterprise]);

  return (
    <ModalStyled open={open} setOpen={setOpen} title={'Acessos:'}>
      <Spacing fd={'row'} jc={'space-between'} ai={'flex-start'} margin={'0 0 16px 0'}>
        <InputStyled
          onValidation={value =>
            String(value)
              .toLowerCase()
              .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
          }
          messageValidation={'E-mail inválido.'}
          controller={{ form, setForm }}
          name={'email'}
          label={'E-mail'}
          type={'email'}
          maxWidth={'65%'}
          paddingBottom={'0'}
        />
        <ButtonStyled onClick={() => addAdmin()} margin={'0 0 0 8px'} bg={COLORS.GREEN} colorText={COLORS.WHITE}>
          Adicionar Administrador
        </ButtonStyled>
      </Spacing>
      {!isEmptyObject(users) && (
        <>
          Administradores{' '}
          {users?.map(user => (
            <>
              <Spacing
                margin={'8px 0'}
                width={'100%'}
                sx={`border-bottom: 1px solid ${COLORS.GRAY_BLACK_TRANSPARENT};`}
              />
              <Spacing jc={'space-between'} fd={'row'}>
                {user.email}
                <ButtonStyled onClick={() => removeAdmin(user.id)} margin={'0 0 0 8px'} colorText={COLORS.RED}>
                  Remover
                </ButtonStyled>
              </Spacing>
            </>
          ))}
        </>
      )}
    </ModalStyled>
  );
};

export default ModalOwners;
