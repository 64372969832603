import React, { useEffect, useState } from 'react';
import Logo from '../../common/svgs/Logo';
import { isDesktop } from '../../common/services/screen';
import { COLORS } from '../../common/constants/COLORS';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import InviteBackground from '../../static/img/invite-background.png';
import { Background, Container, ContainerCenter, ContainerStart, SubTitle, Title } from './styled';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { doc, getDoc } from 'firebase/firestore';
import { getDatabase } from '../../common/services/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { acceptedInviteAdmin, recusedInviteAdmin } from '../../common/services/invitesAdmin';
import { INVITE_STATUS } from '../../common/constants/INVITE_STATUS';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import ModalStyled from '../../common/components/ModalStyled/ModalStyled';
import { clearForm, getDataForm, isValidForm } from '../../common/utils/formController';
import { createUserWithEmail, loginWithEmail, resetPassword } from '../../common/services/auth';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { useSnackbar } from 'notistack';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import { translateError } from '../../common/utils/error';
import { setLocalStorage } from '../../common/utils/storage';
import { COLLECTION } from '../../common/constants/COLLECTION';

const Invite = () => {
  document.title = 'Controlote - Seu convite para Controlote';
  const [invite, setInvite] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState();
  const [loginForm, setLoginForm] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getInvite = async () => {
    const data = await getDoc(doc(getDatabase(), COLLECTION.INVITES, id));
    setInvite(data.data());
    setForm({
      email: { value: data.data().email },
      name: { value: data.data().name },
      phone: {
        value: data
          .data()
          .phone.toString()
          .substring(3, data.data().phone.length),
      },
    });
    setLoginForm({
      email: { value: data.data().email },
    });
  };

  const checkRecusedInvite = async () => {
    if (invite?.validDate.seconds <= new Date().getTime() / 1000 && invite?.status !== INVITE_STATUS.ACCEPTED) {
      await recusedInviteAdmin(invite);
      getInvite();
    }
  };

  useEffect(() => {
    getInvite();
  }, []);

  useEffect(() => {
    invite && checkRecusedInvite();
  }, [invite]);

  function handlePhone(data) {
    if (data.phone) {
      data.phone =
        '+55' +
        data.phone
          .replaceAll('(', '')
          .replaceAll(') ', '')
          .replaceAll('-', '');
    }
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (isValidForm(form, setForm)) {
      try {
        const dataForm = getDataForm(form);
        handlePhone(dataForm);
        const user = await createUserWithEmail(dataForm, invite?.type);
        setLocalStorage('user', user);
        await successSignUp({ ...invite, userInvited: user?.id });
      } catch (error) {
        enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
      }
      setForm(clearForm(form));
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setIsLoading(false);
  };

  const successSignUp = async tempInvite => {
    await acceptedInviteAdmin(tempInvite);
    navigate(tempInvite?.type === USER_PERMISSION.ADMIN ? '../admin/home' : '../home');
    enqueueSnackbar('Cadastro realizado com sucesso!', { variant: ALERT_TYPES.SUCCESS });
  };

  const signInWithEmail = async () => {
    setIsLoading(true);
    if (isValidForm(loginForm, setLoginForm)) {
      try {
        const data = getDataForm(loginForm);
        const user = await loginWithEmail(data.email, data.password);
        setLocalStorage('user', user);
        await successSignUp({ ...invite, userInvited: user?.id });
      } catch (error) {
        enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
      }
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
    setIsLoading(false);
  };

  const forgetPassword = () => {
    if (loginForm?.email.value.length > 0 && loginForm?.email.onValidation(loginForm?.email.value)) {
      resetPassword(loginForm?.email.value)
        .then(() => {
          enqueueSnackbar('E-mail de recuperação enviado!', { variant: ALERT_TYPES.SUCCESS });
        })
        .catch(error => {
          enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
        });
      return;
    }
    enqueueSnackbar('Email inválido.', { variant: ALERT_TYPES.WARNING });
  };

  return (
    <Container>
      <ContainerStart>
        <Background src={InviteBackground} />
      </ContainerStart>
      <ContainerCenter>
        <Spacing margin={'24px 0 24px 0'}>
          <Logo width={isDesktop ? '200px' : '150px'} height={isDesktop ? '200px' : '150px'} />
        </Spacing>
        <Spacing margin={'0 0 65px 0'}>
          <SubTitle>{invite && invite?.name} </SubTitle>
          <Title>Bem vindo à Controlote!</Title>
          {invite?.type === USER_PERMISSION.ADMIN ? (
            <SubTitle>
              Você foi convidado para ter acesso à área administrativa onde poderá fazer o gerenciamento de seus
              empreendimentos.
            </SubTitle>
          ) : (
            <SubTitle>
              Você foi convidado ao controlote onde terá acesso aos melhores empreendimentos em tempo real.
            </SubTitle>
          )}
          <ButtonStyled
            width={'100%'}
            maxWidth={'300px'}
            colorText={COLORS.WHITE}
            bg={COLORS.GREEN_LIGHT}
            disabled={invite?.status !== INVITE_STATUS.SENDING}
            onClick={() => setOpenModal(true)}
          >
            {invite && invite?.status === INVITE_STATUS.SENDING && 'Aceitar convite'}
            {invite && invite?.status === INVITE_STATUS.EXPIRED && 'Este convite expirou'}
            {!invite && 'Carregando...'}
            {invite && invite?.status === INVITE_STATUS.ACCEPTED && 'Esse convite já foi aceito'}
          </ButtonStyled>
        </Spacing>
        <Spacing>
          <SubTitle>Desde já agradecemos sua preferência.</SubTitle>
        </Spacing>
      </ContainerCenter>
      <ContainerStart>
        <Background inverted src={InviteBackground} />
      </ContainerStart>
      <ModalStyled
        title={hasAccount ? 'Entrar' : 'Cadastro'}
        open={openModal}
        onClose={() => setHasAccount(false)}
        setOpen={setOpenModal}
        width={hasAccount && isDesktop ? '50%' : '80%'}
      >
        <Spacing display={!hasAccount && 'none'}>
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
            }
            messageValidation={'E-mail inválido.'}
            controller={{ form: loginForm, setForm: setLoginForm }}
            name={'email'}
            label={'E-mail'}
            type={'email'}
          />
          <InputStyled
            onValidation={value => value.length >= 6}
            messageValidation={'Senha deve ter 6 ou mais caracteres.'}
            controller={{ form: loginForm, setForm: setLoginForm }}
            name={'password'}
            label={'Senha'}
            type={'password'}
          />
          <ButtonStyled
            disabled={isLoading}
            onClick={() => signInWithEmail()}
            size="large"
            width={'100%'}
            margin={'0 0 16px 0'}
            bg={COLORS.GRAY_BLACK}
            variant="contained"
          >
            Entrar
          </ButtonStyled>
          <ButtonStyled onClick={() => forgetPassword()} colorText={COLORS.GRAY} bg={COLORS.WHITE}>
            Esqueceu a senha?
          </ButtonStyled>
        </Spacing>
        <Spacing ai={'end'} display={hasAccount && 'none'}>
          {invite?.type === USER_PERMISSION.USER && (
            <InputStyled controller={{ form, setForm }} name={'creci'} label={'CRECI'} type={'text'} />
          )}
          <InputStyled controller={{ form, setForm }} name={'name'} label={'Nome'} type={'text'} />
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/\((\d{2})\)\s(\d{5})-(\d{4})/g) !== null && value.length === 15
            }
            messageValidation={'Celular inválido. Ex: (48) 99988-9988'}
            notRequired
            mask={value => {
              return (
                value &&
                value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '($1) $2')
                  .replace(/(\d{5})(\d{4})/, '$1-$2')
              );
            }}
            maxLength={15}
            controller={{ form, setForm }}
            name={'phone'}
            label={'Celular'}
            type={'text'}
          />
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
            }
            messageValidation={'E-mail inválido.'}
            controller={{ form, setForm }}
            name={'email'}
            label={'E-mail'}
            type={'email'}
          />
          <InputStyled
            onValidation={value => value.length >= 6}
            messageValidation={'Senha deve ter 6 ou mais caracteres.'}
            controller={{ form, setForm }}
            name={'password'}
            label={'Senha'}
            type={'password'}
          />
          <Spacing fd={'row'}>
            <ButtonStyled margin={'0 8px 0 0'} onClick={() => setHasAccount(true)} bg={COLORS.BLUE} variant="contained">
              Já tenho conta
            </ButtonStyled>
            <ButtonStyled onClick={() => onSubmit()} bg={COLORS.GREEN_LIGHT} variant="contained">
              Cadastrar
            </ButtonStyled>
          </Spacing>
        </Spacing>
      </ModalStyled>
    </Container>
  );
};

export default Invite;
