import { createDocument, updateDocument } from './firestore';
import { database, vapidKey } from './firebase';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { COLLECTION } from '../constants/COLLECTION';
import { setLocalStorage } from '../utils/storage';
import { isEmptyObject } from '../utils/objectManipulation';
import { doc, getDoc } from 'firebase/firestore';
import { ALERT_TYPES } from '../constants/ALERT_TYPES';
import { COLORS } from '../constants/COLORS';
import { currentUser } from './user';

const messaging = getMessaging();

async function requestPermission() {
  try {
    await Notification.requestPermission();
  } catch (error) {
    console.error(error);
  }
}

function getNewFcmToken(data, fcmToken) {
  if (!isEmptyObject(data?.fcmToken)) {
    let hasToken = false;
    for (const index in data?.fcmToken) {
      if (data?.fcmToken[index].token.toString() === fcmToken.toString()) {
        hasToken = true;
      }
    }
    if (!hasToken) {
      return [...data?.fcmToken, { platform: 'web', token: fcmToken.toString() }];
    }
    return data?.fcmToken;
  }
  return [{ platform: 'web', token: fcmToken.toString() }];
}

export const configCloudMessaging = async user => {
  await requestPermission();

  getToken(messaging, { vapidKey: vapidKey })
    .then(async currentToken => {
      if (currentToken) {
        const newUser = { ...user, fcmToken: getNewFcmToken(user, currentToken) };
        setLocalStorage('user', newUser);
        await updateDocument(COLLECTION.USERS, newUser);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (enqueueSnackbar, closeSnackbar, navigate) =>
  new Promise(() => {
    onMessage(messaging, payload => {
      let url = payload.data.url;
      const pathname = window.location.pathname;
      url = url.split(window.location.origin + '/');
      url = '../' + url[1];
      if (url !== '..' + pathname) {
        enqueueSnackbar(payload.data.title, {
          variant: ALERT_TYPES.INFO,
          onClick: () => {
            closeSnackbar();
            navigate(url);
          },
        });
      }
    });
  });

export const sendPushNotification = async (title, message, id, url) => {
  const user = (await getDoc(doc(database, COLLECTION.USERS, id))).data();
  let newFcmToken = [];
  for (const index in user?.fcmToken) {
    const fcm = user?.fcmToken[index];
    let data = {
      to: fcm?.token,
      data: {
        title: title,
        body: message,
        url: url,
        icon: '/logo192.png',
      },
      notification: {
        title: title,
        body: message,
      },
    };
    if (fcm?.platform === 'web') {
      data = {
        to: fcm?.token,
        data: {
          title: title,
          body: message,
          url: url,
          icon: '/logo192.png',
        },
      };
    }
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Key=AAAAQJEov-c:APA91bFAFQXwrzCtL516EBsLIE6hoOm_dlD3exODr0Jc_RjeCj9A3hwy760gNnbVq6xwFnoI6UePBnH8VVqyobgaAdoRzNuAumzSGwcKJF_BkxdzhWdq29jG2VYcnf0GXwBXhIn0DoIV',
      },
      body: JSON.stringify(data),
    });
    if ((await response.json()).success === 1) {
      newFcmToken = [...newFcmToken, fcm];
    }
  }
  let newUser;
  if (isEmptyObject(user?.notification)) {
    newUser = {
      ...user,
      fcmToken: newFcmToken,
      notification: [
        {
          title: title,
          body: message,
          url: url,
        },
      ],
    };
  } else if (
    !user?.notification.find(noti => {
      let validUrl = url;
      validUrl = validUrl.split(window.location.origin + '/');
      validUrl = '../' + validUrl[1];
      let validUrlNoti = noti?.url;
      validUrlNoti = validUrlNoti.split(window.location.origin + '/');
      validUrlNoti = '../' + validUrlNoti[1];
      return validUrl === validUrlNoti;
    })
  ) {
    newUser = {
      ...user,
      fcmToken: newFcmToken,
      notification: [
        ...user?.notification,
        {
          title: title,
          body: message,
          url: url,
        },
      ],
    };
  }
  await updateDocument(COLLECTION.USERS, newUser);
};

export const clearNotification = async () => {
  const user = (await getDoc(doc(database, COLLECTION.USERS, currentUser()?.id))).data();
  if (!isEmptyObject(user?.notification)) {
    const newNotification = user?.notification.filter(notification => {
      const pathname = window.location.pathname;
      let url = notification?.url;
      url = url.split(window.location.origin + '/');
      url = '../' + url[1];
      return url !== '..' + pathname;
    });
    const newUser = {
      ...user,
      notification: [...newNotification],
    };
    await updateDocument(COLLECTION.USERS, newUser);
    setLocalStorage('user', newUser);
  }
};

export const clearAllNotification = async () => {
  const user = (await getDoc(doc(database, COLLECTION.USERS, currentUser()?.id))).data();
  if (!isEmptyObject(user?.notification)) {
    const newUser = {
      ...user,
      notification: [],
    };
    await updateDocument(COLLECTION.USERS, newUser);
    setLocalStorage('user', newUser);
  }
};

export const sendEmail = async (email, subject, text, link) => {
  await createDocument('mail', {
    to: email,
    message: {
      subject: subject,
      html: defaultEmail(text, link),
    },
  });
};

const defaultEmail = (text, link) => {
  return (
    '<div style="width: 600px;">' +
    '<div style="width: 600px;padding: 40px 200px 0 200px">' +
    '<img alt="Logo Controlote" style="width: 200px;" src="https://controlote.com/static/media/logo.5e0082025fd7f4ab3e6d.png"/>' +
    '</div>' +
    '<div style="padding: 16px 40px; text-align: center">' +
    text +
    '</div>' +
    '<div style="padding: 0 40px; text-align: center">' +
    '<a style="text-decoration: none;color: ' +
    COLORS.BLUE +
    ';" href="' +
    link +
    '">' +
    link +
    '</a>' +
    '</div>' +
    '<div style="padding: 16px 40px 40px 40px; text-align: center">' +
    'Atenciosamente, Controlote.' +
    '</div>' +
    '</div>'
  );
};
