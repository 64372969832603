import React from 'react';
import { createTheme, Tabs, ThemeProvider } from '@mui/material';
import { COLORS } from '../../constants/COLORS';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.GRAY_BLACK,
      contrastText: COLORS.WHITE,
    },
  },
});

const TabsStyled = ({ value, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Tabs value={value}>{children}</Tabs>
    </ThemeProvider>
  );
};

export default TabsStyled;
