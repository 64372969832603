import BusinessIcon from '@mui/icons-material/Business';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';
import { Home } from '@mui/icons-material';

export const MENU_ADMIN = [
  [
    { title: 'Home', icon: <Home />, to: '/admin/home' },
    { title: 'Empreendimentos', icon: <BusinessIcon />, to: '/admin/empreendimentos' },
    { title: 'Reservas', icon: <BeenhereOutlinedIcon />, to: '/admin/reservas' },
    { title: 'Propostas', icon: <CurrencyExchangeIcon />, to: '/admin/propostas' },
  ],
  [{ title: 'Convites', icon: <SendIcon />, to: '/admin/convites' }],
];
