import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';

export const Description = styled.h4`
  color: ${COLORS.GRAY_BLACK};
  width: calc(100% - 26px);
  margin: 16px 0 6px 0;
  font-weight: 400;
`;

export const Price = styled.h4`
  color: ${COLORS.GREEN_LIGHT};
  width: calc(100% - 26px);
  margin: 6px 0 6px 0;
  font-weight: 400;
`;

export const Status = styled.h4`
  color: ${({ color }) => color};
  width: calc(100% - 26px);
  margin: 0 0 16px 0;
  font-weight: 600;
`;
