export const COLORS = {
  WHITE: '#ffffff',
  WHITE_LIGHT: '#d4d4d4',
  BLACK_TRANSPARENT: 'rgba(0,0,0,0.6)',
  GREEN: '#23a638',
  GREEN_LIGHT: '#259456',
  GREEN_LIGHT_TRANSPARENT: 'rgba(0,196,104,0.40)',
  GRAY_BLACK: '#2A2829',
  GRAY: '#7a7a7a',
  RED: '#d32f2f',
  RED_BLACK: '#a93d3d',
  RED_BLACK_TRANSPARENT: 'rgba(169,61,61,0.40)',
  YELLOW_BLACK_TRANSPARENT: 'rgba(248,226,94,0.4)',
  YELLOW: 'rgb(218,192,32)',
  NOTHING: 'inherit',
  TRANSPARENT: 'rgba(0,0,0,0)',
  BLUE: '#099fb7',
  BLUE_BLACK: '#005a9a',
  GRAY_BLACK_TRANSPARENT: 'rgba(42,40,41,0.18)',
  GRAY_TRANSPARENT: 'rgba(122,122,122,0.10)',
  BLUE_TRANSPARENT: 'rgba(9,159,183,0.6)',
  GREEN_TRANSPARENT: 'rgba(0,196,104,0.6)',
  YELLOW_TRANSPARENT: 'rgba(218,192,32,0.6)',
  ORANGE_TRANSPARENT: 'rgba(224,102,26,0.6)',
};

export const COLORS_GRADIENT = [
  '#cef2d2',
  '#7de299',
  '#57c396',
  '#389c9c',
  '#357d7d',
  '#389c9c',
  '#57c396',
  '#7de299',
  '#cef2d2',
  '#7de299',
  '#57c396',
  '#389c9c',
  '#357d7d',
  '#389c9c',
  '#57c396',
  '#7de299',
  '#cef2d2',
  '#7de299',
  '#57c396',
  '#389c9c',
  '#357d7d',
  '#389c9c',
  '#57c396',
  '#7de299',
  '#cef2d2',
  '#7de299',
  '#57c396',
  '#389c9c',
  '#357d7d',
  '#389c9c',
  '#57c396',
  '#7de299',
  '#cef2d2',
];
