import React, { useEffect, useState } from 'react';
import { Spacing } from '../../common/components/Spacing/Spacing';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import ModalStyled from '../../common/components/ModalStyled/ModalStyled';
import { clearForm, getDataForm, isValidForm } from '../../common/utils/formController';
import { deleteInviteAdmin, saveInviteAdmin } from '../../common/services/invitesAdmin';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import { doc, getDoc } from 'firebase/firestore';
import { getDatabase } from '../../common/services/firestore';
import { translateError } from '../../common/utils/error';
import { useSnackbar } from 'notistack';
import { INVITE_STATUS } from '../../common/constants/INVITE_STATUS';
import { auth } from '../../common/services/auth';
import SelectStyled from '../../common/components/SelectStyled/SelectStyled';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { sendEmail } from '../../common/services/notification';

const ModifyInviteModal = ({ openModal, setOpenModal, id, getInvites }) => {
  const [form, setForm] = useState();
  const [invite, setInvite] = useState();
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const getInvite = async () => {
    setLoading(true);
    const data = await getDoc(doc(getDatabase(), COLLECTION.INVITES, id));
    setInvite(data.data());
    setForm({
      email: { value: data.data().email },
      name: { value: data.data().name },
      phone: {
        value: data
          .data()
          .phone.toString()
          .substring(3, data.data().phone.length),
      },
      type: { value: data.data().type },
    });
    setLoading(false);
  };

  useEffect(() => {
    id && getInvite();
  }, [id, openModal]);

  function handlePhone(data) {
    if (data.phone) {
      data.phone =
        '+55' +
        data.phone
          .replaceAll('(', '')
          .replaceAll(') ', '')
          .replaceAll('-', '');
    }
  }

  const onSubmit = async () => {
    if (isValidForm(form, setForm)) {
      setOpenModal(false);
      const data = getDataForm(form);
      handlePhone(data);
      const newInvite = { ...invite, ...data, status: INVITE_STATUS.SENDING };
      const inv = await saveInviteAdmin(newInvite);
      await getInvites();
      setForm(clearForm(form));
      setForm({ type: { value: USER_PERMISSION.USER } });
      await sendEmail(
        data?.email,
        'Olá, ' + data?.name + '! Chegou o seu Convite!',
        'Olá, ' + data?.name + '! Segue o link para acessar seu convite.',
        window.location.origin + '/convite/' + inv?.id
      );
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
  };

  const deleteInvite = async () => {
    try {
      await deleteInviteAdmin(invite);
      enqueueSnackbar('Dados deletados com sucesso.', { variant: ALERT_TYPES.SUCCESS });
      await getInvites();
      setOpenModal(false);
      setForm(clearForm(form));
    } catch (error) {
      enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.WARNING });
    }
  };

  return (
    <ModalStyled
      onClose={() => {
        setForm(clearForm(form));
        setInvite();
      }}
      title={invite && !loading ? 'Convite para: ' + invite?.name : 'Carregando...'}
      open={openModal}
      setOpen={setOpenModal}
    >
      {!loading && invite && (
        <Spacing ai={'end'}>
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
            }
            messageValidation={'E-mail inválido.'}
            controller={{ form, setForm }}
            name={'email'}
            label={'E-mail'}
            type={'email'}
          />
          <InputStyled controller={{ form, setForm }} name={'name'} label={'Nome'} type={'text'} />
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/\((\d{2})\)\s(\d{5})-(\d{4})/g) !== null && value.length === 15
            }
            messageValidation={'Celular inválido. Ex: (48) 99988-9988'}
            notRequired
            mask={value => {
              return (
                value &&
                value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '($1) $2')
                  .replace(/(\d{5})(\d{4})/, '$1-$2')
              );
            }}
            maxLength={15}
            controller={{ form, setForm }}
            name={'phone'}
            label={'Celular'}
            type={'text'}
          />
          {(auth?.currentUser?.uid === 'wdPKR5QaWyMwSR4TP0yfaDLmsk52' ||
            auth?.currentUser?.uid === 'zL5OQU8GVbWN0w4aZQDBWbjaxlh2') && (
            <SelectStyled
              controller={{ form, setForm }}
              label={'Tipo'}
              name={'type'}
              options={[
                { value: USER_PERMISSION.ADMIN, label: 'Novo Usuário Administrativo' },
                { value: USER_PERMISSION.USER, label: 'Novo Usuário' },
              ]}
            />
          )}
          <Spacing fd={'row'}>
            <ButtonStyled margin={'0 8px 0 0'} onClick={() => deleteInvite()} bg={COLORS.RED_BLACK} variant="contained">
              Excluir
            </ButtonStyled>
            <ButtonStyled onClick={() => onSubmit()} bg={COLORS.GREEN_LIGHT} variant="contained">
              Salvar / Reenviar Convite
            </ButtonStyled>
          </Spacing>
        </Spacing>
      )}
    </ModalStyled>
  );
};

export default ModifyInviteModal;
