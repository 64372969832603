import React from 'react';
import { IconButton, Modal } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';
import { Close } from '@mui/icons-material';
import { isMobile, MediaQuery, percentInPixelHeight } from '../../services/screen';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 80%;
  min-width: 320px;
  width: ${({ width }) => (width ? width : '80%')};
  transform: translate(-50%, -50%);
  padding: 16px;
  background: ${COLORS.WHITE};
  border-radius: 8px;

  ${MediaQuery(isMobile)} {
    max-width: calc(100% - 32px);
    min-width: calc(100% - 32px);
    width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    height: calc(100% - 32px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

const Title = styled.h2`
  color: ${COLORS.GRAY_BLACK};
  width: calc(100% - 26px);
  margin: 0;
  font-weight: 500;
`;

const Bar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ModalTheme = styled(Modal)`
  .MuiBackdrop-root {
    ${MediaQuery(isMobile)} {
      background-color: ${COLORS.WHITE};
    }
  }
`;

const ChildrenContainer = styled.div`
  width: 100%;
  ${({ height }) => height && 'height: ' + height + ';'}
  overflow: auto;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 2px;

  ${MediaQuery(!isMobile)} {
    max-height: calc(${`${percentInPixelHeight(80)}px`} - 40px - 16px - 16px);
  }
`;

const ModalStyled = ({ children, open, setOpen, title, onClose, width, bodyHeight, onlyOnClose = false }) => {
  return (
    <ModalTheme
      open={open}
      onClose={() => {
        !onlyOnClose && setOpen(false);
        onClose && onClose();
      }}
    >
      <Container width={width}>
        {title && (
          <Bar>
            <Title>{title}</Title>
            <IconButton
              onClick={() => {
                !onlyOnClose && setOpen(false);
                onClose && onClose();
              }}
              color="error"
            >
              <Close color={'error'} />
            </IconButton>
          </Bar>
        )}
        <ChildrenContainer id={'modalBody'} height={bodyHeight}>
          {children}
        </ChildrenContainer>
      </Container>
    </ModalTheme>
  );
};

export default ModalStyled;
