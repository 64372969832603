import React, { useEffect, useState } from 'react';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS, COLORS_GRADIENT } from '../../common/constants/COLORS';
import { getDatabase, getDataDocs } from '../../common/services/firestore';
import { collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { currentUser } from '../../common/services/user';
import { PLANT_STATUS } from '../../common/constants/PLANT_STATUS';
import { formatReal, isEmptyObject, realToNumber } from '../../common/utils/objectManipulation';
import { database } from '../../common/services/firebase';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { clearAllNotification } from '../../common/services/notification';
import { heightWithoutToolbar } from '../../common/services/screen';
import { setLocalStorage } from '../../common/utils/storage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  height: ${heightWithoutToolbar};
  padding: 16px;
  background: ${COLORS.GRAY_BLACK_TRANSPARENT};
  overflow: auto;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

const HomeAdmin = () => {
  document.title = 'Controlote - Loteamento sob controle.';
  const [enterprises, setEnterprises] = useState();
  const [sold, setSold] = useState({ length: 0, price: 0 });
  const [reserved, setReserved] = useState(0);
  const [available, setAvailable] = useState(0);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const getEnterprises = async () => {
    setLoading(true);
    const dataDocs = getDataDocs(
      await getDocs(
        query(collection(getDatabase(), COLLECTION.ENTERPRISES), where('user', 'array-contains', currentUser()?.id))
      )
    );
    setEnterprises(dataDocs);
    setSold(getValues(dataDocs, PLANT_STATUS.SOLD));
    setReserved(getValues(dataDocs, PLANT_STATUS.RESERVED));
    setAvailable(getValues(dataDocs, PLANT_STATUS.AVAILABLE));
    setLoading(false);
  };

  const getValues = (dataDocs, status) => {
    let obj = { length: 0, price: formatReal(0) };
    for (const enterprise of dataDocs) {
      for (const form of enterprise?.forms) {
        if (form?.status === status) {
          obj = {
            length: 1 + obj?.length,
            price: formatReal(100 * (realToNumber(form?.price) + realToNumber(obj?.price))),
          };
        }
      }
    }
    return obj;
  };

  useEffect(() => {
    getEnterprises();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(currentUser()?.id)) {
      const unsub = onSnapshot(doc(database, COLLECTION.USERS, currentUser()?.id), async data => {
        setUser(data.data());
        setLocalStorage('user', data.data());
      });
      return () => {
        unsub();
      };
    }
  }, []);

  return (
    <RestrictArea permission={USER_PERMISSION.ADMIN} title={'Home'}>
      <Container>
        {!loading && (
          <Grid>
            {!isEmptyObject(user?.notification) && (
              <Card style={{ margin: '16px', width: '310px' }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Notificações
                  </Typography>
                  <Spacing display={'block'} overflow maxHeight={'400px'}>
                    {user?.notification.map((noti, ind) => (
                      <Spacing
                        margin={'8px'}
                        cursor={'pointer'}
                        borderRadius={'8px'}
                        padding={'8px'}
                        bg={COLORS_GRADIENT[ind]}
                        onClick={() => {
                          let url = noti?.url;
                          url = url.split(window.location.origin + '/');
                          url = '../' + url[1];
                          navigate(url);
                        }}
                      >
                        {noti?.title}
                      </Spacing>
                    ))}
                  </Spacing>
                  <ButtonStyled onClick={() => clearAllNotification()}>Limpar Tudo</ButtonStyled>
                </CardContent>
              </Card>
            )}
            <Card style={{ margin: '16px', cursor: 'pointer', width: '310px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Empreendimentos
                </Typography>
                <Typography variant="h2" color="text.secondary" style={{ color: COLORS.BLUE }}>
                  {enterprises?.length}
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ margin: '16px', cursor: 'pointer', width: '310px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Lotes Vendidos
                </Typography>
                <Typography variant="h3" color="text.secondary" style={{ color: COLORS.BLUE }}>
                  {sold?.length}
                </Typography>
                <Typography variant="h4" color="text.secondary" style={{ color: COLORS.GREEN }}>
                  R$ {sold?.price}
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ margin: '16px', cursor: 'pointer', width: '310px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Lotes Reservados
                </Typography>
                <Typography variant="h3" color="text.secondary" style={{ color: COLORS.BLUE }}>
                  {reserved?.length}
                </Typography>
                <Typography variant="h4" color="text.secondary" style={{ color: COLORS.GREEN }}>
                  R$ {reserved?.price}
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ margin: '16px', cursor: 'pointer', width: '310px' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Lotes Disponíveis
                </Typography>
                <Typography variant="h3" color="text.secondary" style={{ color: COLORS.BLUE }}>
                  {available?.length}
                </Typography>
                <Typography variant="h4" color="text.secondary" style={{ color: COLORS.GREEN }}>
                  R$ {available?.price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Container>
    </RestrictArea>
  );
};

export default HomeAdmin;
