import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';
import { isScreenDown, MediaQuery } from '../../services/screen';

export const Title = styled.h2`
  color: ${COLORS.GRAY_BLACK};
  width: 50%;
  margin: 16px 0 8px 0;
  font-weight: 500;

  ${MediaQuery(isScreenDown(600))} {
    width: 100%;
  }
`;

export const Text = styled.h3`
  color: ${COLORS.GRAY_BLACK};
  margin: 0;
  width: 50%;
  font-weight: 400;

  ${MediaQuery(isScreenDown(600))} {
    width: 100%;
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  margin: 0;
  margin-top: 8px;
  display: flex;
  flex-direction: row;

  ${MediaQuery(isScreenDown(600))} {
    flex-direction: column;
    margin-top: 16px;
  }
`;
