import {
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { appFirebase } from './firebase';
import { createDocument, updateDocument } from './firestore';
import { getUserByEmail } from './user';
import { COLLECTION } from '../constants/COLLECTION';
import { USER_PERMISSION } from '../constants/USER_PERMISSION';

export const auth = getAuth(appFirebase);

export const loginWithEmail = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);

      const newUser = {
        uid: result.user.uid,
        email: result.user.email,
        createdAt: result.user.metadata.creationTime,
        lastLoginAt: result.user.metadata.lastSignInTime,
      };

      saveUserInFirestore(newUser)
        .then(data => resolve(data))
        .catch(error => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};

export const createUserWithEmail = async (user, permission) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, user.email, user.password);

      const newUser = {
        ...user,
        uid: result.user.uid,
        name: user.name,
        email: result.user.email,
        createdAt: result.user.metadata.creationTime,
        lastLoginAt: result.user.metadata.lastSignInTime,
        permission: permission,
      };

      delete newUser?.password;

      saveUserInFirestore(newUser)
        .then(data => resolve(data))
        .catch(error => reject(error));
    } catch (error) {
      reject(error);
    }
  });
};

const saveUserInFirestore = user => {
  return new Promise(async (resolve, reject) => {
    try {
      const userByEmail = await getUserByEmail(user.email);
      if (!userByEmail.empty) {
        await handleExistUser(userByEmail, user, resolve, reject);
      } else {
        await createUser(user, resolve, reject);
      }
    } catch (error) {
      await createUser(user, resolve, reject);
    }
  });
};

const handleExistUser = async (userByEmail, user, resolve, reject) => {
  try {
    const data = userByEmail.docs[0].data();
    if (data?.uid === user.uid) {
      const newUser = { ...data, ...user };
      await updateDocument(COLLECTION.USERS, newUser);
      resolve(newUser);
    } else {
      reject({ code: 'auth/email-already-in-use' });
    }
  } catch (error) {
    reject(error);
  }
};

const createUser = async (user, resolve, reject) => {
  try {
    await createDocument(COLLECTION.USERS, user);
    resolve(user);
  } catch (error) {
    reject(error);
  }
};

export const resetPassword = email => {
  return sendPasswordResetEmail(auth, email);
};

export const getLoginPage = permission => (permission === USER_PERMISSION.ADMIN ? '../admin' : '../');

export const notIsLogged = (id, user) => id === undefined || user.data() === undefined;

export const getHomePage = (navigate, userPermission) =>
  userPermission === USER_PERMISSION.ADMIN ? '../admin/home' : '../home';

export const checkCondition = (condition, func) => {
  if (condition && func !== undefined && func !== null && typeof func === 'function') {
    func();
  }
};

export const compareUid = (first, second) => {
  return first !== second;
};
