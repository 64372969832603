import React, { useEffect, useState } from 'react';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { Box, Chip } from '@mui/material';
import ContainerMaxTable from '../../common/components/ContainerMaxTable/ContainerMaxTable';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { Add, FilterList } from '@mui/icons-material';
import EnterprisesModal from './EnterprisesModal';
import { getDatabase, getDataDocs } from '../../common/services/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { currentUser } from '../../common/services/user';
import TableData from '../../common/components/TableData/TableData';
import { isMobile, percentInPixelWidth } from '../../common/services/screen';
import SelectStyled from '../../common/components/SelectStyled/SelectStyled';
import { ENTERPRISES_STATUS } from '../../common/constants/ENTERPRISES_STATUS';
import { clearForm } from '../../common/utils/formController';
import CreateInteractivePlant from '../CreateInteractivePlant/CreateInteractivePlant';
import { COLLECTION } from '../../common/constants/COLLECTION';

const EnterprisesAdmin = () => {
  const [filter, setFilter] = useState(false);
  const [idEnterprise, setIdEnterprise] = useState();
  const [form, setForm] = useState({ name: { value: '' }, status: { value: '' }, description: { value: '' } });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [enterprises, setEnterprises] = useState();
  const [isModify, setIsModify] = useState();
  const [openInteractivePlant, setOpenInteractivePlant] = useState(false);

  const getEnterprises = async () => {
    setLoadingTable(true);
    let dataDocs = getDataDocs(
      await getDocs(
        query(collection(getDatabase(), COLLECTION.ENTERPRISES), where('user', 'array-contains', currentUser()?.id))
      )
    );
    dataDocs = dataDocs.filter(data => {
      return (
        data.description?.toLowerCase().includes(form?.description?.value.toLowerCase()) &&
        data.name?.toLowerCase().includes(form?.name?.value.toLowerCase()) &&
        (form?.status?.value !== '' ? data.status === form?.status?.value : true)
      );
    });
    setEnterprises(dataDocs);
    setLoadingTable(false);
  };

  useEffect(() => {
    getEnterprises();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      width: percentInPixelWidth(33.33333) - 10,
    },
    { field: 'description', headerName: 'Descrição', width: percentInPixelWidth(33.33333) },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <Chip
          sx={{
            background:
              params.row.status === ENTERPRISES_STATUS.ACTIVE
                ? COLORS.GREEN_LIGHT_TRANSPARENT
                : COLORS.GRAY_BLACK_TRANSPARENT,
          }}
          label={params.row.status}
          variant="outlined"
        />
      ),
      width: percentInPixelWidth(33.33333),
    },
  ];

  return (
    <RestrictArea
      permission={USER_PERMISSION.ADMIN}
      title={'Empreendimentos'}
      barButtons={
        <>
          <ButtonStyled onClick={() => setFilter(!filter)} startIcon={<FilterList />} bg={COLORS.WHITE}>
            Filtro
          </ButtonStyled>
          <ButtonStyled
            onClick={() => {
              setIsModify(false);
              setOpenCreateModal(true);
            }}
            startIcon={<Add />}
            margin={'0 0 0 16px'}
            bg={COLORS.WHITE}
          >
            Novo
          </ButtonStyled>
        </>
      }
    >
      <EnterprisesModal
        openModal={openCreateModal}
        setOpenModal={setOpenCreateModal}
        getEnterprises={getEnterprises}
        setOpenInteractivePlant={setOpenInteractivePlant}
        setIdEnterprise={setIdEnterprise}
        idEnterprise={idEnterprise}
        isModify={isModify}
      />
      <CreateInteractivePlant
        setOpenModal={setOpenCreateModal}
        open={openInteractivePlant}
        setOpen={setOpenInteractivePlant}
        id={idEnterprise}
      />
      <ContainerMaxTable>
        {filter && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              padding: '16px',
            }}
          >
            <Spacing width={isMobile ? '100%' : 'calc(100% - 192px)'}>
              <InputStyled notRequired controller={{ form, setForm }} name={'name'} label={'Nome'} />
              <InputStyled notRequired controller={{ form, setForm }} name={'description'} label={'Descrição'} />
              <SelectStyled
                controller={{ form, setForm }}
                label={'Status'}
                name={'status'}
                notRequired={'0'}
                paddingBottom
                options={[
                  { value: ENTERPRISES_STATUS.ACTIVE, label: 'Ativo' },
                  { value: ENTERPRISES_STATUS.INACTIVE, label: 'Inativo' },
                ]}
              />
            </Spacing>
            <Spacing width={isMobile ? '100%' : '192px'} margin={'16px 0 0 0'} jc={'flex-end'} fd={'row'}>
              <ButtonStyled
                onClick={() => {
                  setForm(clearForm(form));
                }}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.RED_BLACK}
                colorText={COLORS.WHITE}
              >
                Limpar
              </ButtonStyled>
              <ButtonStyled
                onClick={() => {
                  getEnterprises();
                }}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.GRAY}
                colorText={COLORS.WHITE}
              >
                Filtrar
              </ButtonStyled>
            </Spacing>
          </Box>
        )}
        {enterprises && (
          <TableData
            rowClick={({ id }) => {
              setIsModify(true);
              setIdEnterprise(id);
              setOpenCreateModal(true);
            }}
            rows={enterprises}
            columns={columns}
            loading={loadingTable}
          />
        )}
      </ContainerMaxTable>
    </RestrictArea>
  );
};

export default EnterprisesAdmin;
