import React, { useEffect, useState } from 'react';
import ImageMapper from '../ImageMapper/ImageMapper';
import { Spacing } from '../Spacing/Spacing';
import { COLORS } from '../../constants/COLORS';
import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../services/screen';

const ImagePlant = ({ enterprise, plant, maxWidth, maxHeight, onClick, isView = false }) => {
  const [maxHeightInternal, setMaxHeightInternal] = useState(0);
  const [maxWidthInternal, setMaxWidthInternal] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [zoom, setZoom] = useState(1);
  const navigate = useNavigate();

  function calcWidth() {
    return (maxHeightInternal * enterprise?.imageOrigen?.xScreen) / enterprise?.imageOrigen?.yScreen;
  }

  function calcHeight() {
    return (maxWidthInternal * enterprise?.imageOrigen?.yScreen) / enterprise?.imageOrigen?.xScreen;
  }

  const calcSize = () => {
    let tempWidth;
    let tempHeight;
    if (maxHeightInternal > maxWidthInternal) {
      tempWidth = calcWidth();
      tempHeight = maxHeightInternal;
      if (tempWidth > maxWidthInternal) {
        tempHeight = calcHeight();
        tempWidth = maxWidthInternal;
      }
    } else {
      tempHeight = calcHeight();
      tempWidth = maxWidthInternal;
      if (tempHeight > maxHeightInternal) {
        tempWidth = calcWidth();
        tempHeight = maxHeightInternal;
      }
    }
    setWidth(tempWidth);
    setHeight(tempHeight);
  };

  const onZoom = event => {
    if (event.altKey) {
      if (event.deltaY < 0) {
        setZoom(zoom + 0.25);
      } else if (zoom > 1) {
        setZoom(zoom - 0.25);
      }
    }
  };

  useEffect(() => {
    setMaxHeightInternal(maxHeight);
    setMaxWidthInternal(maxWidth);
  }, [maxHeight]);

  useEffect(() => {
    calcSize();
  }, [enterprise, plant, maxHeightInternal, maxWidthInternal]);

  return (
    <Spacing display={'block'} height={maxHeightInternal + 'px'} bg={COLORS.BLACK_TRANSPARENT}>
      <Spacing
        display={'block'}
        draggable="false"
        id={'plantScroll'}
        onWheel={event => onZoom(event)}
        overflow
        height={maxHeightInternal + 'px'}
        width={'100%'}
      >
        <Spacing ai={width * zoom <= maxWidthInternal ? 'center' : 'flex-start'}>
          <ImageMapper
            src={
              plant ||
              'https://rockcontent.com/br/wp-content/uploads/sites/2/2019/02/Como-usar-o-Photoshop-1024x538.png'
            }
            height={height * zoom}
            imgHeight={enterprise?.imageOrigen?.yScreen}
            width={width * zoom}
            imgWidth={enterprise?.imageOrigen?.xScreen}
            map={enterprise?.cords}
            showStatus={false}
            onClick={event => onClick(event)}
          />
        </Spacing>
      </Spacing>
      <Spacing margin={'-60px 0 0 0'} fd={'row'} jc={'flex-end '}>
        {isView && (
          <IconButton
            style={{
              margin: '0 8px 0 0',
              background: COLORS.WHITE_LIGHT,
              border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
              zIndex: 3,
            }}
            title={'Voltar'}
            onClick={async () => {
              document.exitFullscreen();
              navigate('../admin/empreendimentos');
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <IconButton
          style={{
            margin: '0 8px 0 0',
            background: COLORS.WHITE_LIGHT,
            border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
            zIndex: 3,
          }}
          title={'Zoom In'}
          onClick={() => setZoom(zoom + 0.25)}
        >
          <Add />
        </IconButton>
        <IconButton
          style={{
            margin: '0 8px 0 0',
            background: COLORS.WHITE_LIGHT,
            border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
            zIndex: 3,
          }}
          title={'Zoom Out'}
          onClick={() => {
            if (zoom > 1) {
              setZoom(zoom - 0.25);
            }
          }}
        >
          <HorizontalRuleIcon />
        </IconButton>
        <IconButton
          style={{
            margin: isView ? '0 8px 0 0' : '0 16px 0 0',
            background: COLORS.WHITE_LIGHT,
            border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
            zIndex: 3,
          }}
          title={'Remover Zoom'}
          onClick={() => setZoom(1)}
        >
          <ZoomInMapIcon />
        </IconButton>
        {isView && !isMobile && (
          <IconButton
            style={{
              margin: '0 16px 0 0',
              background: COLORS.WHITE_LIGHT,
              border: '1px solid ' + COLORS.BLACK_TRANSPARENT,
              zIndex: 3,
            }}
            title={
              document.documentElement.clientWidth === screen.width &&
              document.documentElement.clientHeight === screen.height
                ? 'Sair tela cheia'
                : 'Colocar em tela cheia'
            }
            onClick={async () => {
              const isFullScreen =
                document.documentElement.clientWidth === screen.width &&
                document.documentElement.clientHeight === screen.height;
              if (isFullScreen) {
                await document.exitFullscreen();
              } else {
                await document.documentElement.requestFullscreen();
              }
              setMaxHeightInternal(document.documentElement.clientHeight);
              setMaxWidthInternal(document.documentElement.clientWidth);
            }}
          >
            <AspectRatioIcon />
          </IconButton>
        )}
      </Spacing>
    </Spacing>
  );
};

export default ImagePlant;
