import React, { useEffect, useState } from 'react';
import AppBarStyled from '../AppBarStyled/AppBarStyled';
import Menu from '../Menu/Menu';
import { MENU_ADMIN } from './menuAdmin';
import { USER_PERMISSION } from '../../constants/USER_PERMISSION';
import { useNavigate } from 'react-router-dom';
import { currentUser } from '../../services/user';
import { MENU_USER } from './menuUser';
import LogoContainer from '../LogoContainer/LogoContainer';
import { doc, getDoc } from 'firebase/firestore';
import { getDatabase } from '../../services/firestore';
import { COLLECTION } from '../../constants/COLLECTION';
import { auth, checkCondition, compareUid, getHomePage, getLoginPage, notIsLogged } from '../../services/auth';
import { isEmptyObject } from '../../utils/objectManipulation';

const getElementByLoginStatus = (hasUser, elememt) => (hasUser ? elememt : <LogoContainer />);

const RestrictArea = ({ permission, children, title, barButtons, onBack = false, isFull = false }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [hasUser, setHasUser] = useState(false);
  const navigate = useNavigate();

  const checkHasUser = async () => {
    const id = currentUser()?.id;
    const uid = currentUser()?.uid;
    const userPermission = currentUser()?.permission;
    try {
      const user = await getDoc(doc(getDatabase(), COLLECTION.USERS, id));
      checkCondition(notIsLogged(id, user), () => navigate(getLoginPage(permission)));
      checkCondition(userPermission !== undefined && userPermission !== permission, () =>
        navigate(getHomePage(userPermission))
      );
      checkCondition(compareUid(uid, auth?.currentUser?.uid), () => navigate(getLoginPage(userPermission)));
      setHasUser(id !== undefined);
    } catch (error) {
      navigate(getLoginPage(permission));
    }
  };
  const handleStorage = event => {
    if (event.key === 'user') {
      checkHasUser();
    }
  };

  useEffect(() => {
    checkHasUser();
    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  useEffect(() => {
    const onAuthStateChanged = auth.onAuthStateChanged(user => {
      if (isEmptyObject(user?.uid) && compareUid(user?.uid, currentUser()?.id)) {
        localStorage.removeItem('user');
        navigate(getLoginPage(permission));
      }
    });
    return () => {
      onAuthStateChanged();
    };
  }, []);

  if (isFull) {
    return getElementByLoginStatus(hasUser, children);
  }

  return getElementByLoginStatus(
    hasUser,
    <>
      <AppBarStyled setOpenMenu={setOpenMenu} title={title} buttons={barButtons} onBack={onBack} />
      <Menu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        menu={permission === USER_PERMISSION.ADMIN ? MENU_ADMIN : MENU_USER}
      />
      {children}
    </>
  );
};

export default RestrictArea;
