import React, { useEffect, useState } from 'react';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { Container, Grid } from './styled';
import { Card, CardContent, Typography } from '@mui/material';
import { currentUser } from '../../common/services/user';
import CardEnterprise from '../../common/components/CardEnterprise/CardEnterprise';
import { getEnterprisesById } from '../../common/services/enterprises';
import { isEmptyObject } from '../../common/utils/objectManipulation';
import { Spacing } from '../../common/components/Spacing/Spacing';
import { COLORS_GRADIENT } from '../../common/constants/COLORS';
import { doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { clearAllNotification } from '../../common/services/notification';
import { setLocalStorage } from '../../common/utils/storage';

const Home = () => {
  document.title = 'Controlote - Loteamento sob controle.';

  const [enterprises, setEnterprises] = useState([]);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const getEnterprises = async () => {
    const data = await getEnterprisesById(currentUser()?.id);
    setEnterprises(data);
  };

  useEffect(() => {
    getEnterprises();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(currentUser()?.id)) {
      const unsub = onSnapshot(doc(database, COLLECTION.USERS, currentUser()?.id), async data => {
        setUser(data.data());
        setLocalStorage('user', data.data());
      });
      return () => {
        unsub();
      };
    }
  }, []);

  return (
    <RestrictArea permission={USER_PERMISSION.USER} title={'Home'}>
      <Container>
        {!isEmptyObject(user?.notification) && (
          <>
            <Typography gutterBottom variant="h5" component="div">
              Notificações
            </Typography>
            <Grid>
              <Card style={{ margin: '16px', width: '310px' }}>
                <CardContent>
                  <Spacing display={'block'} overflow maxHeight={'400px'}>
                    {user?.notification.map((noti, ind) => (
                      <Spacing
                        margin={'8px'}
                        cursor={'pointer'}
                        borderRadius={'8px'}
                        padding={'8px'}
                        bg={COLORS_GRADIENT[ind]}
                        onClick={() => {
                          let url = noti?.url;
                          url = url.split(window.location.origin + '/');
                          url = '../' + url[1];
                          navigate(url);
                        }}
                      >
                        {noti?.title}
                      </Spacing>
                    ))}
                  </Spacing>
                  <ButtonStyled onClick={() => clearAllNotification()}>Limpar Tudo</ButtonStyled>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
        <Typography
          style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
          gutterBottom
          variant="h5"
          component="div"
        >
          Empreendimentos
          {enterprises.length > 3 && (
            <ButtonStyled onClick={() => navigate('../empreendimentos')}>Ver mais</ButtonStyled>
          )}
        </Typography>
        <Grid>
          {enterprises &&
            enterprises.slice(0, 3).map(enterprise => {
              return <CardEnterprise {...{ enterprise }} />;
            })}
        </Grid>
      </Container>
    </RestrictArea>
  );
};

export default Home;
