import { collection, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { database } from './firebase';

export const createDocument = (path, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = await addDoc(collection(database, path), data);
      data.id = docRef.id;
      resolve([docRef, await updateDocument(path, data)]);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateDocument = (path, data) => {
  return updateDoc(doc(database, path, data?.id), data);
};

export const deleteDocument = (path, data) => {
  return deleteDoc(doc(database, path, data?.id));
};

export const getDataDocs = docs => {
  return docs?.docs?.map(document => document.data());
};

export const getDatabase = () => database;
