import styled from 'styled-components';
import { COLORS } from '../../common/constants/COLORS';
import { heightWithoutToolbar } from '../../common/services/screen';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  height: ${heightWithoutToolbar};
  padding: 16px;
  background: ${COLORS.GRAY_BLACK_TRANSPARENT};
  overflow: auto;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
`;
