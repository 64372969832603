import { createDocument } from './firestore';
import { COLLECTION } from '../constants/COLLECTION';

const getInfo = () => {
  let screenSize;
  let version;
  let browser;
  let mobile;
  let cookieEnabled;
  let osVersion;
  let os;
  {
    const unknown = 'Unknown';

    // screen
    screenSize = '';
    let width;
    let height;
    if (screen.width) {
      width = screen.width ? screen.width : '';
      height = screen.height ? screen.height : '';
      screenSize += String(width) + ' x ' + height;
    }

    //browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    browser = navigator.appName;
    version = String(parseFloat(navigator.appVersion));
    let majorVersion = parseInt(navigator.appVersion, 10);
    let ix;
    let nameOffset;
    let verOffset;

    mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    }

    //IE 11 no longer identifies itself as MS IE, so trap it
    //http://stackoverflow.com/questions/17907445/how-to-detect-ie11
    else if (browser === 'Netscape' && nAgt.indexOf('Trident/') !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
      if ((verOffset = nAgt.indexOf('rv:')) !== -1) {
        version = nAgt.substring(verOffset + 3);
      }
    }

    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        version = nAgt.substring(verOffset + 8);
      }

      // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
      //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
      //  No mention of chrome in the user agent string. However, it does mention CriOS, which presumably
      //  can be keyed on to detect it.
      if (nAgt.indexOf('CriOS') !== -1) {
        //Chrome on iPad spoofing Safari...correct it.
        browser = 'Chrome';
        //Don't believe there is a way to grab the accurate version number, so leaving that for now.
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

    majorVersion = parseInt(String(version), 10);
    if (isNaN(majorVersion)) {
      version = String(parseFloat(navigator.appVersion));
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    // cookie
    cookieEnabled = Boolean(navigator.cookieEnabled);

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }

    // system
    os = unknown;
    const clientStrings = [
      { system: 'Windows 3.11', reg: /Win16/ },
      { system: 'Windows 95', reg: /(Windows 95|Win95|Windows_95)/ },
      { system: 'Windows ME', reg: /(Win 9x 4.90|Windows ME)/ },
      { system: 'Windows 98', reg: /(Windows 98|Win98)/ },
      { system: 'Windows CE', reg: /Windows CE/ },
      { system: 'Windows 2000', reg: /(Windows NT 5.0|Windows 2000)/ },
      { system: 'Windows XP', reg: /(Windows NT 5.1|Windows XP)/ },
      { system: 'Windows Server 2003', reg: /Windows NT 5.2/ },
      { system: 'Windows Vista', reg: /Windows NT 6.0/ },
      { system: 'Windows 7', reg: /(Windows 7|Windows NT 6.1)/ },
      { system: 'Windows 8.1', reg: /(Windows 8.1|Windows NT 6.3)/ },
      { system: 'Windows 8', reg: /(Windows 8|Windows NT 6.2)/ },
      { system: 'Windows NT 4.0', reg: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { system: 'Windows ME', reg: /Windows ME/ },
      { system: 'Android', reg: /Android/ },
      { system: 'Open BSD', reg: /OpenBSD/ },
      { system: 'Sun OS', reg: /SunOS/ },
      { system: 'Linux', reg: /(Linux|X11)/ },
      { system: 'iOS', reg: /(iPhone|iPad|iPod)/ },
      { system: 'Mac OS X', reg: /Mac OS X/ },
      { system: 'Mac OS', reg: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { system: 'QNX', reg: /QNX/ },
      { system: 'UNIX', reg: /UNIX/ },
      { system: 'BeOS', reg: /BeOS/ },
      { system: 'OS/2', reg: /OS\/2/ },
      { system: 'Search Bot', reg: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
    ];
    for (const id in clientStrings) {
      const cs = clientStrings[id];
      if (cs.reg.test(nAgt)) {
        os = cs.system;
        break;
      }
    }

    osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (10[._\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([._\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
        break;
    }
  }

  return {
    screen: screenSize,
    browser: browser,
    browserVersion: version,
    mobile: mobile,
    os: os,
    osVersion: osVersion,
    cookies: cookieEnabled,
  };
};

export const createLog = data => {
  let info;
  try {
    info = getInfo();
  } catch {
    info = 'error';
  }
  const log = {
    ...data,
    info: info,
    createAt: new Date(),
  };
  createDocument(COLLECTION.LOGS, log);
};
