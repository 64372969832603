import styled from 'styled-components';
import { isDesktop } from '../../common/services/screen';
import { COLORS } from '../../common/constants/COLORS';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const LoginWrapper = styled.div`
  height: 100%;
  width: ${isDesktop ? '60%' : '100%'};
  background: linear-gradient(145deg, ${COLORS.BLUE_BLACK}, ${COLORS.GREEN});
  position: fixed;
  top: 0;
  right: ${({ position }) => position};
  transition: right 1s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  width: 80%;
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 32px 0;
`;
