import styled from 'styled-components';
import { COLORS } from '../../common/constants/COLORS';

export const Title = styled.h2`
  color: ${COLORS.GRAY_BLACK};
  width: calc(100% - 26px);
  margin: 16px 0 8px 0;
  font-weight: 500;
`;

export const Text = styled.h3`
  color: ${COLORS.GRAY_BLACK};
  width: calc(100% - 26px);
  margin: 0;
  font-weight: 400;
`;
