import React, { useState } from 'react';
import { Spacing } from '../../common/components/Spacing/Spacing';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import ModalStyled from '../../common/components/ModalStyled/ModalStyled';
import { clearForm, getDataForm, isValidForm } from '../../common/utils/formController';
import { createInviteAdmin } from '../../common/services/invitesAdmin';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import { useSnackbar } from 'notistack';
import SelectStyled from '../../common/components/SelectStyled/SelectStyled';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import { auth } from '../../common/services/auth';
import { sendEmail } from '../../common/services/notification';

const CreateInviteModal = ({ openModal, setOpenModal, getInvites }) => {
  const [form, setForm] = useState({ type: { value: USER_PERMISSION.USER } });
  const { enqueueSnackbar } = useSnackbar();

  function handlePhone(data) {
    if (data.phone) {
      data.phone =
        '+55' +
        data.phone
          .replaceAll('(', '')
          .replaceAll(') ', '')
          .replaceAll('-', '');
    }
  }

  const onSubmit = async () => {
    if (isValidForm(form, setForm)) {
      setOpenModal(false);
      const data = getDataForm(form);
      handlePhone(data);
      const inv = await createInviteAdmin(data);
      await getInvites();
      setForm(clearForm(form));
      setForm({ type: { value: USER_PERMISSION.USER } });
      await sendEmail(
        data?.email,
        'Olá, ' + data?.name + '! Chegou o seu Convite!',
        'Olá, ' + data?.name + '! Segue o link para acessar seu convite.',
        window.location.origin + '/convite/' + inv[0]?.id
      );
      enqueueSnackbar('Convite criado com sucesso.', { variant: ALERT_TYPES.SUCCESS });
    } else {
      enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    }
  };

  return (
    <ModalStyled
      title={'Adicionar Convite'}
      open={openModal}
      setOpen={setOpenModal}
      onClose={() => {
        setForm(clearForm(form));
        setForm({ type: { value: USER_PERMISSION.USER } });
      }}
    >
      <Spacing ai={'end'}>
        <InputStyled
          onValidation={value =>
            String(value)
              .toLowerCase()
              .match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
          }
          messageValidation={'E-mail inválido.'}
          controller={{ form, setForm }}
          name={'email'}
          label={'E-mail'}
          type={'email'}
        />
        <InputStyled controller={{ form, setForm }} name={'name'} label={'Nome'} type={'text'} />
        <InputStyled
          onValidation={value =>
            String(value)
              .toLowerCase()
              .match(/\((\d{2})\)\s(\d{5})-(\d{4})/g) !== null && value.length === 15
          }
          messageValidation={'Celular inválido. Ex: (48) 99988-9988'}
          notRequired
          mask={value => {
            return (
              value &&
              value
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
                .replace(/(\d{5})(\d{4})/, '$1-$2')
            );
          }}
          maxLength={15}
          controller={{ form, setForm }}
          name={'phone'}
          label={'Celular'}
          type={'text'}
        />
        {(auth?.currentUser?.uid === 'wdPKR5QaWyMwSR4TP0yfaDLmsk52' ||
          auth?.currentUser?.uid === 'zL5OQU8GVbWN0w4aZQDBWbjaxlh2') && (
          <SelectStyled
            controller={{ form, setForm }}
            label={'Tipo'}
            name={'type'}
            options={[
              { value: USER_PERMISSION.USER, label: 'Novo Usuário' },
              { value: USER_PERMISSION.ADMIN, label: 'Novo Usuário Administrativo' },
            ]}
          />
        )}
        <ButtonStyled onClick={() => onSubmit()} bg={COLORS.GREEN_LIGHT} variant="contained">
          Gerar Convite
        </ButtonStyled>
      </Spacing>
    </ModalStyled>
  );
};

export default CreateInviteModal;
