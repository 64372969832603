import styled from 'styled-components';
import { InputLabel } from '@mui/material';
import { COLORS } from '../../constants/COLORS';

export const InputLabelStyled = styled(InputLabel)`
  &.Mui-focused {
    color: ${COLORS.GRAY} !important;
  }

  ${({ disabled }) => disabled && `color: ${COLORS.GRAY_BLACK_TRANSPARENT};`}
`;
