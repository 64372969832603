import { createDocument, deleteDocument, updateDocument } from './firestore';
import { INVITE_STATUS } from '../constants/INVITE_STATUS';
import { currentUser } from './user';
import { database } from './firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { COLLECTION } from '../constants/COLLECTION';

export const createInviteAdmin = async data => {
  const invite = {
    ...data,
    user: currentUser()?.id,
    status: INVITE_STATUS.SENDING,
    invitedDate: new Date(),
    validDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  return createDocument(COLLECTION.INVITES, invite);
};

export const saveInviteAdmin = async data => {
  const invite = {
    ...data,
    invitedDate: new Date(),
    validDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  };
  await updateDocument(COLLECTION.INVITES, invite);
  return data;
};

export const recusedInviteAdmin = async data => {
  const invite = {
    ...data,
    status: INVITE_STATUS.EXPIRED,
  };
  await updateDocument(COLLECTION.INVITES, invite);
};

export const acceptedInviteAdmin = async data => {
  const invite = {
    ...data,
    status: INVITE_STATUS.ACCEPTED,
  };
  await updateDocument(COLLECTION.INVITES, invite);
};

export const deleteInviteAdmin = async data => {
  await deleteDocument(COLLECTION.INVITES, data);
};

export const getAdminsIdById = async id => {
  return (
    await getDocs(
      query(
        collection(database, COLLECTION.INVITES),
        where('userInvited', '==', id),
        where('status', '==', INVITE_STATUS.ACCEPTED)
      )
    )
  ).docs?.map(data => data?.data()?.user);
};
