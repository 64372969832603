import React, { useEffect, useState } from 'react';
import ModalStyled from '../ModalStyled/ModalStyled';
import { Spacing } from '../Spacing/Spacing';
import { Typography } from '@mui/material';
import { formatReal, realToNumber } from '../../utils/objectManipulation';
import { around } from '../../services/calc';

const ModalParcels = ({ setOpen, open, parcels, enterprise }) => {
  const [showInformation, setShowInformation] = useState();
  const getSize = () => {
    if (showInformation?.armotization === true && showInformation?.tax === false) {
      return '33%';
    } else if (showInformation?.armotization === false && showInformation?.tax === true) {
      return '33%';
    } else if (showInformation?.armotization === true && showInformation?.tax === true) {
      return getSize();
    }
    return '50%';
  };

  useEffect(() => {
    setShowInformation(enterprise?.tax?.showInformation);
  }, [enterprise]);

  return (
    <ModalStyled setOpen={setOpen} open={open} title={'Parcelas'}>
      <Spacing fd={'row'} jc={'space-between'}>
        <Typography fontWeight={'600'} width={getSize()}>
          Nº
        </Typography>
        <Typography fontWeight={'600'} width={getSize()}>
          Parcelas
        </Typography>
        {showInformation?.amortization && (
          <Typography fontWeight={'600'} width={getSize()}>
            Amortização
          </Typography>
        )}
        {showInformation?.tax && (
          <Typography fontWeight={'600'} width={getSize()}>
            Juros
          </Typography>
        )}
      </Spacing>
      {parcels?.map(parcel => (
        <Spacing fd={'row'} jc={'space-between'}>
          <Typography width={getSize()}>{parcel?.parcelNumber}</Typography>
          <Typography width={getSize()}>
            R$
            {formatReal(
              parseInt(realToNumber(parcel.parcelValue))
                .toString()
                .replace(/\D+/g, '')
            )}
          </Typography>
          {showInformation?.amortization && (
            <Typography width={getSize()}>
              R$
              {formatReal(
                parseInt(realToNumber(parcel.amortization))
                  .toString()
                  .replace(/\D+/g, '')
              )}
            </Typography>
          )}
          {showInformation?.tax && (
            <Typography width={getSize()}>
              R$
              {formatReal(
                around(parcel.tax, 2)
                  .toString()
                  .replace(/\D+/g, '')
              )}
            </Typography>
          )}
        </Spacing>
      ))}
      <Spacing fd={'row'} jc={'space-between'}>
        <Typography fontWeight={'600'} width={getSize()}>
          Total
        </Typography>
        <Typography fontWeight={'600'} width={getSize()}>
          R$
          {formatReal(
            around(
              parcels?.reduce((acc, parcel) => {
                return acc + realToNumber(parcel.parcelValue);
              }, 0) / 100,
              2
            )
              .toString()
              .replace(/\D+/g, '')
          )}
        </Typography>
        <Typography fontWeight={'600'} width={getSize()}>
          R$
          {formatReal(
            around(
              parcels?.reduce((acc, parcel) => {
                return acc + realToNumber(parcel.amortization);
              }, 0) / 100,
              2
            )
              .toString()
              .replace(/\D+/g, '')
          )}
        </Typography>
        <Typography fontWeight={'600'} width={getSize()}>
          R$
          {formatReal(
            around(
              parcels?.reduce((acc, parcel) => {
                return acc + parcel.tax;
              }, 0),
              2
            )
              .toString()
              .replace(/\D+/g, '')
          )}
        </Typography>
      </Spacing>
    </ModalStyled>
  );
};

export default ModalParcels;
