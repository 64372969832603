import styled from 'styled-components';
import { FormControl } from '@mui/material';
import { COLORS } from '../../constants/COLORS';

export const FormControlStyled = styled(FormControl)`
  width: 100%;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  margin-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '16px')} !important;

  .MuiFormLabel-root {
    ${({ error }) => error && `color: ${COLORS.RED};`};
    line-height: 1.8em;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${COLORS.GRAY};
      border-width: 0.5px;
    }
  }
  
  .Mui-disabled {
    opacity: 0.5;
  }
`;
