import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { currentUser } from '../../services/user';
import { isEmptyObject } from '../../utils/objectManipulation';
import { configCloudMessaging, onMessageListener } from '../../services/notification';
import { flutterMessage } from '../../services/flutter';
import { FLUTTER_MESSAGE } from '../../constants/FLUTTER_MESSAGE';
import { useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../services/firebase';
import { COLLECTION } from '../../constants/COLLECTION';

const GlobalComponents = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isMessageConfigured, setIsMessageConfigured] = useState(false);
  let isMessageSetup = false;

  const configMessaging = async () => {
    const user = currentUser();
    if (!isEmptyObject(user) && !isMessageSetup) {
      setIsMessageConfigured(true);
      isMessageSetup = true;
      flutterMessage(FLUTTER_MESSAGE.LOGIN, user?.id);
      configCloudMessaging(user);
      onMessageListener(enqueueSnackbar, closeSnackbar, navigate);
    }
  };

  useEffect(() => {
    setInterval(() => configMessaging(), [500]);
  }, []);

  useEffect(() => {
    if (isMessageConfigured) {
      const unsub = onSnapshot(doc(database, COLLECTION.CONFIG, 'web'), async data => {
        const version = data.data()?.version;
        const user = currentUser();
        flutterMessage(FLUTTER_MESSAGE.UPDATE, user?.id + '/' + version);
      });
      return () => {
        unsub();
      };
    }
  }, [isMessageConfigured]);

  return <>{children}</>;
};

export default GlobalComponents;
