import { collection, getDocs, query, where } from 'firebase/firestore';
import { database } from './firebase';
import { getAdminsIdById } from './invitesAdmin';
import { getListImageStorage } from './Image';
import { ENTERPRISES_STATUS } from '../constants/ENTERPRISES_STATUS';
import { COLLECTION } from '../constants/COLLECTION';

const getImage = async enterprise => {
  return (await getListImageStorage(enterprise?.id, 'cover'))[0]?.url;
};

export const getEnterprisesById = async id => {
  const admins = await getAdminsIdById(id);
  const docs = (
    await getDocs(
      query(
        collection(database, COLLECTION.ENTERPRISES),
        where('user', 'array-contains-any', admins),
        where('status', '==', ENTERPRISES_STATUS.ACTIVE)
      )
    )
  ).docs;
  let list = [];
  for (const enterprise of docs) {
    const enterpriseData = enterprise.data();
    list = [
      ...list,
      {
        ...enterpriseData,
        image: await getImage(enterpriseData),
      },
    ];
  }
  return list;
};
