import React, { useEffect, useState } from 'react';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { doc, onSnapshot } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { useParams } from 'react-router-dom';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import ImagePlant from '../../common/components/ImagePlant/ImagePlant';
import { getListImageStorage } from '../../common/services/Image';

const EnterpriseView = () => {
  const { id } = useParams();
  const [enterprise, setEnterprise] = useState();
  const [plant, setPlant] = useState();

  const getPlant = async () => {
    setPlant((await getListImageStorage(id, 'plant'))[0]?.url);
  };

  useEffect(() => {
    getPlant();
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(doc(database, COLLECTION.ENTERPRISES, id), async data => {
      await setEnterprise(data.data());
      await setEnterprise(data.data());
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <RestrictArea permission={USER_PERMISSION.ADMIN} isFull={true}>
      <ImagePlant
        onClick={event => console.log(event)}
        enterprise={enterprise}
        plant={plant}
        maxWidth={document.body.clientWidth}
        maxHeight={document.body.clientHeight}
        isView={true}
      />
    </RestrictArea>
  );
};

export default EnterpriseView;
