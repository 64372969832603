import React, { useEffect, useState } from 'react';
import { clearForm, getDataForm, isValidForm } from '../../common/utils/formController';
import { Spacing } from '../../common/components/Spacing/Spacing';
import ModalStyled from '../../common/components/ModalStyled/ModalStyled';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { heightWithoutToolbar, isDesktop, isMobile } from '../../common/services/screen';
import SelectStyled from '../../common/components/SelectStyled/SelectStyled';
import { Divider, IconButton, Typography } from '@mui/material';
import { COLORS } from '../../common/constants/COLORS';
import { ENTERPRISES_STATUS } from '../../common/constants/ENTERPRISES_STATUS';
import { STATE_CITIES } from '../../common/constants/STATE_CITIES';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { ALERT_TYPES } from '../../common/constants/ALERT_TYPES';
import {
  createEnterprisesAdmin,
  deleteEnterprisesAdmin,
  saveEnterprisesAdmin,
} from '../../common/services/enterprisesAdmin';
import { useSnackbar } from 'notistack';
import WomanAvatar from '../../static/img/womenavatar.png';
import { isEmptyObject } from '../../common/utils/objectManipulation';
import { getListImageStorage, uploadMultiImageStorage, uploadSingleImageStorage } from '../../common/services/Image';
import FileInput from '../../common/components/FileInput/FileInput';
import { Close } from '@mui/icons-material';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import AlertDialog from '../../common/components/AlertDialog/AlertDialog';
import { doc, getDoc } from 'firebase/firestore';
import { getDatabase } from '../../common/services/firestore';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { translateError } from '../../common/utils/error';
import { useNavigate } from 'react-router-dom';
import ModalPrice from '../../common/components/ModalPrice/ModalPrice';
import ModalOwners from '../../common/components/ModalOwners/ModalOwners';
import { currentUser } from '../../common/services/user';
import ModalTax from '../../common/components/ModalTax/ModalTax';

const EnterprisesModal = ({
  openModal,
  setOpenModal,
  getEnterprises,
  setOpenInteractivePlant,
  setIdEnterprise,
  idEnterprise,
  isModify,
}) => {
  const [form, setForm] = useState();
  const [formAddress, setFormAddress] = useState();
  const [openMobileModal, setMobileModal] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [loadingImageCover, setLoadingImageCover] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageCover, setImageCover] = useState([]);
  const [enterprise, setEnterprise] = useState([]);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [openOwnersModal, setOpenOwnersModal] = useState(false);
  const [openTaxModal, setOpenTaxModal] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  function handlePhone(data) {
    if (data.phone) {
      data.phone =
        '+55' +
        data.phone
          .replaceAll('(', '')
          .replaceAll(') ', '')
          .replaceAll('-', '');
    }
  }

  const onSubmit = async (isAuto = false) => {
    const validForm = isValidForm(form, setForm);
    const validFormAddress = isValidForm(formAddress, setFormAddress);
    if (validForm && validFormAddress) {
      setOpenModal(false);
      let data = getDataForm(form);
      handlePhone(data);
      data = { ...data, address: getDataForm(formAddress), id: idEnterprise };
      await saveEnterprisesAdmin(data);
      await getEnterprises();
      enqueueSnackbar('Empreendimento salvo com sucesso.', { variant: ALERT_TYPES.SUCCESS });
      if (!isAuto) {
        setImageUrls([]);
        setImageCover([]);
        setForm(clearForm(form));
        setFormAddress(clearForm(formAddress));
        setIdEnterprise(undefined);
        setForm(clearForm(form));
        setFormAddress(clearForm(formAddress));
      }
      return true;
    }
    enqueueSnackbar('Dados inválidos.', { variant: ALERT_TYPES.WARNING });
    return false;
  };

  const deleteImage = async path => {
    const storage = getStorage();
    const storageRef = ref(storage, path);
    let newUrls = [...imageUrls];
    newUrls = newUrls.filter(img => img?.path !== path);
    setImageUrls([...newUrls]);
    let newCover = [...imageCover];
    newCover = newCover.filter(img => img?.path !== path);
    setImageCover([...newCover]);
    await deleteObject(storageRef);
  };

  const deleteEnterprise = async () => {
    await deleteEnterprisesAdmin({ id: idEnterprise });
    setImageUrls([]);
    setImageCover([]);
  };

  const createEnterprise = async () => {
    if (openModal === true) {
      const response = await createEnterprisesAdmin();
      setIdEnterprise(response[0].id);
    }
  };

  const getEnterprise = async () => {
    try {
      setLoadingImages(true);
      setLoadingImageCover(true);
      const data = (await getDoc(doc(getDatabase(), COLLECTION.ENTERPRISES, idEnterprise))).data();
      setEnterprise(data);
      setForm({
        name: { value: data?.name },
        description: { value: data?.description },
        status: { value: data?.status },
        maxReservation: { value: data?.maxReservation },
        phone: { value: data?.phone.toString().substring(3, data?.phone.length) },
      });
      setFormAddress({
        city: { value: data?.address?.city },
        district: { value: data?.address?.district },
        number: { value: data?.address?.number },
        road: { value: data?.address?.road },
        state: { value: data?.address?.state },
        zipcode: { value: data?.address?.zipcode },
      });
      setImageUrls(await getListImageStorage(idEnterprise, 'image'));
      setImageCover(await getListImageStorage(idEnterprise, 'cover'));
    } finally {
      setLoadingImages(false);
      setLoadingImageCover(false);
    }
  };

  useEffect(() => {
    isEmptyObject(idEnterprise) ? createEnterprise() : getEnterprise();
  }, [openModal]);

  const openPlant = async () => {
    if (await onSubmit(true)) {
      setOpenModal(false);
      setOpenInteractivePlant(true);
    }
  };

  const handleImageCover = async event => {
    setLoadingImageCover(true);
    try {
      const url = await uploadSingleImageStorage(event, idEnterprise, 'cover');
      setImageCover([url]);
    } catch (error) {
      enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
    } finally {
      setLoadingImageCover(false);
    }
  };

  const handleImage = async event => {
    setLoadingImages(true);
    try {
      const urls = await uploadMultiImageStorage(event, idEnterprise, 'image');
      setImageUrls([...imageUrls, ...urls]);
    } catch (error) {
      enqueueSnackbar(translateError(error.code), { variant: ALERT_TYPES.ERROR });
    } finally {
      setLoadingImages(false);
    }
  };

  return (
    <ModalStyled
      title={isModify ? 'Alterar Empreendimento' : 'Adicionar Empreendimento'}
      open={openModal}
      setOpen={setOpenModal}
      onClose={() => {
        setOpenCloseDialog(true);
      }}
      bodyHeight={isDesktop && 'calc(80vh - 58px)'}
      onlyOnClose
    >
      <AlertDialog
        open={openCloseDialog}
        title={'Ops!'}
        text={'Deseja realmente sair sem salvar?'}
        handleClose={() => setOpenCloseDialog(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setOpenCloseDialog(false)}>Cancelar</ButtonStyled>
            <ButtonStyled
              onClick={() => {
                !isModify && deleteEnterprise();
                setIdEnterprise(undefined);
                setForm(clearForm(form));
                setFormAddress(clearForm(formAddress));
                setImageUrls([]);
                setImageCover([]);
                setOpenModal(false);
                setOpenCloseDialog(false);
              }}
            >
              Sair
            </ButtonStyled>
          </>
        }
      />
      <AlertDialog
        open={removeDialog}
        title={'Cuidado!'}
        text={'Deseja realmente remover o empreendimento?'}
        handleClose={() => setRemoveDialog(false)}
        actions={
          <>
            <ButtonStyled onClick={() => setRemoveDialog(false)}>Cancelar</ButtonStyled>
            <ButtonStyled
              onClick={async () => {
                await deleteEnterprise();
                setImageUrls([]);
                setImageCover([]);
                setForm(clearForm(form));
                setFormAddress(clearForm(formAddress));
                setIdEnterprise(undefined);
                setForm(clearForm(form));
                setFormAddress(clearForm(formAddress));
                setOpenModal(false);
                setRemoveDialog(false);
                enqueueSnackbar('Empreendimento removido com sucesso.', { variant: ALERT_TYPES.SUCCESS });
                await getEnterprises();
              }}
              colorText={COLORS.RED}
            >
              Remover
            </ButtonStyled>
          </>
        }
      />
      <ModalPrice open={openPriceModal} setOpen={setOpenPriceModal} enterprise={enterprise}></ModalPrice>
      <ModalOwners open={openOwnersModal} setOpen={setOpenOwnersModal} enterprise={enterprise}></ModalOwners>
      <ModalTax open={openTaxModal} setOpen={setOpenTaxModal} enterprise={enterprise}></ModalTax>
      <Spacing ai={'stretch'} fd={isMobile ? 'column' : 'row'}>
        <Spacing margin={isMobile ? '' : '0 16px 0 0'} jc={'flex-start'} width={'100%'}>
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Dados Gerais
          </Typography>
          <InputStyled controller={{ form, setForm }} name={'name'} label={'Nome'} type={'text'} />
          <InputStyled
            multiline
            controller={{ form, setForm }}
            name={'description'}
            label={'Descrição'}
            type={'text'}
          />
          <SelectStyled
            controller={{ form, setForm }}
            label={'Status'}
            name={'status'}
            options={[
              { value: ENTERPRISES_STATUS.ACTIVE, label: 'Ativo' },
              { value: ENTERPRISES_STATUS.INACTIVE, label: 'Inativo' },
            ]}
          />
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Localização
          </Typography>
          <SelectStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            label={'Estado'}
            name={'state'}
            options={STATE_CITIES.states.map(state => {
              return { value: state?.initials, label: state?.name };
            })}
          />
          <SelectStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            label={'Cidade'}
            name={'city'}
            disabled={formAddress?.state?.value === ''}
            options={
              STATE_CITIES.states
                .filter(state => {
                  return state.initials === formAddress?.state?.value;
                })
                .map(state => {
                  return state.cities.map(city => {
                    return { value: city, label: city };
                  });
                })[0]
            }
          />
          <InputStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            name={'zipcode'}
            label={'CEP'}
            type={'text'}
            maxLength={9}
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/\d{5}-\d{3}/) !== null && value.length === 9
            }
            messageValidation={'CEP inválido. Ex: 99999-999'}
            mask={value => {
              return value && value.replace(/(\d{5})(\d{3})/, '$1-$2');
            }}
          />
          <InputStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            name={'district'}
            label={'Bairro'}
            type={'text'}
          />
          <InputStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            name={'road'}
            label={'Rua/Avenida'}
            type={'text'}
          />
          <InputStyled
            controller={{ form: formAddress, setForm: setFormAddress }}
            name={'number'}
            label={'Número'}
            type={'text'}
          />
        </Spacing>
        {!isMobile && <Spacing width={'1px'} sx={`border-left: 1px solid ${COLORS.GRAY_BLACK_TRANSPARENT};`} />}
        <Spacing
          width={isMobile ? '100%' : 'calc(100% - 1px)'}
          jc={'flex-start'}
          margin={isMobile ? 'auto' : '0 0 0 16px'}
          ai={'flex-start'}
        >
          {currentUser()?.id === enterprise?.owner && (
            <>
              <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
                Administradores
              </Typography>
              <Spacing fd={'row'}>
                <ButtonStyled onClick={() => setOpenOwnersModal(true)} variant="contained" margin={'0 16px 16px 0'}>
                  Acessos
                </ButtonStyled>
              </Spacing>
            </>
          )}
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Dados do Financeiro
          </Typography>
          <InputStyled
            onValidation={value =>
              String(value)
                .toLowerCase()
                .match(/\((\d{2})\)\s(\d{5})-(\d{4})/g) !== null && value.length === 15
            }
            messageValidation={'Celular inválido. Ex: (48) 99988-9988'}
            mask={value => {
              return (
                value &&
                value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '($1) $2')
                  .replace(/(\d{5})(\d{4})/, '$1-$2')
              );
            }}
            maxLength={15}
            controller={{ form, setForm }}
            name={'phone'}
            label={'Celular'}
            type={'text'}
          />
          <Spacing fd={'row'}>
            <ButtonStyled onClick={() => setOpenTaxModal(true)} variant="contained" margin={'0 16px 16px 0'}>
              Sistema de Financiamento
            </ButtonStyled>
          </Spacing>
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Número máximo de reserva por corretor
          </Typography>
          <InputStyled controller={{ form, setForm }} name={'maxReservation'} label={'Número máximo'} type={'number'} />
          <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
            Planta Interativa
          </Typography>
          <Spacing fd={'row'}>
            <ButtonStyled
              onClick={() => (isMobile ? setMobileModal(true) : openPlant())}
              variant="contained"
              margin={'0 16px 16px 0'}
            >
              Abrir
            </ButtonStyled>
            {!isEmptyObject(enterprise.forms) && (
              <>
                <ButtonStyled
                  onClick={() => navigate('../admin/empreendimento-view/' + idEnterprise)}
                  variant="contained"
                  margin={'0 16px 16px 0'}
                  bg={COLORS.GREEN_LIGHT}
                >
                  Visualizar
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => setOpenPriceModal(true)}
                  variant="contained"
                  margin={'0 0 16px 0'}
                  bg={COLORS.YELLOW}
                >
                  Ajustar Preços
                </ButtonStyled>
              </>
            )}
          </Spacing>
          {!isMobile && (
            <>
              <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
                Imagem de Capa
              </Typography>
              <FileInput handle={handleImageCover} loading={loadingImageCover} margin={'0 0 16px 0'} />
              <Spacing fd={'row'} jc={'flex-start'} fw={'wrap'}>
                {imageCover &&
                  imageCover.map(img => (
                    <Spacing padding={'8px'}>
                      <IconButton
                        onClick={() => deleteImage(img?.path)}
                        color="error"
                        style={{
                          height: '0',
                          padding: '0',
                          position: 'relative',
                          top: '8px',
                          left: '55px',
                        }}
                      >
                        <Close color={'error'} />
                      </IconButton>
                      <img src={img?.url} width={125} alt={'Imagem de Capa'} />
                    </Spacing>
                  ))}
              </Spacing>
              <Typography align={'left'} sx={{ width: '100%', marginBottom: '8px' }}>
                Imagens
              </Typography>
              <FileInput handle={handleImage} loading={loadingImages} margin={'0 0 16px 0'} />
              <Spacing fd={'row'} jc={'flex-start'} fw={'wrap'}>
                {imageUrls &&
                  imageUrls.map(img => (
                    <Spacing padding={'8px'}>
                      <IconButton
                        onClick={() => deleteImage(img?.path)}
                        color="error"
                        style={{
                          height: '0',
                          padding: '0',
                          position: 'relative',
                          top: '8px',
                          left: '55px',
                        }}
                      >
                        <Close color={'error'} />
                      </IconButton>
                      <img src={img?.url} width={125} alt={'Imagem do Empreendimento'} />
                    </Spacing>
                  ))}
              </Spacing>
            </>
          )}
        </Spacing>
      </Spacing>
      <Divider />
      <Spacing ai={'flex-end'} jc={'flex-end'} fd={'row'} margin={'16px 0 0 0'}>
        <ButtonStyled
          onClick={() => setRemoveDialog(true)}
          margin={'0 16px 0 0'}
          variant="contained"
          component="span"
          bg={COLORS.RED}
        >
          Remover
        </ButtonStyled>
        <ButtonStyled onClick={() => onSubmit(false)} variant="contained" component="span" bg={COLORS.GREEN_LIGHT}>
          Salvar
        </ButtonStyled>
      </Spacing>
      <ModalStyled open={openMobileModal} setOpen={setMobileModal}>
        <Spacing height={heightWithoutToolbar} fd={'Column'}>
          <img style={{ borderRadius: '500px', marginRight: '16px' }} src={WomanAvatar} width={400} alt={'Person'} />
          <Typography sx={{ width: '100%', margin: '64px 32px' }} align={'center'} variant="h5">
            Funcionalidade somente disponível em desktop
          </Typography>
          <ButtonStyled onClick={() => setMobileModal(false)} variant="contained" component="span" bg={COLORS.GRAY}>
            Sair
          </ButtonStyled>
        </Spacing>
      </ModalStyled>
    </ModalStyled>
  );
};

export default EnterprisesModal;
