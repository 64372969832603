import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';

export const getImageStorage = (id, name) => {
  const storage = getStorage();
  const storageRef = ref(storage, id + '/' + name);
  return getDownloadURL(storageRef);
};

export const getListImageStorage = async (id, prefix = '') => {
  const storage = getStorage();
  const storageRef = ref(storage, id);
  const files = (await listAll(storageRef)).items;
  const filesFiltered = files.filter(file => file.name.includes(prefix));
  let list = [];
  for (const index in filesFiltered) {
    list = [
      ...list,
      {
        url: await getImageStorage(id, filesFiltered[index].name),
        path: id + '/' + filesFiltered[index].name,
      },
    ];
  }
  return list;
};

export const uploadSingleImageStorage = async (event, id, name) => {
  const file = event.target.files[0];
  const storage = getStorage();
  const storageRef = ref(storage, id + '/' + name);
  await uploadBytes(storageRef, file);
  const image = {
    url: await getImageStorage(id, name),
    path: id + '/' + name,
  };
  return image;
};

export const uploadMultiImageStorage = async (event, id, prefix) => {
  const files = event.target.files;
  const storage = getStorage();
  let list = [];
  for (const index in files) {
    if (!Number.isNaN(Number(index))) {
      const random = Math.random();
      const storageRef = ref(storage, id + '/' + prefix + '-' + index + '-' + random);
      await uploadBytes(storageRef, files[index]);
      list = [
        ...list,
        {
          url: await getImageStorage(id, prefix + '-' + index + '-' + random),
          path: id + '/' + prefix + '-' + index + '-' + random,
        },
      ];
    }
  }
  return list;
};
