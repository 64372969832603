import React from 'react';
import { AppBar, createTheme, IconButton, ThemeProvider, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { COLORS } from '../../constants/COLORS';
import { isMobile } from '../../services/screen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isEmptyObject } from '../../utils/objectManipulation';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.GREEN_LIGHT,
      contrastText: COLORS.WHITE,
    },
  },
});

const AppBarStyled = ({ setOpenMenu, title, buttons, onBack }) => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => (onBack ? onBack() : setOpenMenu(true))}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: isMobile ? 0 : 2 }}
          >
            {onBack ? <ArrowBackIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant={'h6'} component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {!isEmptyObject(buttons) && buttons}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default AppBarStyled;
