import styled from 'styled-components';
import { COLORS } from '../../constants/COLORS';
import { isScreenDown, MediaQuery } from '../../services/screen';

export const Title = styled.h2`
  color: ${COLORS.GRAY_BLACK};
  width: 50%;
  margin: 16px 0 8px 0;
  font-weight: 500;

  ${MediaQuery(isScreenDown(600))} {
    width: 100%;
  }
`;

export const Accepted = styled.h2`
  color: ${COLORS.GREEN_LIGHT};
  width: 50%;
  margin: 16px 0 8px 0;
  font-weight: 600;

  ${MediaQuery(isScreenDown(600))} {
    width: 100%;
  }
`;

export const Text = styled.h3`
  color: ${COLORS.GRAY_BLACK};
  margin: 0;
  width: 50%;
  font-weight: 400;

  ${MediaQuery(isScreenDown(600))} {
    width: 100%;
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;

  ${MediaQuery(isScreenDown(600))} {
    flex-direction: column;
    margin-top: 16px;
  }
`;

export const WrapperChat = styled.div`
  margin: ${({ isSender }) => (isSender ? '8px 8px 8px auto' : '8px auto 8px 8px')};
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${({ isSender }) => (isSender ? COLORS.GRAY_BLACK_TRANSPARENT : COLORS.GRAY_TRANSPARENT)};
`;

export const ChatSender = styled.h3`
  margin: 0 0 6px 0;
  font-size: 12px;
  color: ${COLORS.GRAY_BLACK_TRANSPARENT};
`;
