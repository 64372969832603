import { PLANT_STATUS } from '../constants/PLANT_STATUS';
import { COLORS } from '../constants/COLORS';

export const getColor = (status, transparent = false) => {
  if (transparent) {
    return COLORS.TRANSPARENT;
  }
  switch (status) {
    case PLANT_STATUS.AVAILABLE:
      return COLORS.GREEN_TRANSPARENT;
    case PLANT_STATUS.RESERVED:
      return COLORS.YELLOW_TRANSPARENT;
    case PLANT_STATUS.IN_PROPOSAL:
      return COLORS.BLUE_TRANSPARENT;
    case PLANT_STATUS.SOLD:
      return COLORS.ORANGE_TRANSPARENT;
    default:
      return COLORS.GRAY_BLACK_TRANSPARENT;
  }
};
