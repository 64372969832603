import React from 'react';
import { heightWithoutToolbar } from '../../services/screen';

const ContainerMaxTable = ({ children }) => {
  return (
    <div style={{ height: heightWithoutToolbar, display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
      {children}
    </div>
  );
};

export default ContainerMaxTable;
