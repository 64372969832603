import React from 'react';
import Logo from '../../svgs/Logo';
import { Container } from './styled';
import { isDesktop } from '../../services/screen';

const LogoContainer = ({ width }) => {
  return (
    <Container width={width}>
      <Logo width={isDesktop ? '320px' : '200px'} />
    </Container>
  );
};

export default LogoContainer;
