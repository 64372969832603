import React, { useEffect, useState } from 'react';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { COLORS } from '../../common/constants/COLORS';
import { recusedInviteAdmin } from '../../common/services/invitesAdmin';
import { getDatabase, getDataDocs } from '../../common/services/firestore';
import TableData from '../../common/components/TableData/TableData';
import { isDesktop, percentInPixelWidth, isMobile } from '../../common/services/screen';
import { Box, Tab } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { INVITE_STATUS } from '../../common/constants/INVITE_STATUS';
import TabsStyled from '../../common/components/TabsStyled/TabsStyled';
import { dateInSeconds } from '../../common/utils/date';
import { currentUser } from '../../common/services/user';
import CreateInviteModal from './CreateInviteModal';
import ModifyInviteModal from './ModifyInviteModal';
import ContainerMaxTable from '../../common/components/ContainerMaxTable/ContainerMaxTable';
import { Add } from '@mui/icons-material';
import { COLLECTION } from '../../common/constants/COLLECTION';

const InvitesAdmin = () => {
  document.title = 'Controlote - Convites';
  const [invites, setInvites] = useState();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openModifyModal, setOpenModifyModal] = useState(false);
  const [inviteId, setInviteId] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [tabs, setTabs] = useState(0);

  const status = [INVITE_STATUS.SENDING, INVITE_STATUS.ACCEPTED, INVITE_STATUS.EXPIRED];

  const checkRecusedInvites = async () => {
    const invitesForSetStatus = getDataDocs(
      await getDocs(query(collection(getDatabase(), COLLECTION.INVITES), where('status', '==', INVITE_STATUS.SENDING)))
    );
    for (const key in invitesForSetStatus) {
      if (invitesForSetStatus[key]?.validDate.seconds <= dateInSeconds()) {
        await recusedInviteAdmin(invitesForSetStatus[key]);
      }
    }
  };

  const getInvites = async () => {
    setLoadingTable(true);
    await checkRecusedInvites();
    setInvites(
      getDataDocs(
        await getDocs(
          query(
            collection(getDatabase(), COLLECTION.INVITES),
            where('user', '==', currentUser()?.id),
            where('status', '==', status[tabs])
          )
        )
      )
    );
    setLoadingTable(false);
  };

  useEffect(() => {
    getInvites();
  }, [tabs]);

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      width: percentInPixelWidth(!isDesktop ? 100 : 50) - (isMobile ? 110 : 222) - 10,
    },
    { field: 'email', headerName: 'Email', width: percentInPixelWidth(50), hide: !isDesktop },
    {
      field: 'link',
      headerName: 'Ações',
      renderCell: params => (
        <>
          <ButtonStyled
            onClick={() => {
              const hostname = window.location.hostname;
              if (hostname.includes('localhost')) {
                window.open(
                  `${
                    params.row.type === USER_PERMISSION.ADMIN
                      ? `http://localhost:3000/admin/convite/${params.row.id}`
                      : `http://localhost:3000/convite/${params.row.id}`
                  }`,
                  '_blank'
                );
              } else {
                window.open(
                  `${
                    params.row.type === USER_PERMISSION.ADMIN
                      ? `https://${hostname}/admin/convite/${params.row.id}`
                      : `https://${hostname}/convite/${params.row.id}`
                  }`,
                  '_blank'
                );
              }
            }}
            size={'small'}
            bg={COLORS.BLUE}
            colorText={COLORS.WHITE}
            margin={'0 8px 0 0'}
            noMobile
          >
            Ver Convite
          </ButtonStyled>
          {params.row?.phone && (
            <ButtonStyled
              onClick={() =>
                window.open(
                  `https://wa.me/${params.row?.phone}?text=Acesse o link para abrir o convite:%0A${
                    params.row.type === USER_PERMISSION.ADMIN
                      ? `https://${window.location.hostname}/admin/convite/${params.row.id}`
                      : `https://${window.location.hostname}/convite/${params.row.id}`
                  }`,
                  '_blank'
                )
              }
              size={'small'}
              bg={COLORS.GREEN_LIGHT}
              colorText={COLORS.WHITE}
            >
              Whatsapp
            </ButtonStyled>
          )}
        </>
      ),
      width: isMobile ? 110 : 222,
    },
  ];

  return (
    <RestrictArea
      permission={USER_PERMISSION.ADMIN}
      title={'Convites'}
      barButtons={
        <ButtonStyled onClick={() => setOpenCreateModal(true)} startIcon={<Add />} bg={COLORS.WHITE}>
          Novo
        </ButtonStyled>
      }
    >
      <CreateInviteModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} getInvites={getInvites} />
      <ModifyInviteModal
        id={inviteId}
        openModal={openModifyModal}
        setOpenModal={setOpenModifyModal}
        getInvites={getInvites}
      />
      <ContainerMaxTable>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '16px' }}>
          <TabsStyled value={tabs}>
            <Tab label="Enviados" onClick={() => setTabs(0)} />
            <Tab label="Aceitos" onClick={() => setTabs(1)} />
            <Tab label="Expirados" onClick={() => setTabs(2)} />
          </TabsStyled>
        </Box>
        {invites && (
          <TableData
            rowClick={event => {
              setInviteId(event.id);
              setOpenModifyModal(true);
            }}
            rows={invites}
            columns={columns}
            loading={loadingTable}
          />
        )}
      </ContainerMaxTable>
    </RestrictArea>
  );
};

export default InvitesAdmin;
