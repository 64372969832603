import React, { useEffect, useState } from 'react';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { FilterList } from '@mui/icons-material';
import { COLORS } from '../../common/constants/COLORS';
import ContainerMaxTable from '../../common/components/ContainerMaxTable/ContainerMaxTable';
import { Box, Chip } from '@mui/material';
import { isMobile, percentInPixelWidth } from '../../common/services/screen';
import { Spacing } from '../../common/components/Spacing/Spacing';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { clearForm } from '../../common/utils/formController';
import TableData from '../../common/components/TableData/TableData';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { currentUser } from '../../common/services/user';
import { isEmptyObject } from '../../common/utils/objectManipulation';
import ModalReservation from '../../common/components/ModalReservation/ModalReservation';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { checkReservationsExpired } from '../../common/services/reservation';
import { RESERVATION_STATUS } from '../../common/constants/RESERVATION_STATUS';
import { useNavigate, useParams } from 'react-router-dom';
import { clearNotification } from '../../common/services/notification';

const ReservationsAdmin = () => {
  const [filter, setFilter] = useState(false);
  const [reservations, setReservations] = useState();
  const [enterprises, setEnterprises] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState({
    userName: { value: '' },
    clientName: { value: '' },
    enterpriseName: { value: '' },
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const columns = [
    { field: 'clientName', headerName: 'Cliente', width: percentInPixelWidth(isMobile ? 30 : 23.333333333) - 10 },
    {
      field: 'userName',
      headerName: 'Corretor',
      width: percentInPixelWidth(23.333333333),
      hide: isMobile,
    },
    {
      field: 'enterpriseName',
      headerName: 'Empreendimento',
      width: percentInPixelWidth(23.333333333),
      hide: isMobile,
    },
    { field: 'square', headerName: 'Quadra', width: percentInPixelWidth(isMobile ? 20 : 10) },
    { field: 'number', headerName: 'Número', width: percentInPixelWidth(isMobile ? 20 : 10) },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <Chip
          sx={{
            background:
              params.row.status === RESERVATION_STATUS.RESERVED
                ? COLORS.GREEN_LIGHT_TRANSPARENT
                : COLORS.GRAY_BLACK_TRANSPARENT,
          }}
          label={params.row.status === RESERVATION_STATUS.RESERVED ? 'Reservado' : 'Expirado'}
          variant="outlined"
        />
      ),
      width: percentInPixelWidth(isMobile ? 30 : 10),
    },
  ];

  const getEnterprises = async () => {
    const docs = (
      await getDocs(
        query(collection(database, COLLECTION.ENTERPRISES), where('user', 'array-contains', currentUser()?.id))
      )
    ).docs;
    setEnterprises(docs?.map(doc => doc.data()));
  };

  const getReservations = async () => {
    const docs = await getDocs(
      query(
        collection(database, COLLECTION.RESERVATIONS),
        where(
          'enterprise',
          'in',
          enterprises?.map(doc => doc?.id)
        )
      )
    );
    let dataDocs = docs?.docs?.map(doc => doc.data());
    checkReservationsExpired(dataDocs, getReservations);
    dataDocs = dataDocs.filter(data => {
      return (
        data?.clientName?.toLowerCase().includes(form?.clientName?.value.toLowerCase()) &&
        data?.enterpriseName?.toLowerCase().includes(form?.enterpriseName?.value.toLowerCase()) &&
        data?.userName?.toLowerCase().includes(form?.userName?.value.toLowerCase())
      );
    });
    setReservations(dataDocs);
  };

  useEffect(() => {
    getEnterprises();
    clearNotification();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(enterprises)) {
      getReservations();
    }
  }, [enterprises]);

  useEffect(() => {
    if (!isEmptyObject(id)) {
      setOpenModal(true);
    }
  }, [id]);

  const handleOnClick = event => {
    navigate('../admin/reservas/' + event?.id);
  };

  return (
    <RestrictArea
      permission={USER_PERMISSION.ADMIN}
      title={'Reservas'}
      barButtons={
        <>
          <ButtonStyled onClick={() => setFilter(!filter)} startIcon={<FilterList />} bg={COLORS.WHITE}>
            Filtro
          </ButtonStyled>
        </>
      }
    >
      <ModalReservation
        getReservations={() => getReservations()}
        isAdmin={true}
        setOpen={setOpenModal}
        open={openModal}
        id={id}
      />
      <ContainerMaxTable>
        {filter && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              padding: '16px',
            }}
          >
            <Spacing width={isMobile ? '100%' : 'calc(100% - 192px)'}>
              <InputStyled notRequired controller={{ form, setForm }} name={'clientName'} label={'Cliente'} />
              <InputStyled notRequired controller={{ form, setForm }} name={'userName'} label={'Corretor'} />
              <InputStyled
                paddingBottom={'0'}
                notRequired
                controller={{ form, setForm }}
                name={'enterpriseName'}
                label={'Empreendimento'}
              />
            </Spacing>
            <Spacing width={isMobile ? '100%' : '192px'} margin={'16px 0 0 0'} jc={'flex-end'} fd={'row'}>
              <ButtonStyled
                onClick={() => {
                  setForm(clearForm(form));
                }}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.RED_BLACK}
                colorText={COLORS.WHITE}
              >
                Limpar
              </ButtonStyled>
              <ButtonStyled
                onClick={() => getReservations()}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.GRAY}
                colorText={COLORS.WHITE}
              >
                Filtrar
              </ButtonStyled>
            </Spacing>
          </Box>
        )}
        {reservations && (
          <TableData rowClick={event => handleOnClick(event)} rows={reservations} columns={columns} loading={false} />
        )}
      </ContainerMaxTable>
    </RestrictArea>
  );
};

export default ReservationsAdmin;
