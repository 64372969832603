export const isEmptyObject = object => {
  for (const property in object) {
    return false;
  }
  if (Number.isInteger(object)) {
    return false;
  }
  if (object === undefined) {
    return true;
  }
  if (object?.toString() === '') {
    return true;
  }
  return true;
};

export const getElementByXpath = path =>
  document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

export const formatReal = int => {
  let tmp = int.toString();
  tmp = tmp.replace(/(\d{2})$/g, ',$1');
  if (tmp.length > 6 && tmp.length < 10) tmp = tmp.replace(/(\d{3}),(\d{2}$)/g, '.$1,$2');
  if (tmp.length >= 10 && tmp.length < 13) tmp = tmp.replace(/(\d{3})(\d{3}),(\d{2}$)/g, '.$1.$2,$3');
  if (tmp.length >= 13 && tmp.length < 16) tmp = tmp.replace(/(\d{3})(\d{3})(\d{3}),(\d{2}$)/g, '.$1.$2.$3,$4');
  if (tmp.length >= 16) tmp = tmp.replace(/(\d{3})(\d{3})(\d{3})(\d{3}),(\d{2}$)/g, '.$1.$2.$3.$4,$5');
  return tmp;
};

export const realToNumber = real => {
  let tmp = real?.toString();
  tmp = tmp?.replaceAll('.', '');
  tmp = tmp?.replaceAll(',', '.');
  return Number.parseFloat(tmp);
};
