import { createDocument, deleteDocument, updateDocument } from './firestore';
import { currentUser } from './user';
import { getStorage, ref, deleteObject, listAll } from 'firebase/storage';
import { COLLECTION } from '../constants/COLLECTION';

export const createEnterprisesAdmin = async data => {
  const enterprise = {
    ...data,
    user: [currentUser()?.id],
    owner: currentUser()?.id,
    createdAt: new Date(),
  };
  return createDocument(COLLECTION.ENTERPRISES, enterprise);
};

export const saveEnterprisesAdmin = async data => {
  const enterprise = {
    ...data,
    updatedAt: new Date(),
  };
  return updateDocument(COLLECTION.ENTERPRISES, enterprise);
};

export const deleteEnterprisesAdmin = async data => {
  const storage = getStorage();
  const storageRef = ref(storage, data?.id);
  const files = (await listAll(storageRef)).items;
  files.forEach(file => deleteObject(file));
  return deleteDocument(COLLECTION.ENTERPRISES, data);
};

export const changeEnterpriseStatus = (status, enterprise, name) => {
  let newEnterprise = { ...enterprise };
  newEnterprise = {
    ...newEnterprise,
    forms: newEnterprise?.forms?.map(item => {
      if (item?.name === name) {
        return { ...item, status: status };
      }
      return item;
    }),
  };
  newEnterprise = {
    ...newEnterprise,
    cords: {
      ...newEnterprise?.cords,
      areas: newEnterprise?.cords?.areas?.map(item => {
        if (item?.name === name) {
          return { ...item, status: status };
        }
        return item;
      }),
    },
  };
  return newEnterprise;
};
