import styled from 'styled-components';
import { COLORS } from '../../../common/constants/COLORS';

export const Container = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 500;
  width: 100%;
  top: 0;
  position: absolute;
  background: ${COLORS.WHITE};
`;
