import { isEmptyObject } from './objectManipulation';

export const isValidForm = (form, setForm) => {
  setForm(value => {
    return { ...value, onSubmit: true };
  });
  let status = true;
  for (const property in form) {
    if (property !== 'onSubmit') {
      if (
        (form[property]?.required === undefined || form[property]?.required) &&
        isEmptyObject(form[property]?.value)
      ) {
        status = false;
      }
      if (
        !isEmptyObject(form[property]?.value) &&
        form[property]?.onValidation !== undefined &&
        !form[property]?.onValidation(form[property]?.value)
      ) {
        status = false;
      }
    }
  }
  return status;
};

export const getDataForm = form => {
  let data = {};
  for (const property in form) {
    if (property !== 'onSubmit') {
      data = { ...data, [property]: form[property]?.value };
    }
  }
  return data;
};

export const clearForm = form => {
  let data = form;
  for (const property in form) {
    if (property !== 'onSubmit') {
      data = { ...data, [property]: { ...form[property], value: '' } };
    }
  }
  return data;
};
