import BusinessIcon from '@mui/icons-material/Business';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import React from 'react';
import { Home } from '@mui/icons-material';

export const MENU_USER = [
  [
    { title: 'Home', icon: <Home />, to: '/home' },
    { title: 'Empreendimentos', icon: <BusinessIcon />, to: '/empreendimentos' },
    { title: 'Reservas', icon: <BeenhereOutlinedIcon />, to: '/reservas' },
    { title: 'Propostas', icon: <CurrencyExchangeIcon />, to: '/propostas' },
  ],
  [],
];
