export const around = (number, decimals = 2) => {
  const og = Math.pow(10, decimals);
  return (Math.trunc(number * og) / og).toFixed(decimals);
};

const calcPrice = (price, tax, parcels, tmpParcels) => {
  const finalValue =
    price * ((Math.pow(1 + tax / 100, parcels) * (tax / 100)) / (Math.pow(1 + tax / 100, parcels) - 1));
  let taxs = price * (tax / 100);
  let amortization = finalValue - taxs;
  let debitBalance = price;
  for (let parcel = 1; parcel <= parcels; parcel++) {
    tmpParcels = [
      ...tmpParcels,
      {
        parcelNumber: parcel,
        parcelValue: around(finalValue),
        amortization: around(amortization),
        tax: taxs,
      },
    ];
    debitBalance -= amortization;
    taxs = debitBalance * (tax / 100);
    amortization = finalValue - taxs;
  }
  return tmpParcels;
};

const calcSac = (price, parcels, tax, tmpParcels) => {
  const amortization = price / parcels;
  let debitBalance = price - amortization;
  let taxs = price * (tax / 100);
  let parcelValue = amortization + taxs;
  for (let parcel = 1; parcel <= parcels; parcel++) {
    tmpParcels = [
      ...tmpParcels,
      {
        parcelNumber: parcel,
        parcelValue: around(parcelValue),
        amortization: around(amortization),
        tax: taxs,
      },
    ];
    taxs = debitBalance * (tax / 100);
    debitBalance -= amortization;
    parcelValue = amortization + taxs;
  }
  return tmpParcels;
};

export const calcParcels = (price, parcels, tax, isSac = true) => {
  let tmpParcels = [];
  if (isSac) {
    tmpParcels = calcSac(price, parcels, tax, tmpParcels);
    return tmpParcels;
  }
  tmpParcels = calcPrice(price, tax, parcels, tmpParcels);
  return tmpParcels;
};
