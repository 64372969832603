import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCwBY3Rsdpv7Ebiu7mcHYaFTagiU8BT4GQ',
  authDomain: 'controlote-f3df2.firebaseapp.com',
  projectId: 'controlote-f3df2',
  storageBucket: 'controlote-f3df2.appspot.com',
  messagingSenderId: '277313273831',
  appId: '1:277313273831:web:e291bbfccc3d5d05234742',
  measurementId: 'G-B4N20B35M5',
};

export const vapidKey = 'BCHFVjODVlNV7sBrjIVoZLx_WVxDc0O-5Rkc3AOxHqxib5Z96sgusLf_4YQl6oFVxkTiQUlEHeAUT0OjtQYwGaY';

export const appFirebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(appFirebase);
export const storage = getStorage(appFirebase);
export const messaging = getMessaging(appFirebase);
export const database = getFirestore();
