export const isDesktop = (() => {
  return document.body.clientWidth >= 1024;
})();

export const isMobile = (() => {
  return document.body.clientWidth <= 425;
})();

export const isTablet = (() => {
  return document.body.clientWidth <= 1024 && document.body.clientWidth >= 425;
})();

export const isScreenDown = width => {
  return document.body.clientWidth <= width;
};

export const MediaQuery = platform => {
  return platform ? '' : 'false';
};

export const percentInPixelWidth = percent => {
  return (document.body.clientWidth * percent) / 100;
};

export const percentInPixelHeight = percent => {
  return (document.body.clientHeight * percent) / 100;
};

export const heightWithoutToolbar = isScreenDown(600) ? 'calc(100vh - 52px)' : 'calc(100vh - 64px)';

export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
