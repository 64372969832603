import React, { useEffect, useState } from 'react';
import { USER_PERMISSION } from '../../common/constants/USER_PERMISSION';
import RestrictArea from '../../common/components/RestrictArea/RestrictArea';
import { ButtonStyled } from '../../common/components/ButtonStyled/ButtonStyled';
import { FilterList } from '@mui/icons-material';
import { COLORS } from '../../common/constants/COLORS';
import ContainerMaxTable from '../../common/components/ContainerMaxTable/ContainerMaxTable';
import { Box } from '@mui/material';
import { isMobile, percentInPixelWidth } from '../../common/services/screen';
import { Spacing } from '../../common/components/Spacing/Spacing';
import InputStyled from '../../common/components/InputStyled/InputStyled';
import { clearForm } from '../../common/utils/formController';
import TableData from '../../common/components/TableData/TableData';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { database } from '../../common/services/firebase';
import { currentUser } from '../../common/services/user';
import { isEmptyObject } from '../../common/utils/objectManipulation';
import ModalProposal from '../../common/components/ModalProposal/ModalProposal';
import { COLLECTION } from '../../common/constants/COLLECTION';
import { useNavigate, useParams } from 'react-router-dom';
import { clearNotification } from '../../common/services/notification';

const ProposalsAdmin = () => {
  const [filter, setFilter] = useState(false);
  const [proposals, setProposals] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [enterprises, setEnterprises] = useState();
  const [form, setForm] = useState({
    userName: { value: '' },
    clientName: { value: '' },
    enterpriseName: { value: '' },
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const columns = [
    { field: 'clientName', headerName: 'Cliente', width: percentInPixelWidth(isMobile ? 33.33333 : 26.66666) - 10 },
    {
      field: 'userName',
      headerName: 'Corretor',
      width: percentInPixelWidth(isMobile ? 33.33333 : 26.66666),
    },
    {
      field: 'enterpriseName',
      headerName: 'Empreendimento',
      width: percentInPixelWidth(isMobile ? 33.33333 : 26.66666),
    },
    { field: 'square', headerName: 'Quadra', hide: isMobile, width: percentInPixelWidth(10) },
    { field: 'number', headerName: 'Número', hide: isMobile, width: percentInPixelWidth(10) },
  ];

  const getEnterprises = async () => {
    const docs = (
      await getDocs(
        query(collection(database, COLLECTION.ENTERPRISES), where('user', 'array-contains', currentUser()?.id))
      )
    ).docs;
    setEnterprises(docs?.map(doc => doc.data()));
  };

  const getProposals = async () => {
    const docs = await getDocs(
      query(
        collection(database, COLLECTION.PROPOSALS),
        where(
          'enterprise',
          'in',
          enterprises?.map(doc => doc?.id)
        )
      )
    );
    let dataDocs = docs?.docs?.map(doc => doc.data());
    dataDocs = dataDocs.filter(data => {
      return (
        data?.clientName?.toLowerCase().includes(form?.clientName?.value.toLowerCase()) &&
        data?.enterpriseName?.toLowerCase().includes(form?.enterpriseName?.value.toLowerCase()) &&
        data?.userName?.toLowerCase().includes(form?.userName?.value.toLowerCase())
      );
    });
    setProposals(dataDocs);
  };

  const handleOnClick = event => {
    navigate('../admin/propostas/' + event?.id);
  };

  useEffect(() => {
    if (!isEmptyObject(id)) {
      setOpenModal(true);
    }
  }, [id]);

  useEffect(() => {
    getEnterprises();
    clearNotification();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(enterprises)) {
      getProposals();
    }
  }, [enterprises]);

  return (
    <RestrictArea
      permission={USER_PERMISSION.ADMIN}
      title={'Propostas'}
      barButtons={
        <>
          <ButtonStyled onClick={() => setFilter(!filter)} startIcon={<FilterList />} bg={COLORS.WHITE}>
            Filtro
          </ButtonStyled>
        </>
      }
    >
      <ModalProposal
        getProposals={() => getProposals()}
        setOpen={setOpenModal}
        open={openModal}
        isAdmin={true}
        id={id}
      />
      <ContainerMaxTable>
        {filter && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              padding: '16px',
            }}
          >
            <Spacing width={isMobile ? '100%' : 'calc(100% - 192px)'}>
              <InputStyled notRequired controller={{ form, setForm }} name={'clientName'} label={'Cliente'} />
              <InputStyled notRequired controller={{ form, setForm }} name={'userName'} label={'Corretor'} />
              <InputStyled
                paddingBottom={'0'}
                notRequired
                controller={{ form, setForm }}
                name={'enterpriseName'}
                label={'Empreendimento'}
              />
            </Spacing>
            <Spacing width={isMobile ? '100%' : '192px'} margin={'16px 0 0 0'} jc={'flex-end'} fd={'row'}>
              <ButtonStyled
                onClick={() => {
                  setForm(clearForm(form));
                }}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.RED_BLACK}
                colorText={COLORS.WHITE}
              >
                Limpar
              </ButtonStyled>
              <ButtonStyled
                onClick={() => getProposals()}
                height={'36.5px'}
                margin={'0 0 0 16px'}
                bg={COLORS.GRAY}
                colorText={COLORS.WHITE}
              >
                Filtrar
              </ButtonStyled>
            </Spacing>
          </Box>
        )}
        {proposals && (
          <TableData rowClick={event => handleOnClick(event)} rows={proposals} columns={columns} loading={false} />
        )}
      </ContainerMaxTable>
    </RestrictArea>
  );
};

export default ProposalsAdmin;
